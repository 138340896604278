import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import CInput from "./CInput";
import CCheckButton from "./CCheckButton";

const CDropDown = ({
    cId,
    cHelperText,
    cData,
    cErrorMsg,
    cOnChange,
    cKeyReset,
    cFullWidth,
    cIsOptionName,
    cIsMultiple,
    cDefaultValue,
    cIsRequired,
}) => {
    const [selectedValue, setSelectedValue] = React.useState(cDefaultValue || "");
    const [inputValue, setInputValue] = useState("");

    const resetRef = useRef(false);

    useEffect(() => {
        // Reset the selected value when resetRef is true
        if (resetRef.current) {
            setSelectedValue("");
            setInputValue("");
            resetRef.current = false;
        }
    }, [resetRef]);

    const renderInputLabel = () => {
        if (cIsRequired) {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{cHelperText}</span>
                    <span style={{ marginLeft: "4px" }}>*</span>
                </div>
            );
        } else {
            return cHelperText;
        }
    };

    return (
        <Autocomplete
            id={cId}
            multiple={cIsMultiple}
            disableCloseOnSelect={cIsMultiple}
            options={cData || []}
            onChange={(event, newValue) => {
                setSelectedValue(newValue);
                if (cOnChange !== undefined) {
                    cOnChange(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            inputValue={inputValue}
            getOptionLabel={(option) =>
                (option && (cIsOptionName === undefined ? option : option[cIsOptionName])) || ""
            }
            value={selectedValue || []}
            renderOption={
                cIsMultiple &&
                ((props, option, { selected }) => (
                    <li {...props}>
                        <CCheckButton
                            cLabel={cIsOptionName === undefined ? option : option[cIsOptionName]}
                            cChecked={selectedValue.indexOf(option) > -1}
                        />
                    </li>
                ))
            }
            renderInput={(params) => (
                <CInput
                    key={cKeyReset}
                    cHintText={renderInputLabel()}
                    cDropDown={params}
                    cErrorMsg={cErrorMsg}
                    cValue={selectedValue}
                />
            )}
            fullWidth={cFullWidth}
            PaperComponent={({ children, ...rest }) => (
                <Paper {...rest}>{children}</Paper>
            )}
        />
    );
};

export default CDropDown;
