import React, { useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CInput from "./CInput";

const CDropDown = ({ cHelperText, cData, cErrorMsg, cOnChange }) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const resetRef = useRef(false);

  useEffect(() => {
    // Reset the selected value when resetRef is true
    if (resetRef.current) {
      setSelectedValue("");
      resetRef.current = false;
    }
  }, [resetRef]);

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    cOnChange(newValue);
  };

  // Function to reset the selected value
  const resetDropdown = () => {
    resetRef.current = true;
  };

  return (
    <Autocomplete
      options={cData || []}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={(option) => option}
      value={selectedValue}
      renderInput={(params) => (
        <CInput
          cHintText={cHelperText}
          cDropDown={params}
          cErrorMsg={cErrorMsg}
        />
      )}
      fullWidth
    />
  );
};

export default CDropDown;
