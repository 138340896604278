import React from "react";
import { BrowserRouter } from "react-router-dom";
// import { ToastProvider, CThemeProvider } from "fe_comp_base";
import {ToastProvider} from "./basecomp/components/provider/ToastProvider";
import CThemeProvider from "./basecomp/components/wrapper/CThemeProvider";
import AppContent from "./main/AppContent";


function App() {

  return (
    <>
      <CThemeProvider>
        <ToastProvider>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </ToastProvider>
      </CThemeProvider>
    </>
  );
}


export default App;
