import React from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CTable2 from "../../../basecomp/components/CTable2";
import WarehouseLocationVm from "../viewmodel/WarehouseLocationVm";
import CText from "../../../basecomp/components/CText";

const WareHouseView = () => {
  const {
    wareHouseMaterialData,
    infoMatHeadData,
    infoMatRowData,
    terminal_id,
    storage_loc,
  } = WarehouseLocationVm();

  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={10}
      lg={10}
      xl={10}
      sx={{ margin: "5em 0.5em 0.5em 0.5em" }}
    >
      <Card sx={{ padding: "2.5em" }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "2em 0em",
          }}
        >
          {wareHouseMaterialData.length > 0 && (
            <CText
              cText={`Plant ID - Storage Location: ${terminal_id} ${
                storage_loc != null ? `- ${storage_loc}` : ""
              }`}
            />
          )}
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {wareHouseMaterialData.length > 0 && (
            <CTable2
              cHeaderData={infoMatHeadData}
              cRowData={infoMatRowData}
              cActualData={wareHouseMaterialData}
              cIsPagination={true}
              cSmallSize={true}
            />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default WareHouseView;
