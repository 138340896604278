import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const {
  axiosGetCallWithToken,
  axiosPostCallWithToken,
  axiosDeleteCallWithToken,
  axiosPutCallWithToken,
} = AxiosWrapper;

//Default Section : Create new record in job_id_catalogue_table
export const addJobbIdCatalogue = async (data) => {
  try {
    const response = await axiosPostCallWithToken(
      `${Base_URL}/dcatalogue/addJobIdCatalogue`,
      data
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error creating job ID catalogue:", error);
    throw error;
  }
};

//Default section : Update type to WO
export const updateType = async () => {
  try {
    const response = await axiosPutCallWithToken(
      `${Base_URL}/dcatalogue/updateType`
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error creating work order:", error);
  }
};

//Default Section : Get unique equipment_id and equipment_number for shopping basket dropdown
export async function getEquipmentForDropdown() {
  try {
    const url = `${Base_URL}/dcatalogue/getEquipmentForDropdown`;

    const response = await axiosGetCallWithToken(url);
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error fetching equipment for dropdown:", error);
  }
}

//Default Section : Generate new Job card number logic (Job Id Catalogue)
export const getJobCardNo = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getJobCardNo`
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error getting JobCardNo:", error);
  }
};
