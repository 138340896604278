import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import LoginVM from "../viewmodel/LoginVM";
import CButton from "../../components/CButton";
import CInput from "../../components/CInput";

const LoginView = ({ cLogo, cBgImg, handleToastOpen }) => {
  const { email, setEmail, handleLogin } = LoginVM({ handleToastOpen });

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${cBgImg})`,
        backgroundSize: "cover"
      }}
    >
      <Card
        style={{
          padding: "4em 4.5em",
          maxWidth: 400,
          minWidth: 300,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10em"
        }}
      >
        <img
          src={cLogo}
          alt="Company Logo"
          style={{
            width: "100%",
            height: "50%",
            marginBottom: "4.5em"
          }}
        />

        <CInput
          cId="login_email"
          cHintText="Email"
          cOnChange={(e) => setEmail(e.target.value)}
          cIsRequired="true"
          cFullWidth="true"
        />

        {/* <CButton
          cText="Login"
          cFullWidth="true"
          cOnClick={handleLogin}
        /> */}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            borderRadius: "1.0em",
            marginTop: "2.2em",
          }}
          onClick={handleLogin}
        >
          Login
        </Button>

      </Card>
    </div>
  );
};

export default LoginView;
