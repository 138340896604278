import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const StyledFooterImage = styled("img")(() => ({
  width: "6.25em",
  height: "auto",
  marginRight: "0.18em",
}));

const Footer = ({ cLogo }) => {
  return (
    <footer>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        paddingBottom="0.25em"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            margin: "0.31em",
            paddingRight: "0.18em",
          }}
        >
          Developed By
        </Typography>

        <StyledFooterImage src={cLogo} alt="Footer Logo" />
      </Grid>
    </footer>
  );
};

export default Footer;
