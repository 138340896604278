import React, { useState } from "react";
import Drawer from "../main/Drawer";
import { Grid, Typography, AppBar, Avatar, Popover, Box, useTheme, useMediaQuery } from "@mui/material";
import CText from "../components/CText";
import CButton from "../components/CButton";
import NavUtils from "./NavUtils";
import { themeMain } from "../components/wrapper/CThemeProvider";

const Header = ({
  cLogo,
  cLogoSmall,
  handleLogin,
  handleLogout,
  cUserName,
  cUserInitials,
  pageTitle,
  appRouteMap,
  drawerList,
  pathName,
  navigate,
  linkComponent
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const [profilePopover, setProfilePopover] = useState(null);
  const { handleLogoClick } = NavUtils();

  const handlePopOpen = (event) => {
    setProfilePopover(event.currentTarget);
  };

  const handlePopClose = () => {
    setProfilePopover(null);
  };

  return (
    <AppBar sx={{ backgroundColor: themeMain.palette.mainbg.main, padding: "0.5em 1em" }}>
      <Grid container alignItems="center">
        <Grid item xs={1.5} sm={1} md={1} lg={0.5} xl={0.5}>
          <Drawer
            appRouteMap={appRouteMap}
            drawerList={drawerList}
            pathName={pathName}
            linkComponent={linkComponent}
          />
        </Grid>
        <Grid item xs={1} sm={2.5} md={2} lg={2} xl={1.5}>
          <Box
            component="img"
            src={isXs ? cLogoSmall : cLogo}
            alt="Click to see All Services"
            onClick={() => handleLogoClick(cUserName, navigate)}
            sx={{
              maxWidth: "80%",
              maxHeight: "100%",
              cursor: "pointer",
              objectFit: "contain",
              height: "2em",
              width: "auto",
            }}
          />
        </Grid>
        <Grid item xs={7} sm={5} md={6} lg={6.5} xl={7}
          sx={{
            textAlign: "center",
            overflow: "hidden"
          }}>
          <Typography
            color="secondary"
            fontSize={isXs ? "1.5rem" : "2rem"}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item xs={2.5} sm={3.5} md={3} lg={3} xl={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {cUserName && (
            <div>
              <Avatar
                sx={{
                  padding: "0.2em",
                  bgcolor: themeMain.palette.secondary.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: themeMain.palette.secondary.dark },
                }}
                onClick={(e) => handlePopOpen(e)}
              >
                {cUserInitials}
              </Avatar>

              <Popover
                sx={{ marginTop: "0.5em" }}
                open={Boolean(profilePopover)}
                anchorEl={profilePopover}
                onClose={handlePopClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ padding: "1.5em" }}>
                  <CText cVariant="head_card" cText={cUserName} />
                  <Box item sx={{ marginTop: "1em" }}>
                    <CButton
                      cId="log_out"
                      cTooltipText="LogOut"
                      cOnClick={handleLogout}
                      cFullWidth={true}
                      cText="LogOut"
                    />
                  </Box>
                </Box>
              </Popover>
            </div>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default Header;
