import { useEffect, useState } from "react";
import {
  ImageUpload,
  deleteLocalAndGlobalMaterialImages,
  gblNameOrLocalNameCheck,
  getLocalAndGlobalMaterialImages,
} from "../model/ImageUpload";
import Logger from "../../../basecomp/base/Logger";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import {
  MaterialNumberRequired,
  MaxImageSelect,
  ImageRequired,
  maxSizePerFile,
  imageSize,
  MaxImageUpload,
  MaterialImageDeleted,
} from "../../assets/Strings";

const ImageUploadVM = () => {
  const [globalType, setGlobalType] = useState("");
  const [inputBoxValueName, setInputBoxValueName] = useState("");
  const [materialReq, setMaterialReq] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const { handleToastOpen } = useToast();

  const [previewImages, setPreviewImages] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [totalImages, setTotalImages] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);
  const matNoFromUrl = urlParams.get("mat_no");
  const mat_no = matNoFromUrl || "";

  const [openModal, setOpenModal] = useState(false);
  const [adminImageAccess, setAdminImageAccess] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [fileSelected, setFileSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMaterialReq(mat_no);
  }, [mat_no, setMaterialReq]);

  useEffect(() => {
    handleGlobalLocalCheck();
  }, []);

  useEffect(() => {
    if (materialReq.length >= 5) {
      setLoadingData(true);
      fetchLocalAndGlobalMatImgs();
    } else {
      setPreviewImages([]);
    }
  }, [materialReq]);

  const handleGlobalLocalCheck = async () => {
    try {
      const email = localStorage.getItem("email");
      const response = await gblNameOrLocalNameCheck(email);
      setGlobalType(response.data.is_global);
      setInputBoxValueName(response.data.labelName);
      setAdminImageAccess(response.data.isAccess);
    } catch (error) {
      Logger.printStackTrace(error);
    }
  };
  const fetchLocalAndGlobalMatImgs = async () => {
    try {
      setLoadingImages(true);
      const response = await getLocalAndGlobalMaterialImages(
        materialReq,
        globalType
      );
      if (response.data && Array.isArray(response.data)) {
        setPreviewImages(response.data);
        setTotalImages(response.data.length);
      } else {
        setPreviewImages([]);
        setTotalImages(0);
      }
    } catch (error) {
      Logger.printStackTrace(error);
    } finally {
      setLoadingData(false);
      setLoadingImages(false);
    }
  };

  const handleFileChange = (event) => {
    setSelectedImages(event.target.files);
    const selectedFiles = event.target.files;
    const selectedFilesCount = selectedFiles.length;
    setFileSelected(selectedFilesCount);
    console.log(selectedFilesCount);
  };

  const handleSubmit = async () => {
    if (!materialReq) {
      handleToastOpen("error", MaterialNumberRequired);
      return;
    }
    if (!selectedImages || selectedImages.length === 0) {
      handleToastOpen("error", ImageRequired);
      return;
    }
    if (selectedImages.length > 5) {
      handleToastOpen("error", MaxImageSelect);
      return;
    }
    if (selectedImages.length + totalImages > 5) {
      handleToastOpen("error", MaxImageUpload);
      return;
    }
    // Validate each image size and total size
    let totalSize = 0;

    for (const image of selectedImages) {
      totalSize += image.size;

      if (image.size > maxSizePerFile) {
        handleToastOpen("error", imageSize);
        return;
      }
    }
    setLoading(true);
    try {
      await ImageUpload(
        materialReq,
        globalType,
        selectedImages,
        handleToastOpen
      );
      await fetchLocalAndGlobalMatImgs();
      // Clear selected images after successful upload
      setSelectedImages([]);
      setFileSelected(0); // Reset file selected count
      const fileInput = document.getElementById("image-id");
      if (fileInput) {
        fileInput.value = ""; // Reset file input value
      }
    } catch (error) {
      Logger.printStackTrace(error);
    } finally {
    setLoading(false); // Set loading to false after completion
  }
  };

  const handleDeleteClick = (imageUrl) => {
    setImageUrl(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmDelete = async () => {
    const response = await deleteLocalAndGlobalMaterialImages(materialReq,globalType,imageUrl);
    if (response) {
      setImageUrl("");
      handleCloseModal();
      await fetchLocalAndGlobalMatImgs();
      await handleToastOpen("success", MaterialImageDeleted);
    }
  };
  function getImageName(imageUrl) {
    const urlParts = imageUrl.split("/");
    const imageName = urlParts[urlParts.length - 1];
    return imageName;
  }

  return {
    materialReq,
    setMaterialReq,
    selectedImages,
    handleFileChange,
    handleSubmit,
    inputBoxValueName,
    previewImages,
    loadingData,
    loadingImages,
    globalType,
    handleConfirmDelete,
    handleCloseModal,
    handleDeleteClick,
    openModal,
    getImageName,
    adminImageAccess,
    fileSelected,
    loading,
  };
};

export default ImageUploadVM;
