const PdfPageStyle = {
  paperStyle: { display: "flex", justifyContent: "flex-end" },
  paperTopMargin: { marginTop: "5em" },
  pdfPreviewRoute:'/download-pdf',
  imageUploadNav:'/warehouse/image-upload'
};

const labelConstant = {
  labelNav: "/warehouse/label-printing",
  backPageToolTip: "Go Back to the label page",
  downloadToolTip: "Start the download pdf",
  goBackText:"Go Back",
  downloadText:"Download PDF" 
};

const QRDetailStyle = {
  containerStyle: {
    width: "fit-content",
    height: "fit-content",
    margin: "1em 12em",
    boxShadow: "2px 2px 3px 3px rgba(0, 0, 0, 0.2)",
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxStyle: { display: "flex", alignItems: "center" },
  topZero: { top: "0" },
  paddingLeft: { paddingLeft: ".5em" },
  boxContainer: {
    paddingBottom: ".8em",
    top: "0",
  },
  boxDesign: {
    paddingBottom: ".8em",
    lineHeight: "0",
    display: "flex",
    justifyContent: "space-between",
  },
  materialNumber: {
    textAlign: "start",
    margin: "0",
    whiteSpace: "nowrap",
    width: "100px",
    fontSize: ".75em",
    paddingRight: ".5em",
  },
  unit: {
    margin: "0",
    textAlign: "end",
    fontSize: ".75em",
  },
  paddingBottom: {
    paddingBottom: ".5em",
  },
  materialDesc: {
    margin: "0",
    lineHeight: "0",
    paddingBottom: ".8em",
    fontSize: ".75em",
  },
  dateBox: { display: "flex", justifyContent: "space-between" },
  dateText: {
    margin: "0",
    fontSize: ".75em",
  },
  poText: {
    marginLeft: "0.5em",
    fontSize: ".75em",
  },
};

const QRCdPdfStyle = {
  pdfContainer: {
    width: "fit-content",
    height: "fit-content",
    margin: "1em 12em",
    boxShadow: "2px 2px 3px 3px rgba(0, 0, 0, 0.2)",
    padding: "1em",
  },
  boxStyle: { display: "flex", alignItems: "center" },
  codeBox: { display: "flex", alignItems: "top" },
  padding: { paddingRight: "1em" },
  detailsBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "0.2em",
    paddingLeft: "0.2em",
  },
  detailsFirstRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  materialText: {
    textAlign: "start",
    padding: "0.1em 0",
    margin: "0",
    whiteSpace: "nowrap",
    width: "100px",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  unit: {
    padding: "0 0 0.1em 6em",
    margin: "0",
    textAlign: "end",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  materialDescText: {
    padding: "0",
    margin: "0",
    fontSize: ".5em",
    fontWeight: "bold",
    lineHeight: "0",
  },
  binLoc: {
    padding: "0.1em 0",
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100px",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  matSecondRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  dateText: {
    padding: "0.2em 0",
    margin: "0",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  poText: {
    padding: "0.2em 0",
    margin: "0 0 0 0.8em",
    fontSize: ".75em",

    fontWeight: "bold",
  },
  tableMargin: { marginTop: ".5em" },
  tableStyle: {
    borderCollapse: "collapse",
    border: "1px solid black",
    width: "100%",
  },

  cellStyle: {
    border: "1px solid black",
    padding: "2px 5px",
    fontSize: ".7em",
    fontWeight: "bold",
  },
};

const QrCdPdfTableStyle = {
  borderCollapse: "collapse",
  border: "1px solid black",
  width: "100%",
};

const QRCdPdfCellStyle = {
  border: "1px solid black",
  padding: "2px 5px",
  fontSize: ".7em",
  fontWeight: "bold",
};

const QRLogoPdfStyle = {
  pdfContainer: {
    width: "fit-content",
    height: "100%",
    margin: "1em",
    boxShadow: "2px 2px 3px 3px rgba(0, 0, 0, 0.2)",
    padding: "1em",
    display: "flex",
    flexDirection: "column",
  },
  imageStyle: {
    width: "6em",
    height: "2em",
  },
  detailsBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "start",
    width: "fit-content",
  },
  qrStyle: {
    marginRight: "1em",
    justifyContent: "flex-start",
    top: "0",
  },
  detailsRowFlex: { flex: 1 },
  rowContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "100%",
  },
  rowFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  matText: {
    textAlign: "start",
    padding: "0.1em 0",
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "200px",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  unitText: {
    padding: "0 0 0em 6em",
    margin: "0",
    textAlign: "end",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  poText: {
    padding: "0",
    margin: "0",
    fontSize: ".75em",
    fontWeight: "bold",
    lineHeight: "0",
  },
  dateAndPoRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  binLoc: {
    padding: "0em 0",
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100px",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  dateText: {
    padding: "0em 0",
    margin: "0",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  poNo: {
    padding: "0.2em 0",
    margin: "0 0 0 0.2em",
    fontSize: ".75em",
    fontWeight: "bold",
  },
  tableMargin:{ marginTop: ".4em" },
};

const QRLogoTableStyle = {
  borderCollapse: "collapse",
  border: "1px solid black",
  width: "100%",
};

const QRLogoCellStyle = {
  border: "1px solid black",
  padding: "2px 10px",
  fontSize: ".8em",
  fontWeight: "bold",
};

const QRCdLogoPdfStyle = {
  pdfContainer: {
    width: "fit-content",
    height: "100%",
    margin: "1em 12em",
    boxShadow: "2px 2px 3px 3px rgba(0, 0, 0, 0.2)",
    padding: "1em 1em 5em 1em",
    display: "flex",
    flexDirection: "column",
  },
  imageStyle:{
    width: "6em",
    height: "2.2em",
  },
  qrContainer:{
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "start",
  },
  qrStyle:{
    marginRight: "1em",
    justifyContent: "flex-start",
    top: "0",
  },
  detailsBoxFlex:{ flex: 1 },
  detailsBox:{
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  detailsRowFlex:{
    display: "flex",
    justifyContent: "space-between",
  },
  matText:{
    textAlign: "start",
    padding: "0.1em 0",
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100px",
    fontSize: '.75em',
    fontWeight: "bold",
  },
  unitText:{
    padding: "0 0 0em 6em",
    margin: "0",
    textAlign: "end",
    fontSize: '.75em',
    fontWeight: "bold",
  },
  poText:{
    padding: "0",
    margin: "0",
    fontSize: '.75em',
    fontWeight: "bold",
    lineHeight: "0",
  },
  binLoc:{
    padding: "0.1em 0",
    margin: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100px",
    fontSize: '.75em',
    fontWeight: "bold",
  },
  dateAndPoRow:{
    display: "flex",
    justifyContent: "space-between",
  },
  dateText:{
    padding: "0.2em 0",
    margin: "0",
    fontSize: '.75em',
    fontWeight: "bold",
  },
  poNo:{
    padding: "0.2em 0",
    margin: "0 0 0 0.8em",
    fontSize: '.75em',
    fontWeight: "bold",
  },
  tableMargin:{ marginTop: "1em" }
};

const QRCdLogoPdfTableStyle = {
  borderCollapse: "collapse",
  border: "1px solid black",
  width: "100%",
};

const QRCdLogoPdfCellStyle = {
  border: "1px solid black",
  padding: "1px 5px",
  fontSize: ".8em",
  fontWeight: "bold",
};

const MultipleA4Style = {
  pageStyle:{
    height: "900px",
    width: "370px",
    boxShadow: "2px 2px 3px 3px rgba(0, 0, 0, 0.2)",
    marginLeft: "5em",
    marginBottom: "2em",
    padding:'1em',
  },
  containerStyle: {
    width: "fit-content",
    height: "fit-content",
    marginTop: "2em",
    marginLeft:"1em",
    padding: ".5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:'center',
    border:'2px solid black'
  },
  secondBoxStyle:{
    marginTop:'8em'
  }
}

const Label_Printing_Container={
  Main_Container:{marginTop:'6em',padding:'0 10em',marginBottom:'2em'},
  Box_container:{ display: "flex", justifyContent: "space-between" }

}

module.exports = {
  QRDetailStyle,
  labelConstant,
  PdfPageStyle,
  QRCdPdfStyle,
  QrCdPdfTableStyle,
  QRCdPdfCellStyle,
  QRLogoPdfStyle,
  QRLogoCellStyle,
  QRLogoTableStyle,
  QRCdLogoPdfStyle,
  QRCdLogoPdfTableStyle,
  QRCdLogoPdfCellStyle,
  MultipleA4Style,
  Label_Printing_Container
};
