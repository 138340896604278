import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import JobDetailsVM from "../viewmodel/JobDetailsVM";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CDropDown2 from "../../../../basecomp/components/CDropDown2";
import CInput2 from "../../../../basecomp/components/CInput2";
import CDateTimePicker from "../../../../basecomp/components/CDateTimePicker";

const styles = {
  table: {
    border: "1px solid #d3d3d3",
    borderCollapse: "collapse",
  },
  tableCell: {
    border: "1px solid #d3d3d3",
    textAlign: "center",
  },
};

const JobDetails = (props) => {
  const {
    spDetails,
    jobDirective,
    equipmentType,
    equipmentID,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    jobCardNumber,
    externalRef,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
    handleToastOpen,
  } = props;
  const {
    jobDropdown,
    handleStartDateChange,
    handleFinishDateChange,
    handleIconButtonClick,
    handleButtonDownload,
    isLoading,
  } = JobDetailsVM({
    spDetails,
    jobDirective,
    equipmentType,
    equipmentID,
    jobCardNumber,
    externalRef,
    jobType,
    section,
    resources,
    workDesc,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
    handleToastOpen,
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} sm={12} md={12} margin={1.5}>
        <AppBar
          position="static"
          sx={{
            padding: "0em",
            alignItems: "center",
            backgroundColor: "#13A538",
          }}>
          <Typography variant="h4">Job Details</Typography>
        </AppBar>
      </Grid>
      <Grid item xs={12} sm={12} md={12} margin={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2.2}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              Job Type
            </Typography>
            <CDropDown2
              cId="sb_job_type"
              cHelperText="Choose..."
              cData={jobDropdown && jobDropdown.type ? jobDropdown.type : []}
              cErrorMsg=""
              cOnChange={(newValue) => setJobType(newValue)}
              cIsSession={true}
              getOptionLabel={(option) => option || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || option === ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={2.2}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              Section
            </Typography>

            <CDropDown2
              cId="sb_section"
              cHelperText="Choose..."
              cData={
                jobDropdown && jobDropdown.section ? jobDropdown.section : []
              }
              cErrorMsg=""
              cOnChange={(newValue) => setSection(newValue)}
              cIsSession={true}
              getOptionLabel={(option) => option || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || option === ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={1.6}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              Resources
            </Typography>
            <CInput2
              cId="sb_resources"
              cType="number"
              cIsSession={true}
              cIsSmall
              cIsRequired
              cValue={resources === "" ? 1 : Math.max(1, resources)}
              cHtmlProps={{ min: 0 }}
              cOnChange={(value) => {
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue) && parsedValue >= 0) {
                  setResources(value === "" ? "" : parsedValue);
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              From
            </Typography>
            <CDateTimePicker
              cId="sb_start_date_time"
              cOnChange={handleStartDateChange}
              cIsSession={true}
              value={startDate}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              To
            </Typography>
            <CDateTimePicker
              cId="sb_finish_date_time"
              cOnChange={handleFinishDateChange}
              cIsSession={true}
              value={finishDate}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} paddingTop={1.5}>
          <Typography style={{ marginBottom: "0.5em" }}>
            Job Description
          </Typography>
          <CInput2
            cId="sb_work_desc"
            cValue={workDesc || ""}
            cOnChange={(value) => setWorkDesc(value)}
            cIsSession={true}
            cFullWidth={true}
            cRows={5}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} paddingTop={2}>
          <div style={{ overflowX: "auto" }}>
            {spDetails && spDetails.length > 0 ? (
              <Table id="jobdetails">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}>Location/Bin</TableCell>
                    <TableCell sx={styles.tableCell}>Material</TableCell>
                    <TableCell sx={styles.tableCell}>Description</TableCell>
                    <TableCell sx={styles.tableCell}>QTY</TableCell>
                    <TableCell sx={styles.tableCell}>UOM</TableCell>
                    <TableCell sx={styles.tableCell}>OHQ</TableCell>
                    <TableCell sx={styles.tableCell}>QR code</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spDetails
                    .filter((data) => data.material_no)
                    .map((data) => (
                      <TableRow key={data.id}>
                        <TableCell sx={styles.tableCell}>
                          {data.storage_bin !== "null" ? data.storage_bin : ""}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.material_no}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.material_desc}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.quantity}
                        </TableCell>
                        <TableCell sx={styles.tableCell} l>
                          {data.uom}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styles.tableCell,
                            input: { width: "20px", height: "20px" },
                          }}>
                          <input
                            type="checkbox"
                            defaultChecked={data.status === 1}
                            disabled
                          />
                        </TableCell>

                        <TableCell sx={styles.tableCell}>
                          <QRCodeSVG value={data.material_no} size={50} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : null}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={11.9}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1em",
          }}>
          <div style={{ display: "flex" }}>
            <Tooltip title=" Job Details Email">
              <IconButton
                id="email_job_details"
                style={{
                  backgroundColor: "#13A538",
                  color: "#FFFFFF",
                  borderRadius: 4,
                  height: "1.1em",
                  width: "1.4em",
                }}
                onClick={handleIconButtonClick}>
                <MailOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Job Details Pdf">
              <IconButton
                id="download_job_details"
                style={{
                  backgroundColor: "#13A538",
                  color: "white",
                  borderRadius: 4,
                  height: "1.1em",
                  width: "1.4em",
                  marginLeft: "1em",
                }}
                onClick={handleButtonDownload}>
                <CloudDownloadOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetails;
