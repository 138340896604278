import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const { axiosGetCallWithToken, axiosGetCallWithTokenHeader } = AxiosWrapper;

//Job Details : Get data from job_id_catalogue_table whose job card no/id is passed in query for historical details page
export const fetchJobDetails = async (jobCardNumber) => {
  try {
    if (!jobCardNumber) {
      throw new Error("job_card_no is undefined or invalid");
    }
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getJobIdCatByJobNos/${jobCardNumber}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching job details:", error);
  }
};

// Replenishment : Email and pdf
export const replenishmentEmail = async (
  jobId,
  equipmentId,
  jobDirective,
  type
) => {
  try {
    const url = `${Base_URL}/dcatalogue/getPdf?jobId=${jobId}&equipmentId=${equipmentId}&jobDirective=${jobDirective}&type=${type}`;
    return await axiosGetCallWithTokenHeader(url);
  } catch (error) {}
};
