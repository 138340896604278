import React from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

/**
 * Component for input
 * @param cId Id of the Input Component 
 * @param cVariant Variant handles the look and feel of Input Component [Should not be used outside components]
 * @param cHintText Hint text is for the text inside the input box of Input Component
 * @param cHelperText Helper Text is for the text below input box of Input Component
 * @param cDisabled Set this to true in order to disable the Input Component
 * @param cOtherMsg Set this message to show error / success messages over helper text
 * @param cOtherType Set this error / success 
 * @param cType Set the type of value that can be entered in Input Component - e.g. text(default), number, search....
 * @param cRows Handle no. of rows for tall box in Input Component, default 10
 * @param cOnChange Handle the change in text and provide value to initial view for Input Component
 * @param cHtmlProps Handle the basic props for Input Component 
 *                   [pass additional properties directly to the <input> element rendered by the TextField]
 * @param cInputProps Handle the basic props for Input Component 
 *                    [pass additional properties and configurations to the Input component 
 *                    (the component that Material-UI uses internally to render the input)]
 * @param cDropDown If the input is used in dropdown, this is set of Input Component 
 *                  [Should not be used outside dropdown components]
 * @param cValue The value which is entered or put from outside of Input Component
 * @param cKeyReset This value resets the values, forces remounting of Input Component
 * @param cHandleSubmit For some places required to submit the form on clicking of enter of Input Component
 * @param cIsSmall In some places small size is required of Input Component
 * @param cIsRequired Required field property is set, adds a star to the text for Input Component
 * @param cFullWidth In order to create fullwidth for Input Component
 * @param cEndAdornment Adding properties like UNIT at the end for Input Component
 */
const CInput2 = ({
    cId,
    cVariant,
    cHintText,
    cDisabled,
    cHelperText,
    cOtherMsg,
    cOtherType,
    cType,
    cRows,
    cOnChange,
    cHtmlProps,
    cInputProps,
    cDropDown,
    cValue,
    cKeyReset,
    cHandleSubmit,
    cIsSmall,
    cIsRequired,
    cFullWidth,
    cEndAdornment,
    cIsSession
}) => {

    if (cValue === undefined) {
        const sessionItem = sessionStorage.getItem(cId);
        cValue = sessionItem;
    }

    return (
        <TextField
            id={cId}
            key={cKeyReset}
            disabled={cDisabled}
            label={cHintText === undefined ? "" : cHintText}
            color={cOtherType === "success" ? "success" : "primary"}
            focused={cOtherType === "success"}
            variant={cVariant === undefined ? "outlined" : cVariant}
            error={cOtherType === "error"}
            type={cType === undefined ? "text" : (cType === "quantity" ? "number" : cType)}
            helperText={cOtherMsg === undefined ? (cHelperText === undefined ? " " : cHelperText) : cOtherMsg}
            multiline={cRows > 1 ? true : false}
            inputProps={cHtmlProps}
            InputProps={cEndAdornment ? {
                endAdornment: (
                    <InputAdornment position="end">{cEndAdornment}</InputAdornment>
                ),
            } : cInputProps}
            onChange={(event) => {
                if (cOnChange !== undefined) {
                    if (cType === "file") {
                        cOnChange(event.target.files);
                    } else if (cType === "quantity") {
                        let changeValue = event.target.value;
                        const parsedValue = parseInt(changeValue);
                        //if (isNaN(parsedValue) || parsedValue === 0) {
                        //event.target.value = cValue || 1;
                        if (parsedValue < 0) {
                            event.target.value = 0;
                            changeValue = 0;
                        }
                        cOnChange(changeValue);
                        if (cIsSession) {
                            sessionStorage.setItem(cId, changeValue);
                        }
                    } else {
                        const changeValue = event.target.value;
                        cOnChange(changeValue);
                        if (cIsSession) {
                            sessionStorage.setItem(cId, changeValue);
                        }
                    }
                }
            }}
            rows={cRows === undefined ? 10 : cRows}
            fullWidth={cFullWidth}
            {...cDropDown}
            defaultValue={cValue}
            onKeyDown={(event) => {
                if (cHandleSubmit !== undefined && event.key === "Enter") {
                    cHandleSubmit();
                }
                if (cType === "quantity") {
                    if (event.key === '.' || event.key === '-') {
                        event.preventDefault();
                    }
                }
            }}
            required={cIsRequired}
            size={cIsSmall ? "small" : "medium"}
        />
    );
};

export default CInput2;
