import { useState, useEffect } from "react";
import { getPlantOptions, submitFormData } from "../model/GSMainPage";
import { useLocation } from "react-router-dom";
import Logger from "../../../basecomp/base/Logger";
import { getSparePartDetails } from "../model/GSMainPage";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";

function useRegistrationDataViewModel() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const asset_id =
    decodeURIComponent(searchParams.get("asset_id")) !== "null"
      ? decodeURIComponent(searchParams.get("asset_id"))
      : "";
      const { handleToastOpen } = useToast();

  const [plantOptions, setPlantOptions] = useState([]);
  const [material, setMaterial] = useState("");
  const [materialCheckResult, setMaterialCheckResult] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [additionalFieldName, setAdditionalFieldName] = useState("");
  const [selectedObjImg, setSelectedObjImg] = useState(null);
  const [selectedAssetImg, setSelectedAssetImg] = useState(null);
  const [selectedKMHours, setSelectedKMHours] = useState(null);

  const handleImageUploadObj = (event) => {
    setSelectedObjImg(event.target.files[0]);

    console.log("selectedObjImg", selectedObjImg);
  };

  useEffect(() => {
    // This useEffect will run whenever selectedAssetImg changes.
    // You can perform any actions related to the image here.
    console.log("Selected Object Image (in useEffect):", selectedObjImg);
  }, [selectedObjImg]);

  const handleImageUploadAsset = (event) => {
    setSelectedAssetImg(event.target.files[0]);

    console.log("selectedAssetImg", selectedAssetImg);
  };

  useEffect(() => {
    // This useEffect will run whenever selectedAssetImg changes.
    // You can perform any actions related to the image here.
    console.log("Selected Asset Image (in useEffect):", selectedAssetImg);
  }, [selectedAssetImg]);

  useEffect(() => {
    fetchPlantOptions();//Move this to GSMainPage and then handle login redirect
  }, []);

  const fetchPlantOptions = async () => {
    try {
      const response = await getPlantOptions();
      setPlantOptions(response.data);
    } catch (error) {
      Logger.printStackTrace(error);
    };
  };

  useEffect(() => {
    if (material) {
      MaterialData();
    }
  }, [material]);

  const MaterialData = async () => {
    try {
      const response = await getSparePartDetails(material);

      if (response.data && response.data[0] && response.data[0].material_desc) {
        setMaterialCheckResult(response.data[0]);
      } else {
        setMaterialCheckResult(null);
      }
    } catch (error) {
      setMaterialCheckResult(null);
      Logger.printStackTrace(error);
    }
  };

  const handleOption1Change = (event) => {
    setOption1(event.target.value);
  };

  const handleOption2Change = (event) => {
    setOption2(event.target.value);
    setShowAdditionalFields(
      event.target.value === "option1" || event.target.value === "option2"
    );
    setAdditionalFieldName(
      event.target.value === "option1" ? "Fuel Asset ID" : "Material Number"
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Clear the fields except for asset_id
    setSelectedKMHours('');
    setSelectedObjImg(null);
    setSelectedAssetImg(null);
    setMaterial('');
    setMaterialCheckResult('');
    setOption1('');
    setOption2('');

      // Ensure that the file input elements are also cleared
  document.getElementById("object-photo").value = null;
  document.getElementById("id-plate-photo").value = null;
  
    // Show a success toast message
    handleToastOpen("success", "Asset added successfully");
  };
  

  return {
    plantOptions,
    option1,
    option2,
    showAdditionalFields,
    material,
    materialCheckResult,
    additionalFieldName,
    handleOption1Change,
    handleOption2Change,
    setMaterial,
    handleSubmit,
    handleImageUploadObj,
    handleImageUploadAsset,
    selectedKMHours,
    setSelectedKMHours
  };
}

export default useRegistrationDataViewModel;
