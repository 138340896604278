import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const {
  axiosGetCallWithToken,
  axiosPostCallWithToken,
  axiosDeleteCallWithToken,
  axiosPutCallWithToken,
} = AxiosWrapper;

//Shopping Basket : Truncate sp_basket_table all of latest user and terminal id (Sp Basket)
export const truncates = async () => {
  try {
    const response = await axiosDeleteCallWithToken(
      `${Base_URL}/dcatalogue/deletepBasket`
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error truncating table:", error);
    throw error;
  }
};

//Shopping Basket : Update quantity in spbasket (Sp Basket)
export const updateQuantity = async (id, editedQuantity) => {
  try {
    await axiosPutCallWithToken(`${Base_URL}/dcatalogue/updateQuantity`, {
      basket_id: id,
      quantity_ord: editedQuantity,
    });
  } catch (error) {
    Logger.messageStackTrace("Error updating data:", error);
    throw error;
  }
};

//Shopping Basket : Delete row data by its ID (Sp Basket)
export const deleteData = async (id) => {
  try {
    await axiosDeleteCallWithToken(
      `${Base_URL}/dcatalogue/deleteSpRecord?id=${id}`
    );
  } catch (error) {
    Logger.messageStackTrace("Error deleting data:", error);
    throw error;
  }
};

//Shopping Basket : Get all data from sp_basket_table by terminal_id  and type="SB" (Sp Basket)
export const getSQ01AndSpBasket = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getSpBasketAll`
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching data:", error);
  }
};

//Shopping Basket : Generate new Job card number logic (Job Id Catalogue)
export const getJobCardNo = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getJobCardNo`
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error getting JobCardNo:", error);
  }
};

//Shopping Basket: Email all records in sp basket based on latest user and terminal
export const postDCatBasketEmail = async (requestBody) => {
  try {
    const response = await axiosPostCallWithToken(
      `${Base_URL}/dcatalogue/postDCatBasketEmail`
    );

    return response;
  } catch (error) {
    Logger.messageStackTrace("Error posting data:", error);
    throw error;
  }
};

//Shopping Basket: Email only grey records in sp basket based on latest user and terminal
export const postGreyRecordBasketEmail = async (data) => {
  try {
    const response = await axiosPostCallWithToken(
      `${Base_URL}/dcatalogue/postGreyRecordBasketEmail`,
      data
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error posting data:", error);
    throw error;
  }
};
