/* eslint-disable jsx-a11y/alt-text */
import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import Pic from "../assets/pic.jpg";
import Pics from "../assets/pics.jpg";
import Pizz from "../assets/pizz.png";
import Grid from "@mui/material/Grid";

function DigitalLinkPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title">
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              Digital Link
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl" style={{ marginBottom: "-2em" }}>
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <p
                className="fontclass text-color"
                align="justify"
                style={{ fontSize: "1.3rem", margin: "0" }}
              >
                <b style={{ fontSize: "1.7rem" }}>External serial numbers</b>
                <br></br>
                Many customers are struggling with the implementation of
                serialized parts. When you own and maintain assets, you are
                confronted with many different asset components and spare parts
                of many different manufacturers. Each having their own part
                coding and serial number.
                <br></br>
                Often it is already difficult for maintenance or warehouse staff
                to understand which number on the product is the serial number
                and then it is a complex job to enter this number into your ERP
                system. The manufacturer’s serial number contains many digits
                but also dots, hyphens, slashes and backslashes. Whenever the
                number is also printed in barcode, all part manufacturers use
                different barcode standards.<br></br>
                As a result, the manufacturer serial numbers are very difficult
                to use.
                <br></br>
                <br></br>
                <b style={{ fontSize: "1.7rem" }}>GIAI</b> <br></br>
                <a href="https://www.gs1.org/about">GS1</a> has a very good
                solution for this problem called the Global Individual Asset
                Identifier, the GIAI.<br></br>
                GS1 is a neutral, global collaboration platform that brings
                industry leaders, government, regulators, academia, and
                associations together to develop standards-based solutions.
                <br></br>
                GS1 is the number 1 worldwide standard used in retail, but also
                in{" "}
                <a href="https://www.gs1.org/industries/technical-industries">
                  technical industries
                </a>
                , GS1 has a lot to offer.<br></br>
                Below video provides good general overview of using the GS1
                standards in the rail industry<br></br>
                <br></br>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/pcj7Pso0sNs"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                ></iframe>
                <br></br>
                <i>GS1 standards keep rail companies on track</i>
              </p>
              <br></br>

              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                Below video provides good information of an asset owner
                implementing the GS1 GIAI<br></br>
                <br></br>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/noWUiZY-UFU"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                ></iframe>
                <br></br>
                <i>V-line A Project i-TRACE success story</i>
              </p>
              <br></br>
              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                Conas can help you implementing the GIAI successfully in your
                maintenance organization. <br></br>
                <br></br>
                <b style={{ fontSize: "1.7rem" }}>GS1 digital link</b>
                <br></br>
                Using the GIAI in itself already offers significant value to
                maintenance organizations, but when the GIAI is combined with
                the use of GS1 digital link, a maintenance organization can
                create its own asset & spare part data eco system.<br></br> But
                before getting into this, first some general info regarding GS1
                digital link using the GS1 resolver used in a Business to
                Consumer (B2C) and Business to Business (B2B) environment.
              </p>
              <br></br>

              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Mj45b4FQaMQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                ></iframe>
                <br></br>
                <i>B2C - Digital link and QR codes</i>
              </p>

              <br></br>
              <br></br>
              <br></br>

              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/JW1ZR2S8878"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                ></iframe>
                <br></br>
                <i>GS1 Digital link B2B - Funciones inter empresariales</i>
              </p>

              <br></br>
              <br></br>
              <br></br>

              <div className="fontclass">
                <b style={{ fontSize: "1.7rem" }}>D-link</b>
              </div>

              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem" }}
              >
                There is a lot of focus to make digital link possible in a
                retail environment, but digital link offers tremendous value in
                maintenance as well, combined with the use of the GIAI.<br></br>
                Together with GS1, Conas developed a proof of concept using
                digital link in combination with the GIAI.<br></br>
                Spare parts and other maintenance objects are tagged by using a
                GIAI ID plate. See examples below.<br></br>
              </p>
              <br></br>

              <Grid container spacing={3} style={{ justifyContent: "center" }}>
                <Grid item xs={12} sm={6} md={3.7}>
                  <img className="img-fluid zoomIn" src={Pic} />
                </Grid>

                <Grid item xs={12} sm={6} md={3.7}>
                  <img className="img-fluid zoomIn" src={Pics} />
                </Grid>

                <Grid item xs={12} sm={6} md={3.7}>
                  <img className="img-fluid zoomIn" src={Pizz} />
                </Grid>
              </Grid>

              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem" }}
              >
                This proof of concept is now being implemented with several of
                our customers. The GS1 GIAI is used for the unique
                identification of spare parts and assets. All developed Conas
                applications can use this identification by scanning the GIAI
                and linking to different applications.<br></br> This way, a
                single scan of the object ID using any phone or tablet, gives
                you access to relevant information, after passing the required
                login and security validation. Examples of relevant info are the
                object identification (internal material number & description),
                inventory overview, drawings, and access to transactional
                systems). For this we are just connecting to your own systems
                and BI environment. No need to implement a Product Information
                System (PIM) which can be complex and expensive. Of course, we
                can also connect your asset via the resolver to your PIM system.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default DigitalLinkPage;
