export const requiredFields = "Please fill in all required fields!";

export const noSizeAvailable = "No size available!";

export const notSelectSize = "Select the size!";

export const selectFileUpload = "No file is selected!";

export const zeroQuantity = "Quantity cannot be zero!";

export const materialNotPresent = "This Material is not present";

export const materialAdded = "Material added successfully!";

export const materialUpdated = "Material updated successfully!";

export const binLocationUpdated = "Bin Location updated successfully!";

export const binLocationLength = "Bin Location length must be in between 1-10";

export const materialDeleted = "Materials deleted successfully!";

export const materialAlreadyDeleted = "Material is already deleted!";

export const materialAlreadyPresented = "Material is already Presented!";

export const fileUploaded = "File uploaded successfully!";

export const dataAlreadyDeleted = "Data is already deleted!";

export const somethingWrong = "Something went wrong!";

export const downloadStarted = "Download Started!";

export const dataNotMatching = "Labels Data is not matching!";

export const missingData = "Missing data!";

export const invalidFileType = "Invalid File Type"

export const bulkLocationAdded = "Bulk Location added successfully!";

export const FILE_DOWNLOAD_FAILED="File download Unsuccessful";

export const NO_DATA="No Data Found";

export const DATE_CHECK="Start date cannot be after end date"

export const DATE_REQUIRED="Please fill both start and end dates"

export const FILE_DOWNLOAD_SUCCESS="File Downloaded Successfully"


