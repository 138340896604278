import React, { useState } from "react";
import CInput2 from "./CInput2";

export const IMG_TYPE = "IMG";
export const DOC_TYPE = "DOC";
export const XCEL_TYPE = "XCL";


const CChooseFile = ({ cFileType, cTooltipText, cDisabled, cHelperText, cHandleFileChange, cFullWidth }) => {

  let fileType = "";

  switch (cFileType) {
    case IMG_TYPE:
      fileType = ".jpg, .jpeg, .png";
      break;
    case DOC_TYPE:
      fileType = ".doc, .docx";
      break;
    case XCEL_TYPE:
      fileType = ".xls, .xlsx, .csv";
      break;
    default:
      console.error("Unknown file type");
      break;
  }

  if (cHelperText === undefined) {
    cHelperText = "Choose File: " + fileType;
  }
  return (
    <CInput2
      cTooltipText={cTooltipText}
      cDisabled={cDisabled}
      cHelperText={cHelperText}
      cType="file"
      cInputProps={{ shrink: true }}
      cHtmlProps={{
        accept: fileType, multiple: true
      }}
      cOnChange={cHandleFileChange}
      cFullWidth={cFullWidth}
    />
  );
};

export default CChooseFile;
