import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";

function PowerBIPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title">
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              Power BI
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl" style={{ marginBottom: "-4em" }}>
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                Reports and insights based on a company's data are generated
                using Microsoft Power BI. Power BI can connect to a variety of
                data sources and "cleans up" the information it receives so that
                it may be more easily digested and comprehended. This data may
                then be used to produce reports and visualisations that can be
                shared with other users. Power BI allows users to view not just
                what has occurred in the past and what is occurring now, but
                also what may occur in the future. Machine learning capabilities
                are included into Power BI, allowing it to recognise patterns in
                data and utilise those patterns to generate educated predictions
                and conduct "what if" scenarios. Users may use these estimations
                to create projections and plan for future demand and other
                crucial parameters.
              </p>
              <br></br>
              <br></br>
              <div
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                <b style={{ fontSize: "1.7rem" }}>
                  We provide the following Power BI services:
                </b>
                <ul
                  type="square"
                  className="mb-4"
                  align="justify"
                  style={{ fontSize: "1.3rem" }}
                >
                  <li>
                    {" "}
                    A novel technique to data analysis that allows you to easily
                    combine and extract data from numerous sources.
                  </li>

                  <li>
                    {" "}
                    Gaining a better understanding of company metrics. Spend
                    more time uncovering ideas and crafting your approach than
                    you spend gathering information.
                  </li>

                  <li>
                    {" "}
                    Create and deploy a PowerBI dashboard and report to share
                    data and insights more simply.
                  </li>

                  <li>
                    {" "}
                    A safe and secure method of reporting on your data source(s)
                  </li>

                  <li>
                    {" "}
                    Your organization's trained business analyst and report
                    writer, capable of producing future reports
                  </li>
                </ul>
                <br></br>
                <b style={{ fontSize: "1.7rem" }}>Benefits of Power BI:</b>
                <ul
                  type="square"
                  className="mb-4"
                  align="justify"
                  style={{ fontSize: "1.3rem" }}
                >
                  <li> Works in tandem with current apps.</li>

                  <li> Customized dashboards with lots of options</li>

                  <li> Securely publish reports</li>

                  <li> There are no memory or time limits.</li>

                  <li>
                    {" "}
                    Accurately and quickly extracting business intelligence
                  </li>

                  <li>
                    {" "}
                    Achieved a good balance of simplicity and performance
                  </li>
                </ul>
                <br></br>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default PowerBIPage;
