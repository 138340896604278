import React, { createContext, useContext, useState } from "react";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastObj, setToastObj] = useState({
    open: false,
    type: null,
    message: null,
  });
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate());
  const formattedCurrentDate = currentDate.toISOString().split("T")[0];

  // Get yesterday's date
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = yesterday.toISOString().split("T")[0];
  const handleToastOpen = (type, message) => {
    setToastObj({
      open: true,
      type: type,
      message: message,
      isMaintenance:
        type === "error" &&
        message ===
          "Data refresh ongoing, old data might be inaccessible during this duration. Please do not make any changes during this time",
      isOutdated:
        type === "error" &&
        (message ===
          `The data was refereshed last on ${formattedCurrentDate}` ||
          message === `The data was refereshed last on ${formattedYesterday}`),
    });
  };

  const handleToastClose = () => {
    setToastObj({ open: false });
  };

  return (
    <ToastContext.Provider
      value={{
        toastObj,
        handleToastOpen,
        handleToastClose,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
