import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import ReplenishmentVM from "../viewmodel/ReplenishmentVM";

const styles = {
  table: {
    border: "1px solid #d3d3d3",
    borderCollapse: "collapse",
  },
  tableCell: {
    border: "1px solid #d3d3d3",
    textAlign: "center",
  },
};

const Replenishment = (props) => {
  const {
    jobDirective,
    equipmentID,
    equipmentType,
    spDetails,
    jobCardNumber,
    handleToastOpen,
    jobType,
  } = props;
  const { mrpType, lotSize, handleIconButtonClick, isLoading } =
    ReplenishmentVM({
      spDetails,
      jobDirective,
      equipmentID,
      jobCardNumber,
      equipmentType,
      handleToastOpen,
      jobType,
    });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} margin={1.5}>
          <AppBar
            position="static"
            sx={{
              padding: "0em",
              alignItems: "center",
              backgroundColor: "#13A538",
            }}>
            <Typography variant="h4">Replenishment</Typography>
          </AppBar>
        </Grid>

        <Grid item xs={12} sm={12} md={12} margin={1.5}>
          <div style={{ overflowX: "auto" }}>
            <Table sx={styles.table} id="replenishment_table">
              <TableHead>
                <TableRow>
                  <Tooltip title="SAP material number">
                    <TableCell sx={styles.tableCell}>Material</TableCell>
                  </Tooltip>
                  <Tooltip title="SAP material description">
                    <TableCell sx={styles.tableCell}>Description</TableCell>
                  </Tooltip>
                  <Tooltip title="Quantity requested in shopping basket">
                    <TableCell sx={styles.tableCell}>QTY</TableCell>
                  </Tooltip>
                  <Tooltip title="On Hand Quantity during last monthly data refresh">
                    <TableCell sx={styles.tableCell}>OHQ</TableCell>
                  </Tooltip>
                  <Tooltip
                    title={mrpType.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}>
                    <TableCell sx={styles.tableCell}>MRP Type</TableCell>
                  </Tooltip>
                  <Tooltip
                    title={lotSize.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}>
                    <TableCell sx={styles.tableCell}>Lot Size</TableCell>
                  </Tooltip>
                  <Tooltip title="SAP MRP triggers if On Hand QTY is below ROP (exception: if ROP is 0, it triggers at 0)">
                    <TableCell sx={styles.tableCell}>Re-order Point</TableCell>
                  </Tooltip>
                  <Tooltip title="SAP MRP replenishes to the maximum stock level">
                    <TableCell sx={styles.tableCell}>
                      Maximum Stock Level
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="SAP MRP replenishes with a fixed lot size Quantity">
                    <TableCell sx={styles.tableCell}>Fixed Lot Size</TableCell>
                  </Tooltip>
                  <Tooltip title="Unit of Measure">
                    <TableCell sx={styles.tableCell}>UOM</TableCell>
                  </Tooltip>
                  <Tooltip title="Quick Response Code containing the SAP material number">
                    <TableCell sx={styles.tableCell}>QR code</TableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                {spDetails &&
                  spDetails
                    .filter((data) => data.material_no)
                    .map((data) => (
                      <TableRow key={data.id}>
                        <TableCell sx={styles.tableCell}>
                          {data.material_no}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.material_desc}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.quantity}
                        </TableCell>
                        <TableCell
                          sx={styles.tableCell}
                          style={{
                            backgroundColor:
                              parseInt(data.ohqty) === 0
                                ? "#FFCCCB" // Red color when the value is zero
                                : parseInt(data.ohqty) >=
                                  parseInt(data.quantity)
                                ? "#90EE90"
                                : "#E0E0E0",
                          }}>
                          {data.ohqty}
                        </TableCell>
                        <TableCell
                          sx={styles.tableCell}
                          style={{
                            backgroundColor:
                              data.type === "VB" ? "#90EE90" : "#E0E0E0",
                          }}>
                          {data.type}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>{data.ls}</TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.reorder_pt}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.max_level}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.fix_lot_size}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>{data.uom}</TableCell>
                        <TableCell sx={styles.tableCell}>
                          <QRCodeSVG value={data.material_no} size={40} />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={11.9}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "1em",
            }}>
            <div style={{ display: "flex" }}>
              <Tooltip title="Replenishment Email">
                <IconButton
                  id="email_replenishment"
                  style={{
                    backgroundColor:
                      spDetails &&
                      spDetails.material !== null &&
                      spDetails.material !== "" &&
                      spDetails &&
                      spDetails.length === 0
                        ? "#E0E0E0"
                        : "#13A538",
                    color: "#FFFFFF",
                    borderRadius: 4,
                    height: "1.1em",
                    width: "1.4em",
                    pointerEvents:
                      spDetails &&
                      spDetails.material !== null &&
                      spDetails.material !== "" &&
                      spDetails &&
                      spDetails.length === 0
                        ? "none"
                        : "auto",
                  }}
                  onClick={handleIconButtonClick}>
                  <MailOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Replenishment;
