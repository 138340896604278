import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import NavUtils from "./NavUtils";
import Grid from "@mui/material/Grid";
import { themeMain } from "../components/wrapper/CThemeProvider";

const Drawer = ({
  appRouteMap,
  drawerList,
  pathName,
  linkComponent: Link
}) => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { getDrawerItems, isDrawerShow } = NavUtils();
  const isDrawerMethod = isDrawerShow(drawerList, pathName);
  const isDrawer = isDrawerMethod.isCurrentPathInDrawer;
  const savePath = isDrawerMethod.savePath;

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOverlayClick = () => {
    // Close the drawer when clicking outside
    handleCloseDrawer();
  };

  return (
    <Grid>
      {isDrawer && (
        <MenuIcon
          fontSize="large"
          aria-label="drawer-toggle menu"
          sx={{
            color: themeMain.palette.primary.main,
            cursor: "pointer",
          }}
          onClick={handleOpenDrawer}
        />
      )}

      {isDrawerOpen && (
        <Grid
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: themeMain.palette.blurry.main,
            zIndex: "1000",
          }}
          onClick={handleOverlayClick}
        >
          <Grid
            sx={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "18em",
              height: "100%",
              backgroundColor: themeMain.palette.mainbg.main,
              zIndex: "1001",
            }}
          >
            <IconButton
              color="primary"
              aria-label="close menu"
              sx={{
                position: "absolute",
                top: "0.4em",
                right: "0.4em",
              }}
              onClick={handleCloseDrawer}
            >
              <ClearIcon fontSize="large" />
            </IconButton>

            <List sx={{ marginTop: "3.5em" }}>
              {getDrawerItems(appRouteMap, savePath, isDrawer).map((item, index) => (
                <Accordion key={index}>
                  <AccordionDetails>
                    <ListItemButton component={Link} to={item.path}>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>

          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Drawer;
