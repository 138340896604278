import axios from "axios";
import UserUtils from "../auth/UserUtils";

const apiKey = "x-api-key";
const { handleLoginError, getTokenFromEmail } = UserUtils;

const axiosGetCall = (url, dataObj) => {
  return axios.get(url, dataObj);
};

const axiosPostCall = async (url, formData) => {
  const response = await axios.post(url, formData);
  return response;
};

const axiosFormPostCall = async (url, formData, formHeader) => {
  const response = await axios.post(url, formData, formHeader);
  return response;
};

const axiosPutCall = (url, dataObj) => {
  return axios.put(url, dataObj);
};

const axiosDeleteCall = (url, dataObj) => {
  return axios.delete(url, dataObj);
};

const axiosPostCallWithToken = async (url, formData) => {
  try {
    const token = getTokenFromEmail();
    const response = await axios.post(url, formData, {
      headers: {
        [apiKey]: token,
      },
    });

    return response;
  } catch (error) {
    handleLoginError(error);
  }
};

const axiosGetCallWithToken = async (url, formData) => {
  try {
    const token = getTokenFromEmail();
    const response = await axios.get(url, {
      params: formData,
      headers: {
        [apiKey]: token,
      },
    });
    return response;
  } catch (error) {
    handleLoginError(error);
  }
};

const axiosPutCallWithToken = async (url, formData) => {
  try {
    const token = getTokenFromEmail();
    const response = await axios.put(url, formData, {
      headers: {
        [apiKey]: token,
      },
    });

    return response;
  } catch (error) {
    handleLoginError(error);
  }
};

const axiosDeleteCallWithToken = async (url) => {
  try {
    const token = getTokenFromEmail();
    const response = await axios.delete(url, {
      headers: {
        [apiKey]: token,
      },
    });

    return response;
  } catch (error) {
    handleLoginError(error);
  }
};

export const axiosGetCallWithTokenHeader = async (url, formData) => {
  try {
    const token = getTokenFromEmail();
    const response = await fetch(url, {
      method: "GET",
      headers: {
        [apiKey]: token,
        "Content-Type": "application/pdf",
      },
    });
    return response;
  } catch (error) {
    handleLoginError(error);
  }
};

export const axiosGetCallHeaderWithToken = async (url, headerType) => {
  const token = getTokenFromEmail();
  try {
    const response = await axios.get(url, {
      headers: {
        [apiKey]: token,
        "Content-Type": headerType,
      },
    });
    if (response.status === 200) {
      response.config.responseType = 'blob';
    }
    const updatedResponse = await axios(response.config);
    return updatedResponse;
  } catch (error) {
    handleLoginError(error);
  }
};

export const axiosGetWithTokenHeader = async (url) => {
  const token = getTokenFromEmail();
  try {
    if (token === null) {
      return;
    }
    const response = await axios.get(url, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/excel",
        [apiKey]: token,
      },
    });
    return response;
  } catch (error) {
    handleLoginError(error);
  }
};

const AxiosWrapper = {
  axiosGetCall,
  axiosPostCall,
  axiosFormPostCall,
  axiosPutCall,
  axiosDeleteCall,
  axiosPostCallWithToken,
  axiosGetCallWithToken,
  axiosPutCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallWithTokenHeader,
  axiosGetCallHeaderWithToken,
  axiosGetWithTokenHeader
};

export default AxiosWrapper;
