import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";


function DCataloguePage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title" style={{ textAlign: "center" }}>
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              D-Catalogue
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl" style={{ marginBottom: "-3em" }}>
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                Digital Catalogue also known as D-Catalogue is a visual
                representation of physical manual that consist of detailed
                information about the machines, their spare parts, equipment’s,
                and other parts which that are being used in different machines
                or equipment’s at a company/Port terminal. It is a digitization
                process of physical manual into digital form to improve
                efficiency, reduce manpower and reduce manual work.{" "}
              </p>
              <br></br>
              <br></br>

              <b className="fontclass" style={{ fontSize: "1.7rem" }}>
                We provide the following D-Catalogue services:
              </b>
              <br></br>
              <ul
                className="fontclass"
                type="square"
                align="justify"
                style={{ fontSize: "1.3rem" }}
              >
                <li>
                  This system also checks if the part or the required equipment
                  is available in the warehouse or not.
                </li>

                <li>
                  We can also use this system across different companies or
                  different types, which have different equipments or machines.
                  The best part about this system is that it is time saving as
                  well as cost effective.
                </li>
                <li>
                  The cost to buy hard copies of the manual as well as maintain
                  it physically is very challenging and there is no guarantee
                  that the manual won't be damaged by accident.
                </li>

                <li>
                  Surprisingly there is no such system specifically which has
                  digital representation of each and every part of a specific
                  machine using which we can even order it and so this is the
                  first of its kind application which enables us to see the
                  design of the part, even know if it is available and also
                  order the part at the same time
                </li>
              </ul>
              <br></br>

              <b className="fontclass" style={{ fontSize: "1.7rem" }}>
                Objectives of D-Catalogue:
              </b>
              <ul
                className="fontclass"
                type="square"
                align="justify"
                style={{ fontSize: "1.3rem" }}
              >
                <li>
                  Making manual available to all warehouse and procurement team
                  in digital format.
                </li>

                <li>
                  To show diagrams of the manual in a way with detailed
                  information of each part.
                </li>

                <li>
                  To order part as required just by clicking on the correct area
                  of the image to order the part.
                </li>
              </ul>
              <br></br>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default DCataloguePage;
