import { useEffect } from "react";

const NavUtils = () => {

    const isDrawerShow = (drawerList, pathName) => {
        const matchingPath = drawerList.find((path) => pathName.includes(path));
        return { savePath: matchingPath || "", isCurrentPathInDrawer: !!matchingPath };
    };

    const getDrawerItems = (appRouteMap, savePath, isDrawer) => {
        if (isDrawer) {
            const listItems = Object.entries(appRouteMap).map(([route, { title, isDrawer }]) => ({
                title: title,
                path: route,
                drawer: (isDrawer && route.includes(savePath)),
            }));
            const filteredItems = listItems.filter(item => item.drawer);
            return filteredItems;
        } else {
            return [];
        }
    };

    const getRouteItems = (appRouteMap) => {
        return Object.entries(appRouteMap).map(([route, { element }]) => ({
            path: route,
            element: element
        }));
    };

    const getServiceItems = (services) => {
        return services.map(
            ({ route, title, asset, isEnabled }, index) => ({
                path: route,
                title,
                asset,
                isEnabled,
                key: index
            }));
    };

    const handleLogoClick = (userName, navigate) => {
        const newPath = userName ? "/services" : "/login";
        navigate(newPath);
    };

    return { handleLogoClick, getDrawerItems, isDrawerShow, getRouteItems, getServiceItems };
};

export default NavUtils;
