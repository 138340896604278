import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";

function ERPImplementationPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title" style={{ textAlign: "center" }}>
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0"}}
            >
              ERP Implementation
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                The success of every ERP implementation is highly impacted by
                the quality of data. Customers have defined our data cleansing
                projects as a Phase 0 of their ERP implementations. We also
                helped them with other prerequisites for a successful ERP roll
                out, like supporting them with their warehouse design and
                layout, location management, cycle counting & 5S. When you spend
                so much time on site with their staff, the actual ERP
                implementation is only a small additional step. We have
                experience implementing IFS and SAP in Maintenance, Warehouse &
                Procurement. In addition to the standard ERP implementations, we
                developed easy to use add-on-software solutions to simplify
                using the ERP systems in a maintenance & warehouse environment.
                We also increased the management & control by creating easy to
                use dashboards in Power BI.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default ERPImplementationPage;
