import { useEffect, useState } from "react";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";

import {
  getJobDetail,
  getPermissionId,
  pdfDownload,
  nonEamsPdfDownload,
} from "../model/JobDetailsModel";

const JobDetailsVM = (props) => {
  const {
    spDetails,
    jobCardNumber,
    equipmentType,
    equipmentID,
    jobDirective,
    jobType,
    section,
    resources,
    workDesc,
    externalRef,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
  } = props;

  const [jobDropdown, setJobDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { handleToastOpen } = useToast();
  let type;

  //Job Details : Validations
  const isDisabled = !jobDirective || !equipmentType || !equipmentID;
  const handleIconButtonClick = () => {
    if (isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
    } else {
      jobMail();
    }
  };

  const equipmentId = equipmentID?.equipment_number;

  //Job Details: Pdf Download
  const handleButtonDownload = async () => {
    if (finishDate && startDate && finishDate <= startDate) {
      handleToastOpen("error", "Invalid date range!");
      return;
    }

    const data = localStorage.getItem("permissionData");

    // Split the data string into an array of values
    const dataArray = data.split(",");

    // Check if feature_eams is present in the dataArray
    const containsFeatureEams = dataArray.includes("feature_eams");

    if (containsFeatureEams) {
      if (isDisabled) {
        handleToastOpen(
          "error",
          "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
        );
      } else {
        type = "eams_pdf";
        const response = await pdfDownload(
          jobCardNumber,
          equipmentId,
          jobDirective,
          jobType ?? "",
          section ?? "",
          resources,
          workDesc,
          externalRef,
          startDate,
          finishDate,
          type
        );
        if (response.status === 400) {
          handleToastOpen("error", "Quantity must be greater than 0");
        } else {
          const currentDate = new Date();
          const formattedDate = currentDate
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "_");
          const currentTime = currentDate.toLocaleTimeString();
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Job${jobCardNumber} ${equipmentID?.equipment_number} WorkOrderRequest${formattedDate} ${currentTime}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      }
    } else {
      type = "non_eams_pdf";
      const response = await pdfDownload(
        jobCardNumber,
        equipmentId,
        jobDirective,
        jobType ?? "",
        section ?? "",
        resources,
        workDesc,
        externalRef,
        startDate,
        finishDate,
        type
      );
      if (response.status === 400) {
        handleToastOpen("error", "Quantity must be greater than 0");
      } else {
        const currentDate = new Date();
        const formattedDate = currentDate
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, "_");
        const currentTime = currentDate.toLocaleTimeString();
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `JobDetails_${jobCardNumber}_${equipmentID?.equipment_number}_${formattedDate}_${currentTime}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  };

  //Job Details: Eams and Non Eams user email &&  Fetch permission for eams and non eams user
  const jobMail = async () => {
    try {
      if (finishDate && startDate && finishDate <= startDate) {
        handleToastOpen("error", "Invalid date range!");
        return;
      }
      setIsLoading(true); // Start the loader
      const data = localStorage.getItem("permissionData");
      console.log("aaaaaaaa", data);

      // Split the data string into an array of values
      const dataArray = data.split(",");

      // Check if feature_eams is present in the dataArray
      const containsFeatureEams = dataArray.includes("feature_eams");

      if (containsFeatureEams) {
        console.log('At least one object contains "feature_eams".');

        if (isDisabled) {
          handleToastOpen(
            "error",
            "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
          );
        } else {
          //Job Details: Eams user email
          const type = "eams_mail";
          const response = await pdfDownload(
            jobCardNumber,
            equipmentId,
            jobDirective,
            jobType ?? "",
            section ?? "",
            resources,
            workDesc,
            externalRef,
            startDate,
            finishDate,
            type
          );

          if (response.status === 200) {
            handleToastOpen("success", "Email sent successfully");
          } else {
            handleToastOpen("error", "Quantity must be greater than 0");
            console.error("Error sending email:", response.status);
          }
        }
      } else {
        console.log('None of the objects contain "feature_eams".');
        if (isDisabled) {
          handleToastOpen(
            "error",
            "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
          );
        } else {
          //Job Details: Non- Eams user email
          const type = "non_eams_mail";
          const response = await nonEamsPdfDownload(
            jobCardNumber,
            equipmentType,
            equipmentId,
            jobDirective,
            jobType ?? "",
            section ?? "",
            resources,
            workDesc,
            externalRef,
            startDate,
            finishDate,
            type
          );
          console.log("response.status ", response.status);
          if (response.status === 200) {
            handleToastOpen("success", "Email sent successfully");
          } else {
            handleToastOpen("error", "Quantity must be greater than 0");
            console.error("Error sending email:", response.status);
          }
        }
      }
    } catch (error) {
      console.error("Error sending Email:", error);
    } finally {
      setIsLoading(false); // Stop the loader regardless of success or error
    }
  };

  //Job Details : Start Date Onchange function
  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  //Job Details : Start Date Onchange function
  const handleFinishDateChange = (value) => {
    setFinishDate(value);
  };

  //Job Details: Get distinct type and section for dropdown in job details page (Job Type)
  const fetchJobDropdown = async () => {
    try {
      const data = await getJobDetail();
      setJobDropdown(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchJobDropdown();
  }, []);

  return {
    jobDropdown,
    jobMail,
    handleStartDateChange,
    handleFinishDateChange,
    startDate,
    finishDate,
    handleIconButtonClick,
    handleButtonDownload,
    isLoading,
  };
};

export default JobDetailsVM;
