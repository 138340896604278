import React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const CButton = ({
    cId,
    cText,
    cSrc,
    cTooltipText,
    cDisabled,
    cOnClick,
    cLink,
    cFullWidth,
}) => {

    const handleClick = () => {
        if (cOnClick !== undefined) {
            cOnClick();
        }
    };

    //span is added in order to show tooltip on disabled element
    return (
        <Tooltip title={cTooltipText} arrow>
            <span>
                {(cText !== undefined && (
                    <Button
                        id={cId}
                        aria-label={cTooltipText}
                        disabled={cDisabled}
                        onClick={handleClick}
                        color="primary"
                        variant="contained"
                        fullWidth={cFullWidth}
                    >
                        {cText}
                    </Button>
                )) ||
                    (cSrc !== undefined && (
                        <IconButton
                            id={cId}
                            aria-label={cTooltipText}
                            disabled={cDisabled}
                            onClick={handleClick}
                            color="primary"
                            variant="contained"
                        >
                            {cSrc}
                        </IconButton>
                    ))}
            </span>
        </Tooltip>
    );
};

export default CButton;
