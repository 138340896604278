import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

const CLabelDropdownSelect = ({ value, options, onChange }) => {
  const filteredOptions = options.filter((data) => data !== null);

  return (
    <FormControl style={{ width: '30%' }}>
      <Select
        value={value}
        onChange={(e) => onChange(e)}
        style={{ height: "2.5em"}}
      >
        {filteredOptions.map((data) => (
          <MenuItem key={data} value={data}>
            {data}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CLabelDropdownSelect;
