import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import CButton from "./CButton";
import CText from "./CText";
import CInput from "./CInput";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import { PdfPageStyle } from "../../label_printing/assets/Constant";
import { Box } from "@mui/system";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const StyledTableCell = styled(TableCell)(({ theme, cColor }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: theme.palette.common.black,
    border: "1px solid black",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const DEFAULT_PAGE_SIZE = 5;
const CTable = ({
  cHeaderData,
  cRowData,
  cActualData,
  cTooltipText,
  cIsPagination,
  cSmallSize,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGE_SIZE);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", margin: "1em 0" }}>
      <TableContainer>
        {/* sx={{ maxHeight: 440 }} */}
        <Table
          aria-label={cTooltipText}
          size={cSmallSize === true ? "small" : "inherit"}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {cHeaderData.map((headData, headIndex) => (
                <StyledTableCell key={headIndex}>{headData}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {cActualData.length > 0 ? (
              cActualData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((actualRow, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {cRowData.map((rowData, cellIndex) => (
                      <TableCell
                        component="th"
                        scope="row"
                        key={cellIndex}
                        sx={{
                          color:
                            rowData.status === true
                              ? actualRow.status === 0
                                ? "red"
                                : "green"
                              : "inherit",
                        }}
                      >
                        {rowData.type === "T" && actualRow[rowData.value]}
                        {rowData.type === "I" && (
                          <CButton
                            cSrc={rowData.value}
                            cTooltipText={
                              //rowData.tooltipText + " " + (rowIndex + 1)
                              rowData.tooltipText
                            }
                            cOnClick={() => rowData.actionV(actualRow)}
                          />
                        )}
                        {/* ------Adding this types for Value checking -------*/}
                        {rowData.type === "TE" &&
                          (actualRow[rowData.value] !== null ? (
                            <CText cText={actualRow[rowData.value]} />
                          ) : (
                            <Box
                              container
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "80%",
                              }}
                            >
                              <CInput
                                error
                                cOnChange={(event) => {
                                  setInputValue(event.target.value);
                                }}
                              />
                              <CButton
                                cSrc={<SaveIcon />}
                                cTooltipText="Save"
                                cOnClick={() => {
                                  const updatedRow = {
                                    ...actualRow,
                                    [rowData.value]: inputValue,
                                  };
                                  rowData.actionV(updatedRow);
                                }}
                              />
                            </Box>
                          ))}

                        {rowData.type === "E" && <CInput />}

                        {rowData.type === "BL" && (
                          <Box
                            container
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                            }}
                          >
                            <CInput
                              error
                              cOnChange={(event) => {
                                setInputValue(event.target.value);
                              }}
                            />
                            <CButton
                              cSrc={<SaveIcon />}
                              cTooltipText="Save"
                              cOnClick={() => {
                                const updatedRow = {
                                  ...actualRow,
                                  [rowData.value]: inputValue,
                                };
                                rowData.actionV(updatedRow);
                              }}
                            />
                          </Box>
                        )}
                        {rowData.type === "R" && (
                          <CText
                            cText={
                              actualRow[rowData.value] === 0 ? "Yes" : "No"
                            }
                          />
                        )}
                        {rowData.type === "IL" && (
                          <CText
                            cText={
                              actualRow[rowData.value] === 0 ? (
                                <CameraAltIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => rowData.actionV(actualRow)}
                                />
                              ) : (
                                <RemoveRedEyeIcon
                                  onMouseOver={() => rowData.actionV(actualRow)}
                                />
                              )
                            }
                          />
                        )}
                        {rowData.type === "PR" && (
                          <CText
                            cText={
                              actualRow[rowData.value] === 0 ? null : (
                                <LocalPrintshopIcon
                                  onClick={() => rowData.actionV(actualRow)}
                                  style={{ cursor: "pointer" }}
                                />
                              )
                            }
                          />
                        )}
                        {rowData.type === "VR" && (
                          <CText
                            cText={
                              actualRow[rowData.value] === 0 ? null : (
                                <PictureAsPdfIcon
                                  onClick={() => rowData.actionV(actualRow)}
                                  style={{ cursor: "pointer" }}
                                />
                              )
                            }
                          />
                        )}

                        {/* ------Adding this types for Value checking -------*/}
                        {rowData.type === "B" && (
                          <CButton
                            cText={rowData.value}
                            cTooltipText={
                              rowData.tooltipText + " " + (rowIndex + 1)
                            }
                            cOnClick={() => rowData.actionV(actualRow)}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={cHeaderData.length}
                  sx={{ textAlign: "center" }}
                >
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {cIsPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={cActualData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default CTable;
