const PO_KEY = "po_no";
const MAT_NO_KEY = "material_no";
const QUANTITY_KEY = "print_qty";
const SIZE_KEY = "measurement_id";
const BIN_LOC_KEY = "bin_location";
const BULK_LOC_KEY = "bulk_location";
const STORAGE_BIN_LOC_KEY = "storage_bin_loc";
const STORAGE_LOC_KEY = "storage_loc";

const PRINT_VIEW_ALL = "print_view_all";
const DELETE_ALL = "delete_all";
const PRINT_VIEW_ID = "print_view_id";
const PRINT_VIEW_DATA = "print_view_data";

export const AddPrintKeys = {
    PO_KEY, MAT_NO_KEY, QUANTITY_KEY, SIZE_KEY, BIN_LOC_KEY, BULK_LOC_KEY, STORAGE_BIN_LOC_KEY, STORAGE_LOC_KEY
}
export const WareHouseLocationKeys = {
    STORAGE_BIN_LOC_KEY, STORAGE_LOC_KEY
}
export const PrintViewTypes = {
    PRINT_VIEW_ALL, PRINT_VIEW_ID, PRINT_VIEW_DATA,
    DELETE_ALL
}

