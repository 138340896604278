import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  addBulkLocation,
  addBulkLabelData,
  addLabelData,
  deleteAllLabels,
  deleteLabelData,
  fetchDefaultPresentedLabels,
  fetchMaterialsByPo,
  fetchPoByMaterials,
  fetchSizeOptions,
  updateBinLocation,
  updateLabel,
  handlePdfClick,
  fetchViewPdfDataByMatNum,
  fetchPrintPdfData,
  fetchPrintAllPdfData,
} from "../model/Sticker";
import excelFile from "../../assets/present_label.xlsx";
import {
  binLocationLength,
  binLocationUpdated,
  bulkLocationAdded,
  dataAlreadyDeleted,
  fileUploaded,
  invalidFileType,
  invalidQuantity,
  materialAdded,
  materialAlreadyDeleted,
  materialAlreadyPresented,
  materialDeleted,
  materialNotPresent,
  materialUpdated,
  noSizeAvailable,
  notSelectSize,
  requiredFields,
  selectFileUpload,
  somethingWrong,
  zeroQuantity,
} from "../model/Strings";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
import { PdfPageStyle } from "../../assets/Constant";
import Logger from "../../../basecomp/base/Logger";
import { currentTime, formattedDate } from "../model/PdfUtils";

const AddPrintingVM = () => {
  const navigate = useNavigate();
  const { handleToastOpen } = useToast();

  const [sizeOptions, setSizeOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState("1");

  const [updatedLabelId, setUpdatedLabelId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [renderComp, setRenderComp] = useState(false);
  const [renderEditComp, setRenderEditComp] = useState(false);

  const [updatedMaterialNumber, setUpdatedMaterialNumber] = useState("");
  const [updatedQuantity, setUpdatedQuantity] = useState("");

  const [labelData, setLabelData] = useState([]);
  const [updatedMatNo, setUpdatedMatNo] = useState("");
  const [updatedQuantityNo, setUpdatedQuantityNo] = useState("");
  const [matStatus, setMatStatus] = useState("");
  const [refreshSize, setRefreshSize] = useState("");

  const [notExistIds, setNotExistIds] = useState([]);
  const [existIds, setExistIds] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [materialImages, setMaterialImages] = useState([]);

  //-------------------LP V2 new states------------------------------
  //po mat options
  const [poOptions, setPoOptions] = useState([]);
  const [matOptions, setMatOptions] = useState([]);

  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [materialNumber, setMaterialNumber] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [openCarousel, setOpenCarousel] = useState(false);

  //rendering size
  useEffect(() => {
    fetchSizeOptions()
      .then((response) => {
        setSizeOptions(response.data);
      })
      .catch((error) => {
        handleToastOpen("error", noSizeAvailable);
      });
  }, []);

  //checking user have previous data or not
  useEffect(() => {
    const fetchPresentedLabels = () => {
      fetchDefaultPresentedLabels()
        .then((response) => {
          const newLabels = response.data;

          // Check if the labels are already in the state to avoid duplicates
          const existingLabelIds = labelData.map((label) => label.id);
          const filteredNewLabels = newLabels.filter(
            (label) => !existingLabelIds.includes(label.id)
          );

          // Append the new data to the existing labelData array
          setLabelData([...filteredNewLabels]);
          setRefreshSize(response.data[0].measurement_id);

          // Extract the IDs with status code 1
          const statusOneIds = filteredNewLabels
            .filter((label) => label.status === 1)
            .map((label) => label.id);

          // Extract the IDs with status code 0
          const zeroStatusIds = filteredNewLabels
            .filter((label) => label.status === 0)
            .map((label) => label.id);

          // Update the states directly
          setExistIds([...statusOneIds]);
          setNotExistIds([...zeroStatusIds]);
          setRenderComp(true);
        })
        .catch((error) => {
          Logger.printStackTrace(error);
        });
    };
    fetchPresentedLabels();
  }, []);

  // Fetching material numbers by purchase order
  const handleUpdatedPoOptions = async (e) => {
    const newValue = e.target.value;
    setPurchaseOrder(newValue);
    if (newValue.length >= 3) {
      try {
        const response = await fetchMaterialsByPo(newValue);
        const materialData = response.data;

        if (materialData.length > 0) {
          const matNum = materialData[0].material_no;
          setMaterialNumber(matNum);
        } else {
          setMaterialNumber(null);
        }
        setMatOptions(materialData);
      } catch (error) {
        Logger.printStackTrace(error);
      }
    }
  };

  // Fetching Purchase order by material number
  const handleUpdatedMaterialOptions = async (e) => {
    const newValue = e.target.value;
    setMaterialNumber(newValue);
    if (newValue.length >= 3) {
      try {
        const response = await fetchPoByMaterials(newValue);
        const purchaseData = response.data;
        if (purchaseData.length > 0) {
          const purOrder = purchaseData[0].purchase_order;
          setPurchaseOrder(purOrder);
        } else {
          setPurchaseOrder(null);
        }
        setPoOptions(purchaseData);
      } catch (error) {
        Logger.printStackTrace(error);
      }
    }
  };

  // Handle Purchase Order input change
  const handlePurchaseOrderChange = async (e) => {
    const newValue = e.target.value;
    setPurchaseOrder(newValue);
    if (newValue.length >= 3) {
      try {
        const MaterialResponse = await fetchMaterialsByPo(newValue);
        const materialData = MaterialResponse.data;
        if (materialData.length > 0) {
          const purchaseResponse = await fetchPoByMaterials(
            materialData[0].material_no
          );
          const purchaseData = purchaseResponse.data;
          setPoOptions(purchaseData);
        } else {
          setMaterialNumber(null);
        }
        setMatOptions(materialData);
      } catch (error) {
        Logger.printStackTrace(error);
      }
    }
  };

  // Handle Material Number input change
  const handleMaterialNumberChange = async (e) => {
    const newValue = e.target.value;
    setMaterialNumber(newValue);
    if (newValue.length >= 3) {
      try {
        const response = await fetchPoByMaterials(newValue);
        const purchaseData = response.data;
        if (purchaseData.length > 0) {
          const response = await fetchMaterialsByPo(
            purchaseData[0].purchase_order
          );
          const materialData = response.data;
          setMatOptions(materialData);
        } else {
          setPurchaseOrder(null);
        }
        setPoOptions(purchaseData);
      } catch (error) {
        Logger.printStackTrace(error);
      }
    }
  };

  //Image upload navigation handle
  const handleImageUpload = () => {
    navigate(PdfPageStyle.imageUploadNav);
  };

  //------------------Label Printing V2 PO and Mat No ends---------------------------

  //selecting size
  const handleSizeChange = (event, value) => {
    const selectedValue = event;
    const selectedSizeObject = sizeOptions.find(
      (size) => size.name === selectedValue
    );

    if (selectedSizeObject) {
      setSelectedSize(selectedSizeObject.id);
    }
  };

  //adding label sticker data
  const handleSubmit = () => {
    //handling errors
    if (!materialNumber || selectedSize === null || !quantity) {
      handleToastOpen("error", requiredFields);
      return;
    }

    const quantityAsNumber =Number(quantity) ;
    if (parseFloat(quantity) === 0) {
      handleToastOpen("error", zeroQuantity);
      return;
    }

    // Validate quantity input
    if (isNaN(quantityAsNumber) || !Number.isInteger(quantityAsNumber) || quantityAsNumber < 0) {
      handleToastOpen("error", invalidQuantity);
      return;
    }

    const requestData = {
      po_no: purchaseOrder,
      material_no: materialNumber,
      measurement_id: selectedSize,
      print_qty: quantityAsNumber,
    };
    setIsLoading(true);

    addLabelData(requestData,handleToastOpen)
      .then((response) => {
        if (response.data.status === 0) {
          handleToastOpen("error", materialNotPresent);
          setNotExistIds((prevNotExistIds) => [
            ...prevNotExistIds,
            response.data.id,
          ]);
        } else {
          handleToastOpen("success", materialAdded);
          setExistIds((prevIds) => [...prevIds, response.data.id]);
        }
        if (response.data.image_link !== 0) {
          setMaterialImages(response.data.image_link);
        }
        setUpdatedLabelId(response.data.id); // Set the newly created label id
        setUpdatedMaterialNumber(response.data.material_no);
        setUpdatedQuantity(response.data.print_qty);
        setIsLoading(false);
        setRenderComp(true);
        setLabelData((prevLabelData) => [...prevLabelData, response.data]);
      })
      .catch((error) => {
        Logger.printStackTrace(error)
        handleToastOpen("error", materialAlreadyPresented);
        setIsLoading(false);
      });
  };

  // adding bulk location
  const handleBulkLocationSave = async (materialNumber, bulkLocation) => {
    //handling errors
    if (
      !materialNumber ||
      bulkLocation === null ||
      bulkLocation.length === null
    ) {
      handleToastOpen("error", requiredFields);
      return;
    }

    const requestData = {
      material_no: materialNumber,
      bulk_location: bulkLocation,
    };

    addBulkLocation(requestData)
      .then((response) => {
        if (response.data.bulk_location !== null) {
          handleToastOpen("success", bulkLocationAdded);
          window.location.reload();
        }
      })
      .catch(() => {
        handleToastOpen("error", somethingWrong);
      });
  };

  //after the successful label creation, handle the preview section
  const handlePreview = (label_id, size_id) => {
    if (refreshSize !== null && refreshSize > 0) {
      navigate(
        `${PdfPageStyle.pdfPreviewRoute}?id=${[label_id]}&size=${size_id}`
      );
    } else if (label_id) {
      navigate(
        `${PdfPageStyle.pdfPreviewRoute}?id=${[label_id]}&size=${size_id}`
      );
    } else {
      handleToastOpen("error", somethingWrong);
    }
  };

  //-------------------new download handle request---------------------------

  const handleDownloadWithoutPreview = (label_id, size_id) => {
    if (refreshSize !== null && refreshSize > 0) {
      navigate(
        `${PdfPageStyle.pdfPreviewRoute}?id=${[label_id]}&size=${size_id}`
      );
    } else if (label_id) {
      navigate(
        `${PdfPageStyle.pdfPreviewRoute}?id=${[label_id]}&size=${size_id}`
      );
    } else {
      handleToastOpen("error", somethingWrong);
    }
  };

  // -----------------------------------------------------------------------------

  //handling the edit component section
  const editHandle = (id, matNo, quantity, status) => {
    setRenderEditComp(true);
    setRenderComp(false);
    setUpdatedLabelId(id);
    setUpdatedMatNo(matNo);
    setUpdatedQuantityNo(quantity);
    setMatStatus(status);
  };

  //excel file upload function
  const handleFileUpload = () => {
    //handling the size error
    if (!selectedSize) {
      handleToastOpen("error", notSelectSize);
      return;
    }

    if (selectedFiles) {
      const validExtensions = [".xlsx", ".csv"];
      const invalidFiles = Array.from(selectedFiles).filter((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        return !validExtensions.includes("." + fileExtension);
      });

      if (invalidFiles.length > 0) {
        handleToastOpen("error", invalidFileType);
        return;
      }

      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("file", selectedFiles[i]);
      }
      formData.append("size", selectedSize);

      addBulkLabelData(formData)
        .then((response) => {
          handleToastOpen("success", fileUploaded);

          const newLabels = response.data.insertedLabelNames;

          // Extract the IDs with status code 1
          const statusOneIds = newLabels
            .filter((label) => label.status === 1)
            .map((label) => label.id);

          // Extract the IDs with status code 0
          const zeroStatusIds = newLabels
            .filter((label) => label.status === 0)
            .map((label) => label.id);

          // Append the new data to the existing labelData array
          setLabelData((prevLabelData) => [...prevLabelData, ...newLabels]);

          // Update the states directly
          setExistIds((prevIds) => [...prevIds, ...statusOneIds]);
          setNotExistIds((prevIds) => [...prevIds, ...zeroStatusIds]);
          setRenderComp(true);
        })
        .catch((error) => {
           Logger.printStackTrace(error);
        });
    } else {
      handleToastOpen("error", selectFileUpload);
    }
  };

  //delete data based on user
  const handleDelete = () => {
    deleteAllLabels()
      .then((response) => {
        handleToastOpen("success", materialDeleted);

        setLabelData([]);
        setExistIds([]);
        setNotExistIds([]);
        setRenderEditComp(false);
        setRenderComp(false);
      })
      .catch((error) => {
        handleToastOpen("error", dataAlreadyDeleted);
      });
  };

  //deleting selected labels
  const handleDeleteParticular = (id) => {
    if (id) {
      deleteLabelData(id)
        .then((response) => {
          handleToastOpen("success", materialDeleted);

          setRenderEditComp(false);
          setLabelData((prevLabelData) =>
            prevLabelData.filter((item) => item.id !== id)
          );
          setExistIds((prevUploadedLabelIds) =>
            prevUploadedLabelIds.filter((labelId) => labelId !== id)
          );
          setNotExistIds((prevUploadedNonExistLabelIds) =>
            prevUploadedNonExistLabelIds.filter((labelId) => labelId !== id)
          );
        })
        .catch((error) => {
          handleToastOpen("error", materialAlreadyDeleted);
        });
    } else {
      handleToastOpen("error", somethingWrong);
    }
  };

  //calling delete particular function in edit component
  const EditCompDeleteApi = function () {
    handleDeleteParticular(updatedLabelId);
  };

  //Update label
  const handleUpdateSave = () => {
    setIsLoading(true);
    const quantityAsNumber = parseInt(updatedQuantityNo, 10);

    if (quantityAsNumber === 0) {
      handleToastOpen("error", zeroQuantity);
      return;
    }

    // Validate quantity input
    if (isNaN(quantityAsNumber) || !Number.isInteger(quantityAsNumber) || Number(updatedQuantityNo)< 0) {
      handleToastOpen("error", invalidQuantity);
      return;
    }

    updateLabel(updatedLabelId, updatedMatNo, updatedQuantityNo)
      .then((response) => {
        setRenderEditComp(false);
        setLabelData((prevLabelData) => {
          // Remove the old object with the same ID
          const updatedLabelData = prevLabelData.filter(
            (item) => item.id !== updatedLabelId
          );
          // Push the new value to the array
          updatedLabelData.push(response.data);
          return updatedLabelData;
        });
        if (response.data.status === 0) {
          // Material is not present, add the updatedLabelId to notExistIds
          setNotExistIds((prevNotExistIds) => {
            if (!prevNotExistIds.includes(updatedLabelId)) {
              return [...prevNotExistIds, updatedLabelId];
            }
            return prevNotExistIds;
          });
          handleToastOpen("error", materialNotPresent);
        } else {
          // Material is present, remove the updatedLabelId from notExistIds
          setNotExistIds((prevNotExistIds) => {
            return prevNotExistIds.filter((item) => item !== updatedLabelId);
          });
          //add the updatedLabelId to ExistIds
          setExistIds((prevExistIds) => {
            if (!prevExistIds.includes(updatedLabelId)) {
              return [...prevExistIds, updatedLabelId];
            }
            return prevExistIds;
          });

          handleToastOpen("success", materialUpdated);
        }
        if (response.data.image_link !== 0) {
          setMaterialImages(response.data.image_link);
        }
      })
      .catch((error) => {
        handleToastOpen("error", somethingWrong);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Update label
  const handleUpdateBinLocationSave = (
    updatedLabelId,
    material_no,
    bin_location
  ) => {
    if (bin_location.length > 10 || bin_location.length <= 0) {
      return handleToastOpen("error", binLocationLength);
    }
    setIsLoading(true);
    updateBinLocation(updatedLabelId, material_no, bin_location)
      .then((response) => {
        setRenderEditComp(false);
        setLabelData((prevLabelData) => {
          // Remove the old object with the same ID
          const updatedLabelData = prevLabelData.filter(
            (item) => item.id !== updatedLabelId
          );
          // Push the new value to the array
          updatedLabelData.push(response.data);
          return updatedLabelData;
        });
        if (response.data.status === 0) {
          // Material is not present, add the updatedLabelId to notExistIds
          setNotExistIds((prevNotExistIds) => {
            if (!prevNotExistIds.includes(updatedLabelId)) {
              return [...prevNotExistIds, updatedLabelId];
            }
            return prevNotExistIds;
          });
        } else {
          // Material is present, remove the updatedLabelId from notExistIds
          setNotExistIds((prevNotExistIds) => {
            return prevNotExistIds.filter((item) => item !== updatedLabelId);
          });
          //add the updatedLabelId to ExistIds
          setExistIds((prevExistIds) => {
            if (!prevExistIds.includes(updatedLabelId)) {
              return [...prevExistIds, updatedLabelId];
            }
            return prevExistIds;
          });

          handleToastOpen("success", binLocationUpdated);
        }
        if (response.data.image_link !== 0) {
          setMaterialImages(response.data.image_link);
        }
      })
      .catch((error) => {
        handleToastOpen("error", `${error.response.data.error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //sample file download
  const handleSampleFileDownload = () => {
    const link = document.createElement("a");
    link.href = excelFile;
    link.download = "label.xlsx"; // Update the file name
    link.click();
  };

  const handlePreviewBePdf = async () => {
    try {
      //handling errors
      if (!materialNumber || selectedSize === null || !quantity) {
        handleToastOpen("error", requiredFields);
        return;
      }

      const quantityAsNumber = parseInt(quantity, 10);

      if (quantityAsNumber === 0) {
        handleToastOpen("error", zeroQuantity);
        return;
      }
      const response = await fetchPrintPdfData(
        purchaseOrder,
        materialNumber,
        selectedSize,
        quantity,
        "sq01"
      );

      if (!response.ok) {
        handleToastOpen("error", materialNotPresent);
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `label_list_${formattedDate} ${currentTime}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleToastOpen("error", somethingWrong);
      Logger.printStackTrace(error);
    }
  };

  const handlePreviewInTablePdf = async (
    purchaseOrder,
    materialNumber,
    selectedSize,
    quantity
  ) => {
    try {
      //handling errors
      if (!materialNumber || selectedSize === null || !quantity) {
        handleToastOpen("error", requiredFields);
        return;
      }

      const quantityAsNumber = parseInt(quantity, 10);

      if (quantityAsNumber === 0) {
        handleToastOpen("error", zeroQuantity);
        return;
      }
      const response = await fetchPrintPdfData(
        purchaseOrder,
        materialNumber,
        selectedSize,
        quantity,
        "sticker"
      );

      if (!response.ok) {
        handleToastOpen("error", materialNotPresent);
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `label_list_${formattedDate} ${currentTime}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleToastOpen("error", somethingWrong);
      Logger.printStackTrace(error);
    }
  };

  const handlePreviewClick = async () => {
    try {
      //handling errors
      if (!materialNumber || selectedSize === null || !quantity) {
        handleToastOpen("error", requiredFields);
        return;
      }

      const quantityAsNumber = parseInt(quantity, 10);

      if (quantityAsNumber === 0) {
        handleToastOpen("error", zeroQuantity);
        return;
      }
      const response = await fetchViewPdfDataByMatNum(
        purchaseOrder,
        materialNumber,
        selectedSize,
        quantity,
        "sq01"
      );

      if (!response.ok) {
        handleToastOpen("error", materialNotPresent);
        return;
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      handleToastOpen("error", somethingWrong);
      Logger.printStackTrace(error);
    }
  };

  const handlePreviewClickByMatNum = async (
    material_no,
    po_no,
    measurement_id,
    print_qty
  ) => {
    try {
      const response = await fetchViewPdfDataByMatNum(
        po_no,
        material_no,
        measurement_id,
        print_qty,
        "sticker"
      );

      if (!response.ok) {
        handleToastOpen("error", materialNotPresent);
        return;
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      handleToastOpen("error", somethingWrong);
      Logger.printStackTrace(error);
    }
  };

  //material images preview function
  const viewImagePreview = (id) => {
    if (id) {
      fetchDefaultPresentedLabels()
        .then((response) => {
          const newDta = response.data;
          const matchedItem = newDta.find((item) => item.id === id);
          if (matchedItem && matchedItem.image_link !== 0) {
            setMaterialImages(matchedItem.image_link);
            setOpenCarousel(true);
          }
        })
        .catch((error) => {
          Logger.printStackTrace(error);
        });
    }
  };

  const handleDownloadAllLabels = async () => {
    const response = await fetchPrintAllPdfData(existIds);
    const blob = await response.blob();
    console.log(blob, "blob");
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `label_list_${formattedDate} ${currentTime}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return {
    isLoading,
    matStatus,
    updatedQuantity,
    updatedMaterialNumber,
    renderComp,
    handleSampleFileDownload,
    handleUpdateSave,
    EditCompDeleteApi,
    handleDeleteParticular,
    handleDelete,
    handleFileUpload,
    editHandle,
    handlePreview,
    handleSubmit,
    handleSizeChange,
    sizeOptions,
    selectedSize,
    quantity,
    setQuantity,
    purchaseOrder,
    setPurchaseOrder,
    materialNumber,
    setMaterialNumber,
    renderEditComp,
    labelData,
    updatedMatNo,
    setUpdatedMatNo,
    notExistIds,
    existIds,
    updatedQuantityNo,
    setUpdatedQuantityNo,
    setSelectedSize,
    setSelectedFiles,
    handlePurchaseOrderChange,
    handleMaterialNumberChange,
    poOptions,
    matOptions,
    handleUpdatedPoOptions,
    handleUpdatedMaterialOptions,
    handleImageUpload,
    openCarousel,
    setOpenCarousel,
    viewImagePreview,
    materialImages,
    inputValue,
    setInputValue,
    handleUpdateBinLocationSave,
    handleBulkLocationSave,
    handleDownloadWithoutPreview,
    handlePreviewBePdf,
    handlePreviewClick,
    handlePreviewClickByMatNum,
    handlePreviewInTablePdf,
    handleDownloadAllLabels
  };
};

export default AddPrintingVM;
