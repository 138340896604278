import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  truncates,
  updateQuantity,
  deleteData,
  getJobCardNo,
  postDCatBasketEmail,
  postGreyRecordBasketEmail,
} from "../model/ShoppingBasketModel";

import {
  getSQ01AndSpBasket,
  getGlobalAndSpBasket,
} from "../model/MaterialSearchModel";

const ShoppingBasketVM = () => {
  const [lclMaterialNo, setlclMaterialNo] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lcl_material_no =
    decodeURIComponent(searchParams.get("lcl_mat_no")) !== "null"
      ? decodeURIComponent(searchParams.get("lcl_mat_no"))
      : "";

  const oem_no =
    decodeURIComponent(searchParams.get("oem")) !== "null"
      ? decodeURIComponent(searchParams.get("oem"))
      : "";

  const oem_desc =
    decodeURIComponent(searchParams.get("desc")) !== "null"
      ? decodeURIComponent(searchParams.get("desc"))
      : "";

  const gblno =
    decodeURIComponent(searchParams.get("gblno")) !== "null"
      ? decodeURIComponent(searchParams.get("gblno"))
      : "";

  const user_quantity =
    decodeURIComponent(searchParams.get("quantity")) !== "null"
      ? decodeURIComponent(searchParams.get("quantity"))
      : "";

  const userd =
    decodeURIComponent(searchParams.get("userd")) !== "null"
      ? decodeURIComponent(searchParams.get("userd"))
      : "";

  const notes =
    decodeURIComponent(searchParams.get("notes")) !== "null"
      ? decodeURIComponent(searchParams.get("notes"))
      : "";

  // Use useEffect to set lclMaterialNo based on the search parameter
  useEffect(() => {
    if (lcl_material_no !== "null") {
      setlclMaterialNo(lcl_material_no);
    }
  }, [lcl_material_no]); // Only update when lcl_material_no changes

  const [isMaterialSearchUI, setIsMaterialSearchUI] = useState(
    !lcl_material_no
  );
  const [isShoppingBasketUI, setIsShoppingBasketUI] = useState(
    !!lcl_material_no
  );

  const [isJobDetailsUI, setIsobDetailsUI] = useState(false);
  const [isReplenishmentUI, setIsReplenishmentUI] = useState(false);
  const [jobCardNumber, setJobCardNumber] = useState("");
  const [externalRef, setExternalRef] = useState("");
  const [jobDirective, setJobDirective] = useState("");
  const [equipmentType, setEquipmentType] = useState(null);
  const [equipmentID, setEquipmentID] = useState(null);
  const [spDetails, setSpDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [section, setSection] = useState(null);
  const [jobType, setJobType] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState("");
  const [resources, setResources] = useState(1);
  const [workDesc, setWorkDesc] = useState("");
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [sliderImages, setSliderImages] = useState([]);
  const [spData, setSpData] = useState([]);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { handleToastOpen } = useToast();

  console.log("sb basket", equipmentID);

  // Retrieve values from session storage when the component mounts
  useEffect(() => {
    const storedJobDirective = sessionStorage.getItem("sb_job_directive");
    const storedExternalRef = sessionStorage.getItem("sb_external_ref");
    const storedEquipmentType = sessionStorage.getItem("sb_equipment_type");
    const storedJobType = sessionStorage.getItem("sb_job_type");
    const storedSection = sessionStorage.getItem("sb_section");
    const storedWorkDesc = sessionStorage.getItem("sb_work_desc");
    const storedResources = sessionStorage.getItem("sb_resources");
    const storedstartDate = sessionStorage.getItem("sb_start_date_time");
    const storedfinishDate = sessionStorage.getItem("sb_finish_date_time");

    if (storedJobDirective !== "") {
      setJobDirective(storedJobDirective);
    }
    if (storedExternalRef !== "") {
      setExternalRef(storedExternalRef);
    }
    if (storedWorkDesc !== "") {
      setWorkDesc(storedWorkDesc);
    }
    if (!storedResources) {
      setResources(1); // If empty, set it to 1
    } else {
      setResources(storedResources); // If not empty, set it to the value of storedResources
    }
    if (storedEquipmentType !== "null") {
      setEquipmentType(storedEquipmentType);
    }
    if (storedJobType !== "null") {
      setJobType(storedJobType);
    }
    if (storedSection !== "null") {
      setSection(storedSection);
    }
    if (storedstartDate !== "null") {
      setStartDate(storedstartDate);
    }
    if (storedfinishDate !== "null") {
      setFinishDate(storedfinishDate);
    }
  }, []);

  //Shopping basket : Validations
  const isDisabled = !jobDirective || !equipmentType || !equipmentID;
  const handleIconButtonClick = () => {
    if (spDetails.length === 0 || isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
    } else {
      sendEmail();
    }
  };

  //Shopping basket : To view image in carousel
  const handleImageClick = async (imageLink, index) => {
    setClickedImageIndex(index);
    setSliderImages(imageLink);
    setOpenCarousel(true);
  };

  //Shopping Basket : Truncate sp_basket_table all of latest user and terminal id (Sp Basket)
  const DeleteAll = async () => {
    try {
      const response = await truncates();
      if (response.data.error) {
        console.error(
          "Error occurred while truncating table:",
          response.data.error
        );
      } else {
        setSpDetails([]);
        setSpData([]);
        handleToastOpen("success", "Table truncated successfully");
      }
    } catch (error) {
      console.error("Error occurred while truncating table:", error);
    }
  };

  //Shopping Basket : Edit quantity from spbasket
  const handleEdit = (id, quantity) => {
    setEditingId(id);
    setEditedQuantity(quantity);
  };

  //Shopping Basket : Update quantity in spbasket (Sp Basket)
  const handleSave = async (id, editedQuantity) => {
    try {
      console.log("iddddddd", id);
      if (Number(editedQuantity) === 0) {
        // Convert to Number and check if it's 0
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Exit the function early if quantity is 0
      }
      await updateQuantity(id, editedQuantity);
      setEditingId(null);
      setEditedQuantity("");
      fetchSpDataAll();
      fetchSpBasketAll();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  //Shopping Basket : Delete row data by its ID (Sp Basket)
  const handleDelete = async (id) => {
    try {
      await deleteData(id);
      fetchSpDataAll();
      fetchSpBasketAll();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  //Shopping Basket : Pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //Shopping Basket :Pagination change rows
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Shopping Basket : Get all data from sp_basket_table by terminal_id  and type="SB" (Sp Basket)
  const fetchSpBasketAll = async () => {
    try {
      const data = await getSQ01AndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpDetails([]);
      } else {
        setSpDetails(data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketAll();
  }, []);

  //D-Catalogue (most used): Fetch merge result of spbasket and ictsi data (Sp Basket, Global Data, Material Images)
  const fetchSpDataAll = async () => {
    try {
      const data = await getGlobalAndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpData([]);
      } else {
        setSpData(data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpDataAll();
  }, []);

  //Shopping Basket : Generate new Job card number logic (Job Id Catalogue)
  useEffect(() => {
    const fetchJobCardNumber = async () => {
      try {
        const response = await getJobCardNo();
        const data = await response.data;
        if (data) {
          setJobCardNumber((prevJobCardNumber) => {
            return data.job_card_no;
          });
        } else {
          console.error(data.error);
        }
      } catch (error) {
        console.error("Error occurred during API request:", error);
      }
    };

    fetchJobCardNumber();
  }, []);

  //Shopping Basket: Email all records in sp basket based on latest user and terminal
  const sendEmail = async () => {
    try {
      setIsLoading(true); // Start the loader
      const response = await postDCatBasketEmail();

      if (response.status === 200) {
        handleToastOpen("success", "Email sent successfully");
      } else {
        console.error("Error sending email:", response.status);
      }
    } catch (error) {
      // handleToastOpen("error", "Quantity must be greater than 0");
      let errorMessage = "An error occurred";
      if (error.response && error.response.data) {
        // If there's a response from the server and it contains error data
        errorMessage = error.response.data;
      } else if (error.message) {
        // If there's an error message in the error object
        errorMessage = error.message;
      }

      handleToastOpen("error", errorMessage); // Display the error message in handleToastOpen
      console.error("Error sending email:", error);
    } finally {
      setIsLoading(false); // Stop the loader regardless of success or error
    }
  };

  //Shopping Basket: Email only grey records in sp basket based on latest user and terminal
  const handleGreyRecords = async () => {
    if (spDetails.length === 0 || isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
    } else {
      const data = {
        job_card_no: jobCardNumber,
        job_desc: jobDirective,
        equipment_id: equipmentID,
      };

      try {
        setIsLoading(true); // Start the loader
        const response = await postGreyRecordBasketEmail(data);

        if (response.status === 200) {
          handleToastOpen("success", "Email sent successfully");
        } else {
          console.error("Error sending email:", response.status);
        }
      } catch (error) {
        handleToastOpen("error", "Quantity must be greater than 0");
        console.error("An error occurred:", error);
      } finally {
        setIsLoading(false); // Stop the loader regardless of success or error
      }
    }
  };

  //Job Details : Start Date Onchange function
  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  //Job Details : Finish Date Onchange function
  const handleFinishDateChange = (value) => {
    setFinishDate(value);
  };

  const handleAddImage = (materialNo) => {
    window.location.href = `/warehouse/image-upload?mat_no=${materialNo}`;
  };

  return {
    handleImageClick,
    DeleteAll,
    handleEdit,
    handleSave,
    handleDelete,
    handleChangePage,
    handleChangeRowsPerPage,
    sendEmail,
    handleGreyRecords,
    setExternalRef,
    setJobDirective,
    setEquipmentType,
    setEquipmentID,
    page,
    rowsPerPage,
    editingId,
    setStartDate,
    setFinishDate,
    startDate,
    finishDate,
    handleStartDateChange,
    handleFinishDateChange,
    clickedImageIndex,
    sliderImages,
    isMaterialSearchUI,
    setIsMaterialSearchUI,
    isShoppingBasketUI,
    setIsShoppingBasketUI,
    isJobDetailsUI,
    setIsobDetailsUI,
    isReplenishmentUI,
    setIsReplenishmentUI,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    externalRef,
    jobDirective,
    equipmentType,
    equipmentID,
    spDetails,
    setClickedImageIndex,
    editedQuantity,
    setEditedQuantity,
    setSpDetails,
    setSliderImages,
    jobCardNumber,
    handleIconButtonClick,
    setSpData,
    spData,
    fetchSpDataAll,
    lclMaterialNo,
    setlclMaterialNo,
    sliderImages,
    openCarousel,
    clickedImageIndex,
    setOpenCarousel,
    handleAddImage,
    isLoading,
    fetchSpBasketAll,
  };
};

export default ShoppingBasketVM;
