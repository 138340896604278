import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import Map2 from "../assets/worldmap.png";

function ContactPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title">
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              Contact Us
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl">
          <div className="animated1 slideInUp title">
            <h1 align="center" style={{ color: "#13226c", marginTop: "0" }}>
              Our Offices
            </h1>
          </div>

          <center>
            <img
              src={Map2}
              style={{ maxWidth: "100%", height: "auto" }}
              alt="Map"
            />
          </center>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default ContactPage;
