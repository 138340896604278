import { useState, useEffect } from "react";
import HistoricalJobModel from "../model/HistoricalJobModel";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";

const HistoricalJobVM = () => {
  const [dropdownData, setDropdownData] = useState([]); //To get options in all dropdown
  const [equipmentdropdownData, setEquipmentDropdownData] = useState([]); //To get options in all dropdown
  const [selectedJobID, setSelectedJobID] = useState(null); //To store selected Job Card No/Id from Dropdown
  const [selectedJobType, setSelectedJobType] = useState(null); //To store selected Job Type from Dropdown
  const [selectedCreatedBy, setSelectedCreatedBy] = useState(null); //To store selected Created By from Dropdown
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null); //To store selected Equipment Type from Dropdown
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null); //To store selected Equipment Id from Dropdown
  const [selectedJobDesc, setSelectedJobDesc] = useState(""); //To store selected Job Directive from Textfield
  const [tableData, setTableData] = useState([]); //To store table data
  const [showTable, setShowTable] = useState(false); //To hide and show table data
  const [startDate, setStartDate] = useState(""); //To store selected Start Date from Date picker
  const [finishDate, setFinishDate] = useState(""); //To store selected Finish Date from Date picker
  const [page, setPage] = useState(0); //To set new page in pagaination
  const [rowsPerPage, setRowsPerPage] = useState(5); //To set 5 row in one page
  const [searchQuery, setSearchQuery] = useState(""); //To search any data in table
  const [newData, setNewData] = useState([]); // To store all data from job id catalogue used for filtering
  const [resetAll, setResetAll] = useState(0);
  const { fetchDataAll, fetchDropdownData, getEquipmentForDropdown } =
    HistoricalJobModel();

  const { handleToastOpen } = useToast();

  // Historical Job :  Create the formatted date and time string
  const formatDateTime = (value) => {
    const date = new Date(value);
    return date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  // Historical Job :  To get selected Start Date from Date picker
  const handleFromChange = (value) => {
    const formattedDate = formatDateTime(value);
    setStartDate(formattedDate);
  };

  // Historical Job : To get selected Finish Date from Date picker
  const handleToChange = (value) => {
    const formattedDate = formatDateTime(value);
    setFinishDate(formattedDate);
  };

  // Historical Job : To get selected Job Card No/ID from Dropdown
  const handleJobIdChange = (value) => {
    setSelectedJobID(value);
  };

  // Historical Job : To get selected Job Type from Dropdown
  const handleJobTypeChange = (value) => {
    setSelectedJobType(value);
  };

  // Historical Job : To get selected Created By from Dropdown
  const handleCreatedByChange = (value) => {
    setSelectedCreatedBy(value);
  };

  // Historical Job : To get selected Equipment Type from Dropdown
  const handleEquipmentTypeChange = (value) => {
    setSelectedEquipmentType(value);
  };

  // Historical Job : To get selected Equipment Id from Dropdown
  const handleEquipmentIdChange = (value) => {
    setSelectedEquipmentId(value);
  };

  // Historical Job : To get Input Job Directive from TextField
  const handleJobDirectiveChange = (event) => {
    const value = event.target.value;
    setSelectedJobDesc(value);
  };

  // Historical Job : Get all data from job_id_catalogue_table by terminal_id && To get values in dropdown in historical jobs page
  const fetchData = async () => {
    const historicalData = await fetchDataAll();
    const dropdownData = await fetchDropdownData();
    setNewData(historicalData);
    setDropdownData(dropdownData);
  };

  //Historical Job : Get unique equipment_id and equipment_number for shopping basket dropdown
  const fetchEquipmentDropdownData = async () => {
    try {
      const response = await getEquipmentForDropdown();
      setEquipmentDropdownData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  console.log("equipmentdropdownData", equipmentdropdownData);

  // Historical Job : Filter options for Equipment ID based on selected Equipment Type
  const filteredEquipmentIDs = equipmentdropdownData
    .filter(
      (item) =>
        !selectedEquipmentType || item.equipment_type === selectedEquipmentType
    )
    .map((item) => item.equipment_number);

  console.log("filteredEquipmentIDs", filteredEquipmentIDs);

  // Historical Job : Filter options for Equipment Type based on selected Equipment ID
  const filteredEquipmentTypes = equipmentdropdownData
    .filter(
      (item) =>
        !selectedEquipmentId || item.equipment_number === selectedEquipmentId
    )
    .map((item) => item.equipment_type);

  useEffect(() => {
    fetchData();
    fetchEquipmentDropdownData();
  }, []);

  // Historical Job :  Filter dropdown options based on selected values
  const handleSearchClick = () => {
    // Extracting date component from startDate and finishDate
    const filterStartDate = startDate
      ? new Date(startDate).toISOString().slice(0, 10)
      : "";
    const filterFinishDate = finishDate
      ? new Date(finishDate).toISOString().slice(0, 10)
      : "";

    // Get current date
    const currentDate = new Date();

    // Check if start date is ahead of current date or if finish date is ahead of current date
    if (
      (filterStartDate !== "" && new Date(filterStartDate) > currentDate) ||
      (filterFinishDate !== "" && new Date(filterFinishDate) > currentDate) ||
      (filterStartDate !== "" &&
        filterFinishDate !== "" &&
        new Date(filterStartDate) > new Date(filterFinishDate))
    ) {
      handleToastOpen("error", "Invalid date range!");
      setShowTable(false);
      return;
    }

    if (
      selectedJobID === null &&
      selectedEquipmentType === null &&
      selectedJobType === null &&
      selectedEquipmentId === null &&
      selectedCreatedBy === null &&
      selectedJobDesc.trim() === "" &&
      startDate === "" &&
      finishDate === ""
    ) {
      setTableData([]);
      setShowTable(true);
      return;
    }

    const filteredData = newData.filter((data) => {
      const jobDirective = data.job_directive.toLowerCase();
      const selectedDesc = selectedJobDesc.toLowerCase();

      // Extracting date component from data's start_date_time and finish_date_time
      const startDate = new Date(data.start_date_time);
      const finishDate = new Date(data.finish_date_time);

      const dataStartDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;

      const dataFinishDate = `${finishDate.getFullYear()}-${(
        finishDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${finishDate.getDate().toString().padStart(2, "0")}`;

      return (
        (selectedJobID === null || data.id === selectedJobID) &&
        (selectedJobType === null || data.job_type === selectedJobType) &&
        (selectedCreatedBy === null ||
          data.created_by_name === selectedCreatedBy) &&
        (selectedEquipmentType === null ||
          data.equipment_type === selectedEquipmentType) &&
        (selectedEquipmentId === null ||
          data.equipment_id === selectedEquipmentId) &&
        (selectedDesc === "" || jobDirective.includes(selectedDesc)) &&
        ((filterStartDate === "" && filterFinishDate === "") ||
          ((filterStartDate === "" || dataStartDate >= filterStartDate) &&
            (filterFinishDate === "" || dataFinishDate <= filterFinishDate)))
      );
    });

    setTableData(filteredData);
    setShowTable(true);
  };

  // Historical Job : Reset Filters and hide table
  const handleResetClick = () => {
    // Reload the page
    window.location.reload();
  };

  // Historical Job : Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Historical Job : page number
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    dropdownData,
    tableData,
    showTable,
    page,
    rowsPerPage,
    handleSearchClick,
    handleResetClick,
    setSearchQuery,
    searchQuery,
    handleFromChange,
    handleToChange,
    handleJobIdChange,
    handleJobTypeChange,
    handleCreatedByChange,
    handleEquipmentTypeChange,
    handleEquipmentIdChange,
    handleJobDirectiveChange,
    handleChangePage,
    handleChangeRowsPerPage,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    resetAll,
  };
};

export default HistoricalJobVM;
