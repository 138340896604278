import React from "react";
import { Route, Routes } from "react-router-dom";
// import { NavUtils } from "fe_comp_base";
import NavUtils from "../basecomp/main/NavUtils";

const Routing = ({ appRouteMap }) => {

  const { getRouteItems } = NavUtils();

  return (
    <Routes>
      {getRouteItems(appRouteMap).map((item, index) => (
        <Route key={index} path={item.path} element={item.element} component={item.element} />
      ))}
    </Routes>
  );
}
export default Routing;