/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import Robert from "../assets/robert.jpg";
import Samarth from "../assets/samarth.jpg";
import Grid from "@mui/material/Grid";

function AboutPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title">
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              About Us
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl" style={{ marginBottom: "-1em" }}>
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <p
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                A long-term friendship is the basis of the company <b>CONAS</b>.
                Owners Robert van der Holst – based in The Netherlands and
                Samarth Sakharkar – based in India started Conas together. Both
                have the same passion to work with data, building systems, and
                processes.
                <br />
                Both share the same beliefs of{" "}
                <b>
                  Teamwork, Integrity, Customer focus, and Continuous
                  improvement (TICC).
                </b>
                <br />
                With Robert based in The Netherlands, we are close to and
                connected with our customer base in Europe and the latest
                developments in Service Logistics. With Samarth based in India,
                we have access to highly motivated, cost-competitive resources
                working in the data and software domain. The result is a truly
                global company running projects on all continents.
              </p>
            </div>

            <Grid
              container
              spacing={3}
              style={{ justifyContent: "center", marginTop: "0.5em" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <img
                  src={Robert}
                  className="img-fluid  zoomIn"
                  style={{
                    width: "16em",
                    height: "20em",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="Robert"
                />
                <p
                  className="fontclass"
                  style={{ fontSize: "1.3rem", margin: 0 }}
                >
                  Robert van der Holst<br></br>
                  Managing Director <br></br>Netherlands
                </p>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <img
                  src={Samarth}
                  className="img-fluid  zoomIn"
                  style={{
                    width: "16em",
                    height: "20em",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="Samarth"
                />
                <p
                  className="fontclass"
                  style={{ fontSize: "1.3rem", margin: 0 }}
                >
                  Samarth Sakharkar<br></br>
                  Managing Director<br></br>India
                </p>
              </Grid>
            </Grid>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default AboutPage;
