import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const { axiosGetCallWithToken, axiosPostCallWithToken } = AxiosWrapper;

//Material Search : Get distinct data for checkboxes in card (SQ01)
export const getSQ01ForCheckBoxes = async () => {
  try {
    const url = `${Base_URL}/common/getSQ01ForCheckBoxes`;
    const response = await axiosGetCallWithToken(url);
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error fetching Checkbox Data:", error);
  }
};

//Material Search : Get row data for add to cart by its Id (SQ01)
export const getSQ01ByID = async (id) => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSq01CatById?sq_id=${id}`
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching sq01catById:", error);
  }
};

//Material Search : Get all Sq01 details for filtering table (SQ01)
export const getSq01All = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSq01All`
    );
    console.log("datassssssssss", response);
    const data = response.data;
    return data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching Sq01 details:", error);
  }
};

//D-Catalogue (most used) : Fetch merge result of spbasket and sq01 data(Sp Basket, SQ01 , Material Images)
export const getSQ01AndSpBasket = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSpAndSq01`
    );
    const data = response.data;
    return data;
  } catch (error) {
    Logger.messageStackTrace(
      "Error fetching SQ01 and SpBasket details:",
      error
    );
  }
};

//D-Catalogue (most used): Fetch merge result of spbasket and ictsi data (Sp Basket, Global Data, Material Images)
export const getGlobalAndSpBasket = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSpAndGlobal`
    );
    const data = response.data;
    return data;
  } catch (error) {
    Logger.messageStackTrace(
      "Error fetching Global and SpBasket details:",
      error
    );
  }
};

//Material Search : Insert or Add data in sp_basket (Sp Basket)
export const postSpBasket = async (data) => {
  try {
    console.log("sp_basket", data);
    const response = await axiosPostCallWithToken(
      `${Base_URL}/dcatalogue/addSpBasket`,
      data
    );
    return response;
  } catch (error) {
    Logger.messageStackTrace("Error posting SpBasket details:", error);
  }
};

//Material Search : Generate new sp_basket id logic (Job Id Catalogue)
export const getSpBasketID = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getSpBasketID`
    );

    return response;
  } catch (error) {
    Logger.messageStackTrace("Error getting SpBasket ID: ", error);
  }
};
