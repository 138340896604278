import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Button,
  Dialog,
  Toolbar,
  DialogContent,
  DialogActions,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";

import Typography from "@mui/material/Typography";
import useSparePartInfoViewModel from "../viewmodel/SparePartInfoVM";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CText from "../../../basecomp/components/CText";
import CButton from "../../../basecomp/components/CButton";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import CCarousel from "../../../basecomp/components/CCarousel";

const OrderBox = styled("div")({
  textAlign: "start",
  border: "1px solid #3CB043",
  width: "100%",
  margin: "2rem 0em",
  borderRadius: "1.2em 1.2em 1em 1em",
});

const OrderBoxText = styled("div")({
  textAlign: "center",
  backgroundColor: "#3CB043",
  color: "white",
  padding: "0.5em 3em",
  borderRadius: "0.7em 0.7em 0em 0em",
});

const OrderBoxBtn = styled("div")({
  padding: "2em 3em",
  display: "flex",
  justifyContent: "center",
});

function SparePartInfoView() {
  const {
    materialDetails,
    handleOpenModal,
    modalOpen,
    setModalOpen,
    error,
    handleChange,
    quantity,
    addToCart,
  } = useSparePartInfoViewModel();

  const [openCarousel, setOpenCarousel] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const infoHeadData1 = ["Storage Location / Bin", "OHQ", "UOM"];
  const [aData1, setAData1] = useState([]);
  const [infoRowData, setInfoRowData] = useState([]);
  const [infoRowData1, setInfoRowData1] = useState([]);

  useEffect(() => {
    if (materialDetails !== undefined && materialDetails.length > 0) {
      const materialDet = materialDetails[0];

      // setAssetData([
      //   [["Plant Name:", materialDet.plant_id],
      //   ["Asset ID:",
      //     (materialDet.asset_id === undefined ? "" : materialDet.asset_id)]],
      //   [["Global Material Number:",
      //     (materialDet.gbl_material_number === undefined ? "" : materialDet.gbl_material_number)],
      //   ["Global Description:", materialDet.gbl_material_desc]],
      //   [["Local Material Number:", materialDet.material],
      //   ["Local Description:", materialDet.material_desc]],
      //   [["Manufacturer Name:", materialDet.manufacturer_name],
      //   ["Manufacturer Number:", materialDet.manufac_item_code]],
      //   // ["", ""],
      //   // [["Long Desc.:", materialDet.long_desc],
      //   // ["Local Lang. Desc.:", materialDet.local_material_desc]]
      // ]);

      setAssetData([
        [
          {
            name: "Plant Name",
            value:
              materialDet.plant_id === undefined ? " " : materialDet.plant_id,
          },
        ],
        [
          {
            name: "Asset ID",
            value:
              materialDet.asset_id === undefined ||
              materialDet.asset_id === null
                ? "\u00A0"
                : materialDet.asset_id,
          },
        ],
        [
          {
            name: "Global No.",
            value:
              materialDet.gbl_material_number === undefined ||
              materialDet.gbl_material_number === null
                ? "\u00A0"
                : materialDet.gbl_material_number,
          },
          {
            name: "Global Desc.",
            value:
              materialDet.gbl_material_desc === undefined ||
              materialDet.gbl_material_desc === null
                ? "\u00A0"
                : materialDet.gbl_material_desc,
          },
        ],
        [
          {
            name: "Local No.",
            value:
              materialDet.material_no === undefined ||
              materialDet.material_no === null
                ? "\u00A0"
                : materialDet.material_no,
          },
          {
            name: "Local Desc.",
            value:
              materialDet.material_desc === undefined ||
              materialDet.material_desc === null
                ? "\u00A0"
                : materialDet.material_desc,
          },
        ],
        [
          {
            name: "Manufacturer Name",
            value:
              materialDet.manufacturer_name === undefined ||
              materialDet.manufacturer_name === null
                ? "\u00A0"
                : materialDet.manufacturer_name,
          },
          {
            name: "Manufacturer No.",
            value:
              materialDet.manufac_item_code === undefined ||
              materialDet.manufac_item_code === null
                ? "\u00A0"
                : materialDet.manufac_item_code,
          },
        ],
      ]);
      setInfoRowData([
        { value: `name`, type: "T" },
        { value: `value`, type: "T" },
      ]);

      setAData1([
        {
          storage_loc:
            (materialDet.storage_loc ? materialDet.storage_loc : "") +
            (materialDet.bin_location ? `/ ${materialDet.bin_location}` : ""),
          ohqty: materialDet.ohqty,
          b_un: materialDet.b_un,
        },
      ]);
      setInfoRowData1([
        { value: `storage_loc`, type: "T" },
        { value: `ohqty`, type: "T" },
        { value: `b_un`, type: "T" },
      ]);
    }
  }, [materialDetails]);

  return (
    <>
      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        lg={10}
        xl={10}
        sx={{ margin: "5em 0.5em 0.5em 0.5em" }}
      >
        <Card sx={{ padding: "0.5em" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {materialDetails && (
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {assetData.map((actualArr, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {infoRowData.map((rowData, cellIndex) => (
                          <TableCell
                            component="th"
                            scope="row"
                            key={cellIndex}
                            sx={{
                              border: "0.1em solid black",
                              padding: "0em 0.2em 0em 0.2em",
                              margin: "0em",
                            }}
                          >
                            {actualArr.map((actualRow, rowIndex) => (
                              <TableRow key={rowIndex}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    border: "0em solid black",
                                    textAlign: "left",
                                    padding: "0.8em 0em 0.8em 0em",
                                    verticalAlign: "middle",
                                    fontSize: "0.9em",
                                  }}
                                >
                                  {rowData.type === "T" &&
                                    actualRow[rowData.value]}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          {materialDetails && (
            <>
              <Grid item sx={{ marginTop: "3em" }}>
                <CText
                  cText="Inventory Location Details"
                  cVariant="header_card"
                />
              </Grid>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {infoHeadData1.map((headData, headIndex) => (
                        <TableCell
                          key={headIndex}
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            border: "0.2em solid black",
                            width: "34%",
                          }}
                        >
                          {headData}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {aData1.map((actualRow, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {infoRowData1.map((rowData, cellIndex) => (
                          <TableCell
                            component="th"
                            scope="row"
                            key={cellIndex}
                            sx={{
                              textAlign: "center",
                              border: "0.1em solid black",
                            }}
                          >
                            {rowData.type === "T" && actualRow[rowData.value]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {/* {materialDetails && (
            <CTable
              id="gsone_info_table"
              cHeaderData={infoHeadData1}
              cRowData={infoRowData1}
              cActualData={aData1}
              cIsPagination={false}
              cSmallSize={true}
            />
          )} */}
          <Grid item sx={{ marginTop: "3em" }}>
            {materialDetails && (
              <OrderBox>
                <OrderBoxText>Links</OrderBoxText>
                <OrderBoxBtn>
                  <CButtonGroup>
                    {materialDetails?.[0]?.image_link?.length > 0 && (
                      <CButton
                        cId="gsone_sparepartinfo_image_btn_link"
                        cTooltipText="Image"
                        cSrc={<CameraAltIcon />}
                        cOnClick={() => setOpenCarousel(true)}
                      />
                    )}

                    <Link
                      to={
                        "/d-catalogue/shopping-basket?lcl_mat_no=" +
                        materialDetails[0].material_no
                      }
                    >
                      <CButton
                        cId="gsone_sparepartinfo_matno_btn_search"
                        cTooltipText="Material Search"
                        cSrc={<SearchIcon />}
                      />
                    </Link>
                    <CButton
                      cId="gsone_sparepartinfo_add_btn_cart"
                      cTooltipText="Add to Cart"
                      cSrc={<ShoppingCartOutlinedIcon />}
                      cOnClick={() => handleOpenModal(materialDetails[0].id)}
                    />
                  </CButtonGroup>
                </OrderBoxBtn>
              </OrderBox>
            )}
          </Grid>
        </Card>
      </Grid>
      {openCarousel && (
        <CCarousel
          cOpen={openCarousel}
          images={materialDetails[0].image_link}
          cClose={() => setOpenCarousel(false)}
        />
      )}

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{
          style: {
            marginBottom: "25em",
            maxWidth: "23em", // Adjust the desired maximum width
            width: "100%", // Ensure the modal takes up the full available width
          },
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#3CB043",
            alignItems: "center",
          }}
        >
          <Toolbar>
            <ShoppingCartOutlinedIcon />
          </Toolbar>
        </AppBar>

        <DialogContent>
          <div className="modal-body">
            <Typography
              style={{
                color: "#1976d2",
                marginBottom: "1em",
              }}
            >
              QTY
            </Typography>
            <TextField
              id="standard-basic"
              fullWidth
              value={quantity}
              onChange={handleChange}
              variant="standard"
            />
            {error && <span>{error}</span>}
          </div>
        </DialogContent>

        <DialogActions>
          <Button style={{ color: "#1976d2" }} onClick={addToCart}>
            OK
          </Button>
          <Button
            onClick={() => setModalOpen(false)}
            style={{ color: "#1976d2" }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SparePartInfoView;
