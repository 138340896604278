/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import {
  getSQ01ForCheckBoxes,
  getSQ01ByID,
  getSq01All,
  getSQ01AndSpBasket,
  getGlobalAndSpBasket,
  postSpBasket,
  getSpBasketID,
} from "../model/MaterialSearchModel";

let globalData;

const MaterialSearchVM = (props) => {
  const {
    setSpDetails,
    jobCardNumber,
    setSpData,
    lclMaterialNo,
    setlclMaterialNo,
  } = props;
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [spBasketId, setSpBasketId] = useState("");
  const [sq01Details, setSq01Details] = useState(globalData);
  const [material, setMaterial] = useState("");
  const [globalMaterial, setGlobalMaterial] = useState("");
  const [materialDesc, setMaterialDesc] = useState("");
  const [manufName, setManufName] = useState("");
  const [manufNo, setManufNo] = useState("");
  const [equipFunc, setEquipFunc] = useState("");
  const [binloc, setBinLoc] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [completeData, setCompleteData] = useState(null);
  const [error, setError] = useState("");
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [data1CheckBox, setData1CheckBox] = useState([]);
  const [data2CheckBox, setData2CheckBox] = useState([]);
  const [data3CheckBox, setData3CheckBox] = useState([]);
  const [data4CheckBox, setData4CheckBox] = useState([]);
  const [initialDataCheckBox, setInitialDataCheckBox] = useState([]);
  const [initialData1CheckBox, setInitialData1CheckBox] = useState([]);
  const [initialData2CheckBox, setInitialData2CheckBox] = useState([]);
  const [initialData3CheckBox, setInitialData3CheckBox] = useState([]);
  const [initialData4CheckBox, setInitialData4CheckBox] = useState([]);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [sliderImages, setSliderImages] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const { handleToastOpen } = useToast();

  //Material Search : Filter Checkbox on card Purchasing Group
  const handlePurchasingGroup = (index) => {
    setDataCheckBox((prevDataDropdown) => {
      const updatedDataDropdown = prevDataDropdown.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
            hidden: item.checked ? true : false,
          };
        } else {
          return {
            ...item,
            hidden: true,
          };
        }
      });
      return updatedDataDropdown;
    });

    const selectedLevel = dataCheckBox[index].pgr_desc;

    if (dataCheckBox[index].checked) {
      // Uncheck the checkbox and reset the filters
      setDataCheckBox(initialDataCheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
      return;
    }

    const filteredOptions = sq01Details.filter((data) => {
      return selectedLevel === null || data.pgr_desc === selectedLevel;
    });

    const Level1 = Array.from(
      new Set(filteredOptions.map((option) => option.level1))
    );
    const Level2 = Array.from(
      new Set(filteredOptions.map((option) => option.level2))
    );
    const Level3 = Array.from(
      new Set(filteredOptions.map((option) => option.level3))
    );
    const Level4 = Array.from(
      new Set(filteredOptions.map((option) => option.level4))
    );

    const filteredData1 = data1CheckBox.filter((item) => {
      return Level1.includes(item.level1);
    });
    const filteredData2 = data2CheckBox.filter((item) => {
      return Level2.includes(item.level2);
    });
    const filteredData3 = data3CheckBox.filter((item) => {
      return Level3.includes(item.level3);
    });
    const filteredData4 = data4CheckBox.filter((item) => {
      return Level4.includes(item.level4);
    });

    setData1CheckBox(filteredData1);
    setData2CheckBox(filteredData2);
    setData3CheckBox(filteredData3);
    setData4CheckBox(filteredData4);
  };

  //Material Search : Filter Checkbox on card Level 1
  const handleLevel1 = (index) => {
    setData1CheckBox((prevdata1CheckBox) => {
      const updatedData1Dropdown = prevdata1CheckBox.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
            hidden: item.checked ? true : false,
          };
        } else {
          return {
            ...item,
            hidden: true,
          };
        }
      });
      return updatedData1Dropdown;
    });

    const selectedLevel1 = data1CheckBox[index].level1;

    if (data1CheckBox[index].checked) {
      // Uncheck the checkbox and reset the filters
      setDataCheckBox(initialDataCheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
      return;
    }

    const filteredOptions = sq01Details.filter((data) => {
      return selectedLevel1 === null || data.level1 === selectedLevel1;
    });

    const Pgr = Array.from(
      new Set(filteredOptions.map((option) => option.pgr_desc))
    );
    const Level2 = Array.from(
      new Set(filteredOptions.map((option) => option.level2))
    );
    const Level3 = Array.from(
      new Set(filteredOptions.map((option) => option.level3))
    );
    const Level4 = Array.from(
      new Set(filteredOptions.map((option) => option.level4))
    );

    const filteredData1 = dataCheckBox.filter((item) => {
      return Pgr.includes(item.pgr_desc);
    });
    const filteredData2 = data2CheckBox.filter((item) => {
      return Level2.includes(item.level2);
    });
    const filteredData3 = data3CheckBox.filter((item) => {
      return Level3.includes(item.level3);
    });
    const filteredData4 = data4CheckBox.filter((item) => {
      return Level4.includes(item.level4);
    });

    setDataCheckBox(filteredData1);
    setData2CheckBox(filteredData2);
    setData3CheckBox(filteredData3);
    setData4CheckBox(filteredData4);
  };

  //Material Search : Filter Checkbox on card Level 2
  const handleLevel2 = (index) => {
    setData2CheckBox((prevdata2CheckBox) => {
      const updatedData2Dropdown = prevdata2CheckBox.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
            hidden: item.checked ? true : false,
          };
        } else {
          return {
            ...item,
            hidden: true,
          };
        }
      });
      return updatedData2Dropdown;
    });

    const selectedLevel2 = data2CheckBox[index].level2;

    if (data2CheckBox[index].checked) {
      // Uncheck the checkbox and reset the filters
      setDataCheckBox(initialDataCheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
      return;
    }

    const filteredOptions = sq01Details.filter((data) => {
      return selectedLevel2 === null || data.level2 === selectedLevel2;
    });

    const Level1 = Array.from(
      new Set(filteredOptions.map((option) => option.level1))
    );
    const Pgr = Array.from(
      new Set(filteredOptions.map((option) => option.pgr_desc))
    );
    const Level3 = Array.from(
      new Set(filteredOptions.map((option) => option.level3))
    );
    const Level4 = Array.from(
      new Set(filteredOptions.map((option) => option.level4))
    );

    const filteredData1 = data1CheckBox.filter((item) => {
      return Level1.includes(item.level1);
    });
    const filteredData2 = dataCheckBox.filter((item) => {
      return Pgr.includes(item.pgr_desc);
    });
    const filteredData3 = data3CheckBox.filter((item) => {
      return Level3.includes(item.level3);
    });
    const filteredData4 = data4CheckBox.filter((item) => {
      return Level4.includes(item.level4);
    });

    setData1CheckBox(filteredData1);
    setDataCheckBox(filteredData2);
    setData3CheckBox(filteredData3);
    setData4CheckBox(filteredData4);
  };

  //Material Search : Filter Checkbox on card Level 3
  const handleLevel3 = (index) => {
    setData3CheckBox((prevdata3CheckBox) => {
      const updatedData3Dropdown = prevdata3CheckBox.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
            hidden: item.checked ? true : false,
          };
        } else {
          return {
            ...item,
            hidden: true,
          };
        }
      });
      return updatedData3Dropdown;
    });

    const selectedLevel3 = data3CheckBox[index].level3;

    if (data3CheckBox[index].checked) {
      // Uncheck the checkbox and reset the filters
      setDataCheckBox(initialDataCheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
      return;
    }

    const filteredOptions = sq01Details.filter((data) => {
      return selectedLevel3 === null || data.level3 === selectedLevel3;
    });

    const Level1 = Array.from(
      new Set(filteredOptions.map((option) => option.level1))
    );
    const Pgr = Array.from(
      new Set(filteredOptions.map((option) => option.pgr_desc))
    );
    const Level2 = Array.from(
      new Set(filteredOptions.map((option) => option.level2))
    );
    const Level4 = Array.from(
      new Set(filteredOptions.map((option) => option.level4))
    );

    const filteredData1 = data1CheckBox.filter((item) => {
      return Level1.includes(item.level1);
    });
    const filteredData2 = dataCheckBox.filter((item) => {
      return Pgr.includes(item.pgr_desc);
    });
    const filteredData3 = data2CheckBox.filter((item) => {
      return Level2.includes(item.level2);
    });
    const filteredData4 = data4CheckBox.filter((item) => {
      return Level4.includes(item.level4);
    });

    setData1CheckBox(filteredData1);
    setDataCheckBox(filteredData2);
    setData2CheckBox(filteredData3);
    setData4CheckBox(filteredData4);
  };

  //Material Search : Filter Checkbox on card Level 4
  const handleLevel4 = (index) => {
    setData4CheckBox((prevdata4CheckBox) => {
      const updatedData4Dropdown = prevdata4CheckBox.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
            hidden: item.checked ? true : false,
          };
        } else {
          return {
            ...item,
            hidden: true,
          };
        }
      });
      return updatedData4Dropdown;
    });

    const selectedLevel4 = data4CheckBox[index].level4;

    if (data4CheckBox[index].checked) {
      // Uncheck the checkbox and reset the filters
      setDataCheckBox(initialDataCheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
      return;
    }

    const filteredOptions = sq01Details.filter((data) => {
      return selectedLevel4 === null || data.level4 === selectedLevel4;
    });

    const Level1 = Array.from(
      new Set(filteredOptions.map((option) => option.level1))
    );
    const Pgr = Array.from(
      new Set(filteredOptions.map((option) => option.pgr_desc))
    );
    const Level2 = Array.from(
      new Set(filteredOptions.map((option) => option.level2))
    );
    const Level3 = Array.from(
      new Set(filteredOptions.map((option) => option.level3))
    );

    const filteredData1 = data1CheckBox.filter((item) => {
      return Level1.includes(item.level1);
    });
    const filteredData2 = dataCheckBox.filter((item) => {
      return Pgr.includes(item.pgr_desc);
    });
    const filteredData3 = data2CheckBox.filter((item) => {
      return Level2.includes(item.level2);
    });
    const filteredData4 = data3CheckBox.filter((item) => {
      return Level3.includes(item.level3);
    });

    setData1CheckBox(filteredData1);
    setDataCheckBox(filteredData2);
    setData2CheckBox(filteredData3);
    setData3CheckBox(filteredData4);
  };

  //Material Search : Get distinct data for checkboxes in card (SQ01)
  const fetchSQ01Dropdown = async () => {
    try {
      const response = await getSQ01ForCheckBoxes();

      // Check and filter null entries from response data

      const filteredData = response.data.data.filter(
        (item) => item.pgr_desc !== null
      );

      const filteredData1 = response.data.data1.filter(
        (item) => item.level1 !== null
      );

      const filteredData2 = response.data.data2.filter(
        (item) => item.level2 !== null
      );

      const filteredData3 = response.data.data3.filter(
        (item) => item.level3 !== null
      );

      const filteredData4 = response.data.data4.filter(
        (item) => item.level4 !== null
      );

      setDataCheckBox(filteredData);

      setInitialDataCheckBox(filteredData);

      setData1CheckBox(filteredData1);

      setInitialData1CheckBox(filteredData1);

      setData2CheckBox(filteredData2);

      setInitialData2CheckBox(filteredData2);

      setData3CheckBox(filteredData3);

      setInitialData3CheckBox(filteredData3);

      setData4CheckBox(filteredData4);

      setInitialData4CheckBox(filteredData4);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSQ01Dropdown();
  }, []);

  //Material Search : Reset  all Filters and hide table
  const resetPage = () => {
    setMaterial("");
    setlclMaterialNo("");
    setGlobalMaterial("");
    setMaterialDesc("");
    setManufName("");
    setManufNo("");
    setEquipFunc("");
    setBinLoc("");
    setDataCheckBox(initialDataCheckBox);
    setData1CheckBox(initialData1CheckBox);
    setData2CheckBox(initialData2CheckBox);
    setData3CheckBox(initialData3CheckBox);
    setData4CheckBox(initialData4CheckBox);
    setShowTable(false);

    // Remove query parameters from the URL
    const newUrl = window.location.pathname;
    window.history.pushState({}, "", newUrl);
  };

  //Material Search : Clear Pgr Filter Only
  const handleResetPgr = () => {
    // Clear filters for purchasing card
    setDataCheckBox(initialDataCheckBox);

    // Check if Level1, Level2, Level3, or Level4 checkboxes are selected
    const selectedLevel1Checkbox = data1CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel2Checkbox = data2CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel3Checkbox = data3CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel4Checkbox = data4CheckBox.find(
      (item) => item.checked === true
    );

    if (selectedLevel1Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level1 === selectedLevel1Checkbox.level1
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredPgr = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setDataCheckBox(filteredPgr);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel2Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level2 === selectedLevel2Checkbox.level2
      );
      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredPgr = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setDataCheckBox(filteredPgr);
      setData1CheckBox(filteredData1);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel3Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level3 === selectedLevel3Checkbox.level3
      );
      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredPgr = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setDataCheckBox(filteredPgr);
      setData1CheckBox(filteredData1);
      setData2CheckBox(filteredData2);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel4Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level4 === selectedLevel4Checkbox.level4
      );
      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );

      const filteredPgr = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );

      setDataCheckBox(filteredPgr);
      setData1CheckBox(filteredData1);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
    } else {
      // No checkboxes selected, clear filters for all cards
      setData1CheckBox(initialData1CheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
    }
  };

  //Material Search : Clear Level 1 Filter Only
  const handleResetLevel1 = () => {
    // Clear Level1 filters
    setData1CheckBox(initialData1CheckBox);

    // Check if Purchasing Group, Level2, Level3, or Level4 filters have any selections
    const selectedPgrCheckbox = dataCheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel2Checkbox = data2CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel3Checkbox = data3CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel4Checkbox = data4CheckBox.find(
      (item) => item.checked === true
    );

    if (selectedPgrCheckbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.pgr_desc === selectedPgrCheckbox.pgr_desc
      );

      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel2Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level2 === selectedLevel2Checkbox.level2
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );

      setDataCheckBox(filteredData);
      setData1CheckBox(filteredData1);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel3Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level3 === selectedLevel3Checkbox.level3
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData2CheckBox(filteredData2);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel4Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level4 === selectedLevel4Checkbox.level4
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
    } else {
      // No checkboxes selected, clear filters for all cards
      setDataCheckBox(initialDataCheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
    }
  };

  //Material Search : Clear Level 2 Filter Only
  const handleResetLevel2 = () => {
    // Clear Level2 filters
    setData2CheckBox(initialData2CheckBox);

    // Check if Purchasing Group, Level1, Level3, or Level4 filters have any selections
    const selectedPgrCheckbox = dataCheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel1Checkbox = data1CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel3Checkbox = data3CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel4Checkbox = data4CheckBox.find(
      (item) => item.checked === true
    );

    if (selectedPgrCheckbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.pgr_desc === selectedPgrCheckbox.pgr_desc
      );

      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel1Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level1 === selectedLevel1Checkbox.level1
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData2CheckBox(filteredData2);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel3Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level3 === selectedLevel3Checkbox.level3
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData2CheckBox(filteredData2);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel4Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level4 === selectedLevel4Checkbox.level4
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData2CheckBox(filteredData2);
    } else {
      // No checkboxes selected, clear filters for all cards
      setDataCheckBox(initialDataCheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData3CheckBox(initialData3CheckBox);
      setData4CheckBox(initialData4CheckBox);
    }
  };

  //Material Search : Clear Level 3 Filter Only
  const handleResetLevel3 = () => {
    // Clear Level3 filters
    setData3CheckBox(initialData3CheckBox);

    // Check if Purchasing Group, Level1, Level3, or Level4 filters have any selections
    const selectedPgrCheckbox = dataCheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel1Checkbox = data1CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel2Checkbox = data2CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel4Checkbox = data4CheckBox.find(
      (item) => item.checked === true
    );

    if (selectedPgrCheckbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.pgr_desc === selectedPgrCheckbox.pgr_desc
      );

      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel1Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level1 === selectedLevel1Checkbox.level1
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData2CheckBox(filteredData2);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel2Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level2 === selectedLevel2Checkbox.level2
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel4Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level4 === selectedLevel4Checkbox.level4
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData2CheckBox(filteredData2);
    } else {
      // No checkboxes selected, clear filters for all cards
      setDataCheckBox(initialDataCheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData4CheckBox(initialData4CheckBox);
    }
  };

  //Material Search : Clear Level 3 Filter Only
  const handleResetLevel4 = () => {
    // Clear Level3 filters
    setData4CheckBox(initialData4CheckBox);

    // Check if Purchasing Group, Level1, Level3, or Level4 filters have any selections
    const selectedPgrCheckbox = dataCheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel1Checkbox = data1CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel2Checkbox = data2CheckBox.find(
      (item) => item.checked === true
    );
    const selectedLevel3Checkbox = data3CheckBox.find(
      (item) => item.checked === true
    );

    if (selectedPgrCheckbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.pgr_desc === selectedPgrCheckbox.pgr_desc
      );

      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setData2CheckBox(filteredData2);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel1Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level1 === selectedLevel1Checkbox.level1
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData2CheckBox(filteredData2);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel2Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level2 === selectedLevel2Checkbox.level2
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level4 = Array.from(
        new Set(filteredOptions.map((option) => option.level4))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData4 = initialData4CheckBox.filter((item) =>
        Level4.includes(item.level4)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData4CheckBox(filteredData4);
    } else if (selectedLevel3Checkbox) {
      const filteredOptions = sq01Details.filter(
        (data) => data.level4 === selectedLevel3Checkbox.level4
      );

      const Pgr = Array.from(
        new Set(filteredOptions.map((option) => option.pgr_desc))
      );
      const Level1 = Array.from(
        new Set(filteredOptions.map((option) => option.level1))
      );
      const Level3 = Array.from(
        new Set(filteredOptions.map((option) => option.level3))
      );
      const Level2 = Array.from(
        new Set(filteredOptions.map((option) => option.level2))
      );

      const filteredData = initialDataCheckBox.filter((item) =>
        Pgr.includes(item.pgr_desc)
      );
      const filteredData1 = initialData1CheckBox.filter((item) =>
        Level1.includes(item.level1)
      );
      const filteredData3 = initialData3CheckBox.filter((item) =>
        Level3.includes(item.level3)
      );
      const filteredData2 = initialData2CheckBox.filter((item) =>
        Level2.includes(item.level2)
      );

      setData1CheckBox(filteredData1);
      setDataCheckBox(filteredData);
      setData3CheckBox(filteredData3);
      setData2CheckBox(filteredData2);
    } else {
      // No checkboxes selected, clear filters for all cards
      setDataCheckBox(initialDataCheckBox);
      setData2CheckBox(initialData2CheckBox);
      setData1CheckBox(initialData1CheckBox);
      setData3CheckBox(initialData3CheckBox);
    }
  };

  //Material Search : To view image in carousel
  const handleImageClick = async (imageLink, index) => {
    setClickedImageIndex(index);
    setSliderImages(imageLink);
    setOpenCarousel(true);
  };

  //Material Search : Check quantity must be numeric and update new quantity
  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Numeric regex pattern
    const numericPattern = /^[0-9]*$/;

    if (numericPattern.test(inputValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      setQuantity("");
      setError("Value must be numeric");
    }
  };

  //Material Search : Get row data for add to cart by its Id (SQ01)
  const fetchRowData = async (id) => {
    try {
      const data = await getSQ01ByID(id);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //Material Search : Get all Sq01 details for filtering table (SQ01)
  const fetchSq01All = async (type) => {
    try {
      // Only call the API if globalData is undefined or type is explicitly set to "call"
      if (type === "call" || !globalData) {
        globalData = await getSq01All();
        setSq01Details(globalData);
        // Set count and timestamp in localStorage
        localStorage.setItem("sq01Count", "true");
        localStorage.setItem("sq01Timestamp", Date.now());
      }
    } catch (error) {
      console.error("Error fetching Sq01 details:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // Check if all required data arrays are populated and stop loader
    if (
      sq01Details &&
      dataCheckBox.length > 0 &&
      data1CheckBox.length > 0 &&
      data2CheckBox.length > 0 &&
      data3CheckBox.length > 0 &&
      data4CheckBox.length > 0 &&
      initialDataCheckBox.length > 0 &&
      initialData1CheckBox.length > 0 &&
      initialData2CheckBox.length > 0 &&
      initialData3CheckBox.length > 0 &&
      initialData4CheckBox.length > 0
    ) {
      setIsLoading(false);
    }
  }, [
    sq01Details,
    dataCheckBox,
    data1CheckBox,
    data2CheckBox,
    data3CheckBox,
    data4CheckBox,
    initialDataCheckBox,
    initialData1CheckBox,
    initialData2CheckBox,
    initialData3CheckBox,
    initialData4CheckBox,
  ]);

  const checkAndFetchSq01All = () => {
    const sq01Count = localStorage.getItem("sq01Count");
    const sq01Timestamp = localStorage.getItem("sq01Timestamp");
    if (sq01Count === "true" && sq01Timestamp) {
      const elapsedTime = Date.now() - parseInt(sq01Timestamp, 10);
      const threeHoursInMilliseconds = 3 * 60 * 60 * 1000;
      if (elapsedTime < threeHoursInMilliseconds) {
        // Skip the API call if less than 3 hours have passed
        return;
      }
    }
    // Call fetchSq01All with type set to "call"
    fetchSq01All("call");
  };

  useEffect(() => {
    checkAndFetchSq01All();
  }, []);

  useEffect(() => {
    // Check if globalData is undefined
    if (globalData === undefined || globalData === "") {
      // If globalData is undefined, call fetchSq01All()
      fetchSq01All("call");
    }
  }, [globalData]);
  console.log("ajhajjkk", tableData);
  //D-Catalogue (most used) : Fetch merge result of spbasket and sq01 data && spbasket and ictsi data(Sp Basket, SQ01 , Global Data, Material Images)
  const fetchSpBasketAll = async () => {
    try {
      const data = await getSQ01AndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpDetails([]);
      } else {
        setSpDetails(data);
        console.log("latest", data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketAll();
  }, []);

  //D-Catalogue (most used): Fetch merge result of spbasket and ictsi data (Sp Basket, Global Data, Material Images)
  const fetchSpDataAll = async () => {
    try {
      const data = await getGlobalAndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpData([]);
      } else {
        setSpData(data);
        console.log("latest", data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpDataAll();
  }, []);

  //Material Search : Generate new sp_basket id logic (Job Id Catalogue)
  const fetchSpBasketID = async () => {
    try {
      const response = await getSpBasketID();
      const data = await response.data;

      if (data) {
        setSpBasketId((prevSpBasketID) => {
          return data.sp_basket_id;
        });
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketID();
  }, []);

  //Material Search : Insert or Add data in sp_basket (Sp Basket)
  const addToCart = async () => {
    if (quantity <= 0) {
      handleToastOpen("error", "Quantity must be greater than 0");
      return; // Exit function if quantity is not valid
    }
    const qty_val = completeData[0].ohqty - quantity;
    const status_qty = qty_val >= 0 ? 1 : 0;
    const storage_bin_loc =
      (completeData[0].storage_loc !== null
        ? completeData[0].storage_loc
        : "") +
      (completeData[0].bin_location === null
        ? ""
        : `${completeData[0].storage_loc === null ? "" : " / "}` +
          completeData[0].bin_location);
    // Prepare the data to send as an array

    console.log("333333333333333", spBasketId);
    const data = [
      {
        id: spBasketId,
        oem_no: "", // Use an empty string as a default if oem_no is null
        oem_description: "", // Use an empty string as a default if oem_description is null
        notes: "", // Use an empty string as a default if notes is null
        quantity: quantity || "", // Use an empty string as a default if quantity is null
        uom: completeData[0].b_un || "", // Use an empty string as a default if uom is null
        material_no: completeData[0].material_no || "", // Use an empty string as a default if material_no is null
        global_material_no: "", // Use an empty string as a default if gbl material_no is null
        global_material_desc: "", // Use an empty string as a default if glb material_desc is null
        storage_bin: storage_bin_loc || "", // Use an empty string as a default if storage_bin is null
        status: status_qty, // Use an empty string as a default if status is null
        job_id: jobCardNumber, // Use an empty string as a default if job id is null
        type: "SB" || "", // Default value "SB" if type is not provided
      },
    ];

    try {
      // Send the data to the API endpoint using Axios
      const response = await postSpBasket(data);
      console.log("xxxxxxxxxxxx", response);
      console.log("xxxxxxxxxxxxstatus", response.status);
      if (response.status === 201) {
        handleToastOpen("success", "Data added successfully");
        setModalOpen(false);
        setQuantity(1);
        setSelectedRowId("");
      }
      fetchSpDataAll();
      fetchSpBasketAll();
      fetchSpBasketID();
    } catch (error) {
      setQuantity(1);
      setSelectedRowId("");
      handleToastOpen("error", "Material already exists");
      console.error("Error occurred during API request:", error);
    }
  };

  //Material Search : Filter table on search click
  const handleButtonClick = async () => {
    if (
      material.trim() === "" &&
      globalMaterial.trim() === "" &&
      materialDesc.trim() === "" &&
      manufName.trim() === "" &&
      manufNo.trim() === "" &&
      equipFunc.trim() === "" &&
      binloc.trim() === "" &&
      dataCheckBox.every((item) => !item.checked) &&
      data1CheckBox.every((item) => !item.checked) &&
      data2CheckBox.every((item) => !item.checked) &&
      data3CheckBox.every((item) => !item.checked) &&
      data4CheckBox.every((item) => !item.checked)
    ) {
      setTableData([]);
      setShowTable(true);
      return;
    }

    let filteredData = [];
    if (sq01Details) {
      filteredData = sq01Details.filter((data) => {
        // Split materialDesc into individual search terms
        const materialDescTerms = materialDesc
          .split("%")
          .filter((term) => term.trim() !== ""); // Remove empty terms
        // Function to check if any of the materialDesc terms match data.concat_mat_desc
        const isMaterialDescMatch = materialDescTerms.every((keyword) =>
          data.concat_mat_desc.toLowerCase().includes(keyword.toLowerCase())
        );
        return (
          (material.trim() === "" ||
            (data.material_no &&
              data.material_no
                .toLowerCase()
                .includes(material.toLowerCase()))) &&
          (globalMaterial.trim() === "" ||
            (data.industry_std_desc &&
              data.industry_std_desc
                .toLowerCase()
                .includes(globalMaterial.toLowerCase()))) &&
          (materialDesc.trim() === "" || isMaterialDescMatch) &&
          (manufName.trim() === "" ||
            [
              data.mpn_name1,
              data.mpn_name2,
              data.mpn_name3,
              data.mpn_name4,
              data.mpn_name5,
            ].some(
              (name) =>
                name && name.toLowerCase().includes(manufName.toLowerCase())
            )) &&
          (manufNo.trim() === "" ||
            [
              data.manufac_item_code,
              data.mpn_number1,
              data.mpn_number2,
              data.mpn_number3,
              data.mpn_number4,
              data.mpn_number5,
            ].some(
              (name) =>
                name && name.toLowerCase().includes(manufNo.toLowerCase())
            )) &&
          (equipFunc.trim() === "" ||
            (data.concat_equipment_funct &&
              data.concat_equipment_funct
                .toLowerCase()
                .includes(equipFunc.toLowerCase()))) &&
          (binloc.trim() === "" ||
            (data.bin_location &&
              data.bin_location
                .toLowerCase()
                .includes(binloc.toLowerCase()))) &&
          (dataCheckBox.every((item) => !item.checked) ||
            dataCheckBox.find((item) => item.checked)?.pgr_desc ===
              data.pgr_desc) &&
          (data1CheckBox.every((item) => !item.checked) ||
            data1CheckBox.find((item) => item.checked)?.level1 ===
              data.level1) &&
          (data2CheckBox.every((item) => !item.checked) ||
            data2CheckBox.find((item) => item.checked)?.level2 ===
              data.level2) &&
          (data3CheckBox.every((item) => !item.checked) ||
            data3CheckBox.find((item) => item.checked)?.level3 ===
              data.level3) &&
          (data4CheckBox.every((item) => !item.checked) ||
            data4CheckBox.find((item) => item.checked)?.level4 === data.level4)
        );
      });
    }

    setTableData(filteredData);
    setShowTable(true);
  };

  //GS1
  useEffect(() => {
    // Check if lclMaterialNo exists and is not null
    if (lclMaterialNo) {
      setMaterial(lclMaterialNo);
      handleButtonClick();
    }
  }, [lclMaterialNo, handleButtonClick]);

  //Material Search : Open modal on click
  const handleOpenModal = async (id) => {
    try {
      setSelectedRowId(id);
      const completeData = await fetchRowData(id);
      setCompleteData(completeData);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddImage = (materialNo) => {
    console.log("materialNoooooooooooooo", materialNo);
    window.location.href = `/warehouse/image-upload?mat_no=${materialNo}`;
  };

  const clearSelection = async () => {
    setModalOpen(false);
    setSelectedRowId(""); // Assuming setSelectedRowId is the correct function to clear selectedRowId
  };

  return {
    dataCheckBox,
    data1CheckBox,
    data2CheckBox,
    data3CheckBox,
    data4CheckBox,
    showTable,
    tableData,
    modalOpen,
    error,
    handlePurchasingGroup,
    handleLevel1,
    handleLevel2,
    handleLevel3,
    handleLevel4,
    resetPage,
    handleResetPgr,
    handleResetLevel1,
    handleResetLevel2,
    handleResetLevel3,
    handleImageClick,
    handleChange,
    addToCart,
    handleButtonClick,
    handleOpenModal,
    material,
    setMaterial,
    globalMaterial,
    setGlobalMaterial,
    materialDesc,
    setMaterialDesc,
    manufName,
    setManufName,
    manufNo,
    setManufNo,
    equipFunc,
    setEquipFunc,
    binloc,
    setBinLoc,
    setModalOpen,
    quantity,
    fetchSpBasketAll,
    setOpenCarousel,
    openCarousel,
    clickedImageIndex,
    sliderImages,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    handleAddImage,
    handleResetLevel4,
    isLoading,
    setSearchQuery,
    searchQuery,
    selectedRowId,
    clearSelection,
    fetchSpDataAll,
  };
};

export default MaterialSearchVM;
