import AxiosWrapper from "../../base/AxiosWrapper";
import Logger from "../../base/Logger";

const REACT_BE_URL = process.env.REACT_APP_BE_URL;
const REACT_ENVI = process.env.REACT_APP_ENV;
const {
  axiosPostCall,
  axiosGetCallWithToken
} = AxiosWrapper;

const verificationUser = async (email) => {
  let response;
  try {
    const formD = {
      "envi": REACT_ENVI,
      "email": email,
    };
    response = await axiosPostCall(`${REACT_BE_URL}/users/verification`, formD);
  } catch (error) {//401, 403
    let sendError = error;
    if (sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined) {
      sendError = new Error(sendError.response.data.message);
      //console.log("Throwing error from catch1 ", sendError);
    } else {
      sendError = new Error("Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance.");
    }
    //console.log("Throwing error from catch2 ", error.message);
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined || response.data === undefined) {
    //console.log("Throwing error from  response undefined ");
    throw new Error("No response");
  }
  if ((response.status >= 200 && response.status <= 202) &&
    (response.data.status === undefined || response.data.status === 200)) {
    //console.log("Throwing error from 200 response ", response.data.status);
    return response.data;
  } else {
    //console.log("Throwing error from non 200 ");
    throw new Error(response.data.message);
  }
};

const loginUser = async (email) => {
  let response;
  try {
    const formD = {
      "envi": REACT_ENVI, "email": email,
    };
    response = await axiosPostCall(`${REACT_BE_URL}/users/login`, formD);
  } catch (error) {
    let sendError = error;
    if (sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined) {
      sendError = new Error(sendError.response.data.message);
      //console.log("Throwing error from catch1 ", sendError);
    } else {
      sendError = new Error("Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance.");
    }
    //console.log("Throwing error from catch2 ", error.message);
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined || response.data === undefined) {
    //console.log("Throwing error from  response undefined ");
    throw new Error("No response");
  }
  if ((response.status >= 200 && response.status <= 202) &&
    (response.data.status === undefined || response.data.status === 200)) {
    //console.log("Throwing error from 200 response ", response.data.status);
    return response.data;
  } else {
    //console.log("Throwing error from non 200 ");
    throw new Error(response.data.message);
  }
};

const verifyLogin = async () => {
  return await axiosGetCallWithToken(`${REACT_BE_URL}/users/verifyLogin`);
    
};


export {
  verificationUser,
  loginUser,
  verifyLogin
};


