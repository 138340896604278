export const LogLevel = {
  i: 1,
  e: 2,
  w: 3,
  a: 4,
  d: 5,
};

class Logger {

  static log(msg) {
    console.log(`${msg}`);
  }

  static printStackTrace(error) {
    console.error(error);
    console.trace();
  }

  static messageStackTrace(message, error) {
    console.error(message, error);
    console.trace();
  }

  static shouldLog(level, requiredLevel) {
    return level >= requiredLevel;
  }

  static logIfLevel(level, requiredLevel, msg) {
    if (Logger.shouldLog(level, requiredLevel)) {
      Logger.log(msg);
    }
  }

  static i(level, msg) {
    Logger.logIfLevel(level, LogLevel.i, msg);
  }

  static e(level, msg) {
    Logger.logIfLevel(level, LogLevel.e, msg);
  }

  static w(level, msg) {
    Logger.logIfLevel(level, LogLevel.w, msg);
  }

  static a(level, msg) {
    Logger.logIfLevel(level, LogLevel.a, msg);
  }

  static d(level, msg) {
    Logger.logIfLevel(level, LogLevel.d, msg);
  }

}

export default Logger;
