import React from "react";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useParams } from "react-router-dom";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CCarousel from "../../../../basecomp/components/CCarousel";
import CText from "../../../../basecomp/components/CText";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import HistoricalJobDetailsVM from "../viewmodel/HistoricalJobDetailsVM";

const HistoricalJobDetails = ({ value, data }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const jobIdFromUrl = urlParams.get("job_id");
  const id = jobIdFromUrl || "";

  const {
    jobDetails,
    page,
    rowsPerPage,
    modalOpen,
    clickedImageIndex,
    sliderImages,
    searchQuery,
    onButtonClick,
    handleImageClick,
    handleSaveData,
    handleOpenModal,
    handleChangePage,
    handleChangeRowsPerPage,
    handleAddToCart,
    handleCloseModal,
    handleQuantityChange,
    quantity,
    error,
    spDetails,
    index,
    setOpenCarousel,
    openCarousel,
    handleAddImage,
    setSearchQuery,
    isLoading,
  } = HistoricalJobDetailsVM(id);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Card sx={{ padding: "2em" }}>
              <Grid container style={{ margin: "1em" }}>
                <Grid item xs={12} sm={12} md={2.3}>
                  <CText
                    cText={
                      <>
                        <strong>Job Card No :</strong> {jobDetails?.id}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3.2}>
                  <CText
                    cText={
                      <>
                        <strong>Equipment Type : </strong>{" "}
                        {jobDetails?.job_directive}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <CText
                    cText={
                      <>
                        <strong>Job Directive : </strong>{" "}
                        {jobDetails?.equipment_type}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={1.5}>
                  <CText
                    cText={
                      <>
                        <strong>Section : </strong>{" "}
                        {jobDetails?.section ? jobDetails?.section : ""}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <CText
                    cText={
                      <>
                        <strong>Start Date/Time : </strong>{" "}
                        {jobDetails?.start_date_time
                          ? jobDetails?.start_date_time
                          : ""}
                      </>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container style={{ margin: "1em" }}>
                <Grid item xs={12} sm={6} md={2.3}>
                  <CText
                    cText={
                      <>
                        <strong>Reference No : </strong>{" "}
                        {jobDetails?.reference_no
                          ? jobDetails?.reference_no
                          : ""}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3.2}>
                  <CText
                    cText={
                      <>
                        <strong>Job Type : </strong>{" "}
                        {jobDetails?.job_type ? jobDetails?.job_type : ""}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <CText
                    cText={
                      <>
                        <strong>Equipment ID : </strong>{" "}
                        {jobDetails?.equipment_id}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1.5}>
                  <CText
                    cText={
                      <>
                        <strong>Resources : </strong> {jobDetails?.resources}
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CText
                    cText={
                      <>
                        <strong>Finish Date/Time : </strong>{" "}
                        {jobDetails?.finish_date_time
                          ? jobDetails?.finish_date_time
                          : ""}
                      </>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container style={{ margin: "1em" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <CText
                    cText={
                      <>
                        <strong>Work Description : </strong>{" "}
                        {jobDetails?.detail ? jobDetails?.detail : ""}
                      </>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={11.9}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0.5em",
                  }}>
                  <div>
                    <Tooltip title="Add all materials to basket">
                      <IconButton
                        id="add_to_basket"
                        style={{
                          backgroundColor:
                            spDetails && spDetails.length === 0
                              ? "#E0E0E0"
                              : "#13A538",
                          color: "white",
                          borderRadius: 4,
                          height: "1.1em",
                          width: "1.4em",
                          marginLeft: "0.4em",
                          pointerEvents:
                            spDetails && spDetails.length === 0
                              ? "none"
                              : "auto",
                        }}
                        onClick={handleAddToCart}>
                        <ShoppingCartIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div>
                    <Tooltip title="Download Pdf">
                      <IconButton
                        id="download_pdf"
                        style={{
                          backgroundColor: "#13A538",
                          color: "white",
                          borderRadius: 4,
                          height: "1.1em",
                          width: "1.4em",
                        }}
                        onClick={onButtonClick}>
                        <CloudDownloadOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  style={{ textAlign: "right", paddingRight: "0.5em" }}>
                  <Input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div style={{ overflowX: "auto" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <ShoppingCartOutlinedIcon
                            style={{ marginLeft: "0.3em" }}
                          />
                        </TableCell>
                        <TableCell>
                          <CameraAltIcon style={{ marginLeft: "0.3em" }} />
                        </TableCell>

                        <TableCell>
                          <b>OEM No.</b>
                        </TableCell>
                        <TableCell>
                          <b>OEM Description</b>
                        </TableCell>
                        <TableCell>
                          <b>Notes</b>
                        </TableCell>
                        <TableCell>
                          <b>Global Material No.</b>
                        </TableCell>
                        <TableCell>
                          <b>Material</b>
                        </TableCell>
                        <TableCell>
                          <b>Description</b>
                        </TableCell>
                        <TableCell>
                          <b>QTY</b>
                        </TableCell>
                        <TableCell>
                          <b>UOM</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {spDetails && spDetails.length > 0 ? (
                        spDetails
                          .filter((data) => {
                            // Convert all column values to lowercase for case-insensitive search
                            const values = Object.values(data).map((value) =>
                              String(value).toLowerCase()
                            );
                            // Check if any column value contains the search query
                            return values.some((value) =>
                              value.includes(searchQuery.toLowerCase())
                            );
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((data) => (
                            <TableRow
                              key={data.id}
                              style={{
                                backgroundColor: data.material_no
                                  ? "#00CC00"
                                  : "#E0E0E0",
                              }}>
                              <TableCell>
                                <IconButton
                                  id="add_single_record"
                                  color="black"
                                  onClick={() => handleOpenModal(data)}>
                                  <ShoppingCartOutlinedIcon />
                                </IconButton>
                              </TableCell>

                              <TableCell>
                                {data.image_link.length === 0 ||
                                data.image_link === undefined ? (
                                  <IconButton
                                    id="add_image"
                                    onClick={() =>
                                      handleAddImage(data.material_no)
                                    }>
                                    <CameraAltIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    id="display_image"
                                    onClick={() =>
                                      handleImageClick(data.image_link, index)
                                    }>
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell>{data.oem}</TableCell>
                              <TableCell>{data.oem_description}</TableCell>
                              <TableCell>{data.notes}</TableCell>
                              <TableCell>{data.global_material_no}</TableCell>
                              <TableCell>{data.material_no}</TableCell>
                              <TableCell>{data.global_material_desc}</TableCell>
                              <TableCell>{data.quantity}</TableCell>
                              <TableCell>{data.uom}</TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={10}>No data found</TableCell>
                        </TableRow>
                      )}
                      {openCarousel && (
                        <CCarousel
                          cOpen={openCarousel}
                          images={sliderImages}
                          currentIndex={clickedImageIndex || 0} // Provide a default value if clickedImageIndex is undefined
                          cClose={() => setOpenCarousel(false)}
                        />
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={spDetails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Grid>

              <Dialog
                id="edit_qty"
                open={modalOpen}
                PaperProps={{
                  style: {
                    marginBottom: "25em",
                    maxWidth: "23em",
                    width: "100%",
                  },
                }}>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                <AppBar
                  position="static"
                  style={{ backgroundColor: "#F7A941", alignItems: "center" }}>
                  <Toolbar>
                    <ShoppingCartOutlinedIcon />
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <div className="modal-body">
                    <Typography
                      style={{
                        color: "#1976d2",
                        marginBottom: "1em",
                      }}>
                      QTY
                    </Typography>
                    <TextField
                      id="standard-basic"
                      fullWidth
                      value={quantity}
                      onChange={handleQuantityChange}
                      variant="standard"
                    />
                    {error && <span>{error}</span>}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    id="save_data"
                    style={{ color: "#1976d2" }}
                    onClick={() => handleSaveData(data, quantity)}>
                    OK
                  </Button>
                  <Button
                    id="close_modal"
                    onClick={() => handleCloseModal()}
                    style={{ color: "#1976d2" }}>
                    CANCEL
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HistoricalJobDetails;
