import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CInput from "../../../basecomp/components/CInput";
import ImageUploadVM from "../viewmodel/ImageUploadVM";
import CButton from "../../../basecomp/components/CButton";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../ImageComponent/DeleteModal";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CBackDrop from "../../../basecomp/components/CBackDrop";

const ImageView = () => {
  const {
    materialReq,
    setMaterialReq,
    handleFileChange,
    handleSubmit,
    inputBoxValueName,
    previewImages,
    loadingData,
    loadingImages,
    handleConfirmDelete,
    handleCloseModal,
    handleDeleteClick,
    openModal,
    getImageName,
    adminImageAccess,
    fileSelected,
    loading,
  } = ImageUploadVM();

  return (
    <>
      <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
        <Card sx={{ padding: "2em" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <CInput
                id="material_req"
                cHintText={inputBoxValueName}
                cVariant="outlined"
                cValue={materialReq}
                cOnChange={(e) => setMaterialReq(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} paddingLeft={2}>
              <CInput
                cId="image-id"
                cType="file"
                cInputProps={{ shrink: true }}
                cinputProps={{ multiple: true }}
                cOnChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1.5}
              paddingLeft={2}
              paddingBottom={3}
            >
              <CButton
                cText="Submit"
                cTooltipText="Submit the action"
                cDisabled={loading}
                cOnClick={handleSubmit}
              />
            </Grid>
          </Grid>
          {previewImages.length === 5 && (
            <p style={{textAlign:'center'}}>Maximum you can upload 5 material images</p>
          )}
          
          <CBackDrop cOpen={loading} />
          {!loadingData && materialReq.length >= 5 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {loadingImages && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              {!loadingImages && (
                <>
                  {previewImages.length === 0 ? (
                    <p style={{ width: "100%", textAlign: "center" }}>
                      Material images are not present
                    </p>
                  ) : (
                    previewImages.map((imageUrl, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          width: "250px",
                          height: "250px",
                          margin: "10px",
                          overflow: "hidden",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                      >
                        {adminImageAccess === 1 && (
                          <IconButton
                            aria-label="delete"
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              zIndex: "1",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          >
                            <DeleteIcon
                              style={{ color: "green" }}
                              onClick={() => handleDeleteClick(imageUrl)}
                            />
                          </IconButton>
                        )}
                        <DeleteModal
                          open={openModal}
                          onClose={handleCloseModal}
                          onConfirm={handleConfirmDelete}
                          cFileName={getImageName(imageUrl)}
                        />
                        <img
                          src={imageUrl}
                          alt={`${index + 1}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))
                  )}

                  {previewImages.length < 5 && previewImages.length !== 0 && (
                    <div
                      style={{
                        width: "250px",
                        height: "250px",
                        margin: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: fileSelected === 0 ? "210px" : "165px",
                          border: "1px dashed #ccc",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          component="span"
                          onClick={() => {
                            const fileInput =
                              document.getElementById("image-id");
                            if (fileInput) {
                              fileInput.click();
                            }
                          }}
                        >
                          <AddAPhotoIcon />
                        </IconButton>
                      </div>
                      {fileSelected > 0 && (
                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                          {fileSelected} files selected{" "}
                        </p>
                      )}

                      <div style={{ marginTop: "10px" }}>
                        <CButton
                          cText="Upload"
                          cTooltipText="Submit the action"
                          cDisabled={loading} 
                          cOnClick={handleSubmit}
                          CFullWidth={true}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default ImageView;
