import React, { useEffect, useState } from "react";
import { checkAssetType } from "../model/GSMainPage";
import Logger from "../../../basecomp/base/Logger";

function useGsOneMainPageViewModel() {
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const asset_id = searchParams.get("asset_id");
  const [spareMat, setSpareMat] = useState(null);
  const [fuelId, setFuelId] = useState(null);

  const [registrationPage, setRegistrationPage] = useState("");

  useEffect(() => {
    assetCheck();
  }, [asset_id]);

  const assetCheck = async() => {
    try {
      const response = await checkAssetType(asset_id);
      const detailsAsString = response.data.details;

      // Manually extract values using regular exp as it is in string fromat
      const typeMatch = /type:\s*([^,]+)/.exec(detailsAsString);
      const materialNoMatch = /material_no:\s*([^,]+)/.exec(detailsAsString);
      const fuelAssetIdMatch = /fuel_asset_id:\s*([^,]+)/.exec(
        detailsAsString
      );

      const type = typeMatch ? typeMatch[1].trim() : undefined;
      const materialNo = materialNoMatch
        ? materialNoMatch[1].trim()
        : undefined;
      const fuelAssetId = fuelAssetIdMatch
        ? fuelAssetIdMatch[1].trim()
        : undefined;

      if (type === "F") {
        setRegistrationPage("Fuel");
        if (fuelAssetId) {
          setFuelId(fuelAssetId);
        }
      } else if (type === "S") {
        setRegistrationPage("Spare");
        if (materialNo) {
          setSpareMat(materialNo);
        }
      }
    } catch (error) {
      Logger.printStackTrace(error);
    };
  };

  return { registrationPage, spareMat, fuelId };
}

export default useGsOneMainPageViewModel;
