import React, { useState, useEffect } from "react";
import CInput from "./CInput";

const CDateTimePicker = ({
  cId,
  cHelperText,
  cTooltipText,
  cDisabled,
  cOnChange,
  resetKey,
  cIsSession,
  hideTime, // New prop to conditionally hide time
}) => {
  const getInitialDateTime = () => {
    return ""; // Return an empty string
  };

  const [selectedDateTime, setSelectedDateTime] = useState(
    getInitialDateTime()
  );

  // Check if session storage should be used to populate the value
  useEffect(() => {
    if (cIsSession) {
      const sessionItem = sessionStorage.getItem(cId);
      if (sessionItem) {
        setSelectedDateTime(sessionItem);
      }
    }
  }, [cIsSession, cId]);

  const handleDateTimeChange = (e) => {
    const updatedValue = e.target.value || "";
    setSelectedDateTime(updatedValue);
    cOnChange(updatedValue);
    sessionStorage.setItem(cId, updatedValue);
  };

  useEffect(() => {
    // Reset the date time when the resetKey changes
    if (resetKey) {
      setSelectedDateTime(getInitialDateTime());
    }
  }, [resetKey]);

  return (
    <CInput
      id={cId}
      key={resetKey}
      cTooltipText={cTooltipText}
      cDisabled={cDisabled}
      cHelperText={cHelperText}
      cType={hideTime ? "date" : "datetime-local"} // Conditionally set type
      cOnChange={handleDateTimeChange}
      cValue={selectedDateTime}
    />
  );
};

export default CDateTimePicker;
