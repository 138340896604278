import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CButton from "../../../basecomp/components/CButton";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import AvTimerIcon from "@mui/icons-material/AvTimer";

const OrderBox = styled("div")({
  textAlign: "start",
  border: "1px solid #3CB043",
  width: "100%",
  margin: "2rem 0em",
  borderRadius: "1.2em 1.2em 1em 1em",
});

const OrderBoxText = styled("div")({
  textAlign: "center",
  backgroundColor: "#3CB043",
  color: "white",
  padding: ".5em 3em",
  borderRadius: "0.7em 0.7em 0em 0em",
  fontSize: "1.2em",
});
const OrderBoxBtn = styled("div")({
  padding: "2em 3em",
  display: "flex",
  justifyContent: "center",
});

function FuelAssetInfoView() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const asset_id =
    decodeURIComponent(searchParams.get("asset_id")) !== "null"
      ? decodeURIComponent(searchParams.get("asset_id"))
      : "";

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // State to track modal type

  const [assetData, setAssetData] = useState([
    ["Asset ID", asset_id],
    ["Equipment Type", "MHC"],
    ["Issue From Tank", "CAR TANK LP"],
    ["Plant Name", 4300],
    ["Equipment Number", "MHC01"],
    ["Equipment Capacity", 7850],
    ["Fuel (in liters)", 120],
    ["Running Hours", 16417],
  ]);

  sessionStorage.setItem(
    "logingsoneurl",
    `/warehouse/fuel-asset-info?asset_id=${asset_id}`
  );
  useEffect(() => {}, []);

  // Single function to open the modal
  const handleOpenModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType('');
  };

  return (
    <>
      <Box sx={{ margin: "6em 1em 0.5em 1em" }}>
        <OrderBox>
          <OrderBoxText>General Info</OrderBoxText>
          <Box sx={{ padding: { xs: "0.5em", sm: "2em" } }}>
            {assetData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: "1.5em",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    minWidth: "170px",
                    marginRight: { xs: "0.5em", sm: "1em" },
                    wordWrap: "break-word",
                  }}
                >
                  {item[0]}
                </Typography>

                <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                  {item[1]}
                </Typography>
              </Box>
            ))}
          </Box>
        </OrderBox>
        <Grid item sx={{ marginTop: "3em" }}>
          <OrderBox>
            <OrderBoxText>Fuel / Rh</OrderBoxText>
            <OrderBoxBtn>
              <CButtonGroup>
                <CButton
                  cId="fuel"
                  cTooltipText="Add Fuel (in liters)"
                  cSrc={<LocalGasStationIcon />}
                  cOnClick={() => handleOpenModal("fuel")} 
                />
                <CButton
                  cId="kms"
                  cTooltipText="Add Running hours (Rh)"
                  cSrc={<AvTimerIcon />}
                  cOnClick={() => handleOpenModal("kms")}
                />
              </CButtonGroup>
            </OrderBoxBtn>
          </OrderBox>
        </Grid>
      </Box>

      {/* Modal Implementation */}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            borderRadius: "1em", 
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#3CB043",
            color: "white",
            textAlign: "center",
            marginBottom: ".5em",
            borderRadius: ".5em .5em 0 0", 
          }}
        >
          {modalType === "fuel" ? "Add Fuel (in liters)" : "Add Running Hours (Rh)"}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            fullWidth
            variant="standard"
            label={
              modalType === "fuel"
                ? "Fuel Quantity (in liters)"
                : "Running Hours (Rh)"
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ color: "#1976d2" }}>
            ADD
          </Button>
          <Button onClick={handleCloseModal} style={{ color: "#1976d2" }}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FuelAssetInfoView;
