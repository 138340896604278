import React from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useToast } from "./provider/ToastProvider";
import CSnackbar from "./CSnackbar";

const CToast = ({ cHeader }) => {
  const { toastObj, handleToastClose } = useToast();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleToastClose();
  };

  if (toastObj.open === true) {
    if (toastObj.isMaintenance || toastObj.isOutdated) {
      return <CSnackbar />;
    } else {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={toastObj.open}
          autoHideDuration={5000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleClose }}
        >
          <Alert severity={toastObj.type}>
            <AlertTitle>{cHeader}</AlertTitle>
            {toastObj.message}
          </Alert>
        </Snackbar>
      );
    }
  }
  return null;
};

export default CToast;
