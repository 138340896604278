import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from '@mui/material/Tooltip';
import CButton from "./CButton2";
import CInput from "./CInput2";
import CText from "./CText";


const StyledTableCell = styled(TableCell)(({ theme, cColor }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#fff",
        color: theme.palette.common.black,
        border: "1px solid black",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const DEFAULT_PAGE_SIZE = 10;

const CTable2 = ({
    cHeaderData,
    cRowData,
    cActualData,
    cTooltipText,
    cIsPagination,
    cSmallSize,
}) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGE_SIZE);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleCellData = (rowData, actualRow, rowIndex) => {
        let cellContent;
        let rowDataType = rowData.type;
        if (rowDataType === "M") {
            rowDataType = rowData.actualType(actualRow);
        }
        if (rowDataType === "T") {
            if (rowData.tooltipText) {
                cellContent = <Tooltip title={rowData.tooltipText(actualRow)}>
                    <span>
                        <CText cText={actualRow[rowData.value]} />
                    </span>
                </Tooltip>
            } else {
                cellContent = <CText cText={actualRow[rowData.value]} />
            }
        } else if (rowDataType === "B") {
            cellContent = <CButton
                cText={rowData.value}
                cOnClick={() => rowData.actionV(actualRow)}
                cDisabled={rowData.isDisable(actualRow)}
            />;
        } else if (rowDataType === "I" || rowDataType === "IC") {
            let isShow = true;
            if (rowData.status !== undefined && actualRow[rowData.status] === 0) {
                isShow = false;
            }
            if (isShow) {
                cellContent = <CButton
                    cSrc={
                        rowDataType === "IC" ? rowData.value(actualRow) : rowData.value
                    }
                    cTooltipText={
                        rowDataType === "IC" ? rowData.tooltipText(actualRow) : rowData.tooltipText
                    }
                    cOnClick={() => rowData.actionV(actualRow, (page * rowsPerPage) + rowIndex)}
                />;
            }
        } else if (rowDataType === "E") {
            cellContent = <CInput
                cHintText=" "
                cValue={actualRow[rowData.value]}
                cOnChange={rowData.actionV}
                cType={rowData.eType}
            />;
        }
        return cellContent;
    }

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                {/* sx={{ maxHeight: 440 }} */}
                <Table
                    aria-label={cTooltipText}
                    size={cSmallSize === true ? "small" : "inherit"}
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            {cHeaderData.map((headData, headIndex) => (
                                <StyledTableCell key={headIndex}>{headData}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cActualData.length > 0 ? (
                            cActualData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((actualRow, rowIndex) => (
                                    <TableRow
                                        key={rowIndex}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        {cRowData.map((rowData, cellIndex) => (
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                key={cellIndex}
                                                sx={{
                                                    color:
                                                        rowData.status === undefined
                                                            ? "inherit" :
                                                            actualRow[rowData.status] === 0
                                                                ? "red"
                                                                : "green"
                                                    ,
                                                }}
                                            >
                                                {handleCellData(rowData, actualRow, rowIndex)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={cHeaderData.length}
                                    sx={{ textAlign: "center" }}
                                >
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {cIsPagination && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={cActualData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
};



export default CTable2;