import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const { axiosGetCallWithToken, axiosGetCallWithTokenHeader } = AxiosWrapper;

//Job Details: Get distinct type and section for dropdown in job details page (Job Type)
export const getJobDetail = async () => {
  try {
    const url = `${Base_URL}/dcatalogue/getJobDetails`;

    const response = await axiosGetCallWithToken(url);
    return response.data.data;
  } catch (error) {
    Logger.messageStackTrace("Error getting Details:", error);
  }
};

//Job Details: Fetch permission for eams and non eams user
export const getPermissionId = async () => {
  try {
    const url = `${Base_URL}/dcatalogue/getPermissionId`;

    const response = await axiosGetCallWithToken(url);
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error getting Details:", error);
  }
};

// D-Catalogue: Generate and download pdf
export const pdfDownload = async (
  jobId,
  equipmentId,
  jobDirective,
  jobType,
  section,
  resources,
  workDesc,
  externalRef,
  startDate,
  finishDate,
  type
) => {
  try {
    const url = `${Base_URL}/dcatalogue/getPdf?jobId=${jobId}&equipmentId=${equipmentId}&jobDirective=${jobDirective}&jobType=${jobType}&section=${section}&resources=${resources}&workDesc=${workDesc}&externalRef=${externalRef}&startDate=${startDate}&finishDate=${finishDate}&type=${type}`;
    return await axiosGetCallWithTokenHeader(url);
  } catch (error) {}
};

// D-Catalogue: Generate and non-eams download pdf
export const nonEamsPdfDownload = async (
  jobId,
  equipmentType,
  equipmentId,
  jobDirective,
  jobType,
  section,
  resources,
  workDesc,
  externalRef,
  startDate,
  finishDate,
  type
) => {
  try {
    const url = `${Base_URL}/dcatalogue/getPdf?jobId=${jobId}&equipmentType=${equipmentType}&equipmentId=${equipmentId}&jobDirective=${jobDirective}&jobType=${jobType}&section=${section}&resources=${resources}&workDesc=${workDesc}&externalRef=${externalRef}&startDate=${startDate}&finishDate=${finishDate}&type=${type}`;
    return await axiosGetCallWithTokenHeader(url);
  } catch (error) {}
};
