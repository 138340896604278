export const MaterialNumberRequired =
  "Requested material number cannot be empty";

export const MaxImageSelect = "You can only select up to 5 images";
export const MaxImageUpload = "You can only upload up to 5 images";

export const TerminalNotFound = "Terminal Not Found";

export const MaterialImagesUploaded = "Material Images Uploaded successfully";

export const MaterialNotPresentMessage = "Material Is Not Present";

export const ImageRequired = "Please select the images";
export const Environment = "Local environment does not support image upload!";
export const MaterialImageDeleted="Image Deleted Successfully"

//image size
export const maxSizePerFile = 5 * 1024 * 1024;
// export const maxTotalSize = 32 * 1024 * 1024;
export const imageSize = "Maximum 5MB file size exceeded for an image";

// export const maxImgSize =
//   "Maximum total size of 25MB exceeded for selected images";
