import React from "react";
import { Button, Modal, Typography, Box } from "@mui/material";

const DeleteModal = ({ open, onClose, onConfirm, cFileName }) => {
  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const paperStyle = {
    backgroundColor: "#ffffff",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "20px",
    textAlign: "center",
    borderRadius: "5px",
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center"
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      style={modalStyle}
    >
      <Box sx={{ ...paperStyle, width: "400px", height: "200px" }}>
        <Typography variant="h6" gutterBottom>
          Are you sure you want to permanently delete {cFileName}?
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}
            style={{ marginRight: "20px" }}
          >
            Confirm
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
