import React, { useState } from "react";
import { Grid, Typography, Card } from "@mui/material";
import NavUtils from "./NavUtils";

const ServicesPage = ({ services, linkComponent: Link }) => {

  const { getServiceItems } = NavUtils();

  return (

    <Grid container spacing={5} sx={{ justifyContent: "center", marginTop: "6em" }}>
      {getServiceItems(services).map((item) => (
        <Grid item xs={10} sm={5} md={3.8} lg={3.8} key={item.key}>
          <Card
            className="slide-up-animation delay-1"
            variant="outlined"
            sx={{ borderRadius: "1em 1em 0 0" }}
          >
            <Link to={item.isEnabled ? item.path : null}>
              <Grid className="image-container">
                <img
                  src={item.asset}
                  alt={item.asset}
                  style={{ filter: `grayscale(${item.isEnabled ? '0%' : '100%'})` }}
                />
              </Grid>
            </Link>
            <Grid sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              <Typography
                color="primary"
                fontWeight="bold"
                fontSize="1.2em"
                whiteSpace="nowrap"
                padding="1em"
              >
                {item.title}
              </Typography>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ServicesPage;