// import axios from "axios";
import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const {
  axiosPostCallWithToken,
  axiosGetCallWithToken,
  axiosGetCallWithTokenHeader,
} = AxiosWrapper;

//To get data from Job Id Catalogue for Historical Detail Page (based on terminal_id)
const fetchJobDetails = async (id) => {
  try {
    if (!id) {
      throw new Error("job_card_no is undefined or invalid");
    }
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getJobIdCatByJobNos?job_card_no=${id}`
    );
    const data = response.data;
    return data; // Make sure this returns the entire data object
  } catch (error) {
    Logger.messageStackTrace("Error fetching job details:", error);
  }
};

//Historical Job : Get all Sq01 details for filtering table (SQ01)
const getSq01All = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSq01All`
    );
    const data = response.data;
    return data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching Sq01 details:", error);
  }
};

//Historical Detail : Generate new sp_basket id logic (Job Id Catalogue)
const getSpBasketID = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getSpBasketID`
    );

    return response;
  } catch (error) {
    Logger.messageStackTrace("Error getting SpBasket ID: ", error);
  }
};

// Historical Detail: Insert or Add data in sp_basket (Sp Basket)
const postSpBasket = async (result, handleToastOpen) => {
  try {
    const response = await axiosPostCallWithToken(
      `${Base_URL}/dcatalogue/addSpBasket`,
      result
    );
    return response;
  } catch (error) {
    const errors = error.response.data.errors;
    // Check if the length of result matches the length of modellll
    const isLengthMatched = result.length === errors.length;

    if (isLengthMatched) {
      handleToastOpen("error", "All Records already present!");
    } else if (!isLengthMatched) {
      handleToastOpen("success", "Data added successfully!");
    }

    Logger.messageStackTrace("Error posting SpBasket details:", error);
  }
};

//D-Catalogue (most used): Fetch merge result of spbasket and ictsi data (Sp Basket, Global Data, Material Images)
const getGlobalAndSpBasket = async (id) => {
  try {
    if (!id) {
      throw new Error("job_card_no is undefined or invalid");
    }
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSpAndGlobal?jobid=${id}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching job details:", error);
  }
};

//D-Catalogue : Generate and download pdf
const pdfDownload = async (jobId, type) => {
  try {
    return await axiosGetCallWithTokenHeader(
      `${Base_URL}/dcatalogue/getPdf?jobId=${jobId}&type=${type}`
    );
  } catch (error) {}
};

export {
  fetchJobDetails,
  getSq01All,
  getSpBasketID,
  postSpBasket,
  getGlobalAndSpBasket,
  pdfDownload,
};
