import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import { replenishmentEmail } from "../model/ReplenishmentModel";
import { useState } from "react";

const ReplenishmentVM = (props) => {
  const {
    spDetails,
    jobCardNumber,
    equipmentID,
    jobDirective,
    equipmentType,
    jobType,
  } = props;

  const equipmentId = equipmentID?.equipment_number;

  const [isLoading, setIsLoading] = useState(false);

  //Job Details : Validations
  const { handleToastOpen } = useToast();

  const isDisabled = !jobDirective || !equipmentType || !equipmentID;
  const handleIconButtonClick = async () => {
    if (spDetails.length === 0 || isDisabled) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
    } else {
      setIsLoading(true); // Start the loader
      try {
        const type = "replenishment_mail";
        const response = await replenishmentEmail(
          jobCardNumber,
          equipmentId,
          jobDirective,
          type
        );
        if (response.status === 200) {
          handleToastOpen("success", "Email sent successfully");
        } else {
          handleToastOpen("error", "Quantity must be greater than 0");
          console.error("Error sending email:", response.status);
        }
      } catch (error) {
        handleToastOpen("error", "An error occurred while sending email");
        console.error("Error sending email:", error);
      } finally {
        setIsLoading(false); // Stop the loader regardless of success or error
      }
    }
  };

  const mrpType = `SAP Material Requirement Planning Type:

  VB   Manual Reorder Point Planning (SAP will trigger PR/Planned orders based on manually set re-order point by the user

  ND   No (Demand) Planning; user needs to create PR manually

  PD  PR/PO trigger post quantity requested in workorder (lot for lot)`;

  const lotSize = `SAP lot size setting:

  FX      Fixed lot size

  HB      Replenishment to maximum stock level

  EX      Lot for lot order quantity

  VB/FX   Re-Order Point with Fixed Re-Order Quantity; trigger by MRP

  VB/HB   Re- Order Point to the maximum level; trigger by MRP

  PD/EX   Lot for Lot when QTY is added to a SAP EAMS Work order; trigger by MRP

  ND      Nothing happens; Create manually a PR`;

  return {
    spDetails,
    mrpType,
    lotSize,
    handleIconButtonClick,
    isLoading,
  };
};

export default ReplenishmentVM;
