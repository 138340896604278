import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useToast } from "./provider/ToastProvider";

const CSnackbar = () => {
  const { toastObj, handleToastClose } = useToast();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(toastObj.isOutdated || toastObj.isMaintenance);
  }, [toastObj.isOutdated, toastObj.isMaintenance]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleToastClose();
  };

  if (toastObj.isMaintenance) {
    setInterval(() => {
      window.location.reload();
    }, 40000);
  }

  const autoHideDuration = toastObj.isOutdated ? null : 42000;

  const snackbarStyle = {
    width: "25em",
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      style={snackbarStyle}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="info"
      >
        {toastObj.message}
      </Alert>
    </Snackbar>
  );
};

export default CSnackbar;
