import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CTable from "../../../basecomp/components/CTable";
import CText from "../../../basecomp/components/CText";
import CButton from "../../../basecomp/components/CButton";
import CDropdown from "../../../basecomp/components/CDropDown";
import CInput from "../../../basecomp/components/CInput";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";
import AddPrintingVM from "../viewmodel/AddPrintingVM";
import CLabelDropdownSelect from "../../../basecomp/components/CLabelDropdownSelect";
import { Label_Printing_Container, PdfPageStyle } from "../../assets/Constant";
import CCarousel from "../../../basecomp/components/CCarousel";
import { useNavigate } from "react-router-dom";
const AddPrintingDetails = () => {
  const {
    handleUpdateSave,
    EditCompDeleteApi,
    handleDeleteParticular,
    handleDelete,
    editHandle,
    handlePreview,
    handleSubmit,
    sizeOptions,
    selectedSize,
    quantity,
    setQuantity,
    purchaseOrder,
    materialNumber,
    renderEditComp,
    labelData,
    updatedMatNo,
    setUpdatedMatNo,
    updatedQuantityNo,
    setUpdatedQuantityNo,
    handleSizeChange,
    handlePurchaseOrderChange,
    handleMaterialNumberChange,
    poOptions,
    matOptions,
    handleUpdatedPoOptions,
    handleUpdatedMaterialOptions,
    viewImagePreview,
    openCarousel,
    setOpenCarousel,
    materialImages,
    handleUpdateBinLocationSave,
    handleBulkLocationSave,
    handlePreviewBePdf,
    handlePreviewClick,
    handlePreviewClickByMatNum,
    handlePreviewInTablePdf,
    handleDownloadAllLabels,
    notExistIds,
    existIds,
  } = AddPrintingVM();
  const navigate = useNavigate();

  const editHandleRow = (label) => {
    editHandle(label.id, label.material_no, label.print_qty, label.status);
  };
  const viewImageRow = (label) => {
    if (label.image_link === 0) {
      navigate(PdfPageStyle.imageUploadNav+"?mat_no="+label.material_no);
      sessionStorage.setItem("material_no", label.material_no);
    } else {
      viewImagePreview(label.id);
    }
  };

  const deleteHandleRow = (label) => {
    handleDeleteParticular(label.id);
  };

  const binLocationRow = (label) => {
    handleUpdateBinLocationSave(
      label.id,
      label.material_no,
      label.bin_location
    );
  };
  const bulkLocationRow = (label) => {
    handleBulkLocationSave(label.material_no, label.bulk_location);
  };

  const previewLabelPdfFunction = (label) => {
    handlePreviewClickByMatNum(label.material_no, label.po_no);
  };

  const previewLabelPdfInTableFunction = (label) => {
    handlePreviewInTablePdf(
      label.po_no,
      label.material_no,
      label.measurement_id,
      label.print_qty
    );
  };

  const [infoRowData, setInfoRowData] = useState([
    { value: `material_no`, status: true, type: "T" },
    { value: `print_qty`, status: true, type: "T" },
    {
      value: <EditIcon />,
      tooltipText: "Edit Row",
      status: true,
      type: "I",
      actionV: editHandleRow,
    },
    {
      value: <DeleteIcon />,
      tooltipText: "Delete Row",
      status: true,
      type: "I",
      actionV: deleteHandleRow,
    },

    {
      value: `bin_location`,
      tooltipText: "Bin Location Checking",
      status: true,
      type: "TE",
      actionV: binLocationRow,
    },
    {
      value: `image_link`,
      tooltipText: "Upload Material Images",
      type: "IL",
      actionV: viewImageRow,
    },
    {
      value: `bulk_location`,
      tooltipText: "Click Here to Inspect this Material",
      status: true,
      type: "TE",
      actionV: bulkLocationRow,
    },
    {
      value: `status`,
      tooltipText: "Click Here to Preview",
      status: false,
      type: "VR",
      actionV: previewLabelPdfFunction,
    },
    {
      value: `status`,
      tooltipText: "Click Here to Print",
      status: false,
      type: "PR",
      actionV: previewLabelPdfInTableFunction,
    },
  ]);

  const infoHeadData = [
    "Material Number",
    "Print Quantity",
    "Edit",
    "Delete",
    "Bin Location",
    "Images",
    "Bulk Location",
    "Preview",
    "Print",
  ];
  return (
    <>
      <Grid style={Label_Printing_Container.Main_Container}>
        {Object.keys(poOptions).length !== 0 ? (
          <Box style={Label_Printing_Container.Box_container}>
            <CInput
              cHintText="Purchase Order"
              cValue={purchaseOrder}
              cOnChange={handlePurchaseOrderChange}
            />
            <CLabelDropdownSelect
              value={purchaseOrder}
              options={poOptions.map((data) => data.purchase_order)}
              onChange={handleUpdatedPoOptions}
            />
          </Box>
        ) : (
          <CInput
            cHintText="Purchase Order"
            cValue={purchaseOrder}
            cOnChange={handlePurchaseOrderChange}
            fullWidth
          />
        )}

        {Object.keys(matOptions).length !== 0 ? (
          <Box style={Label_Printing_Container.Box_container}>
            <CInput
              cHintText="Material Number"
              cValue={materialNumber}
              cOnChange={handleMaterialNumberChange}
            />
            <CLabelDropdownSelect
              value={materialNumber}
              options={matOptions.map((data) => data.material_no)}
              onChange={handleUpdatedMaterialOptions}
            />
          </Box>
        ) : (
          <CInput
            cHintText="Material Number"
            cValue={materialNumber}
            cOnChange={handleMaterialNumberChange}
            fullWidth
          />
        )}
        <CDropdown
          id="size"
          cHelperText="Size"
          cValue={selectedSize}
          cData={sizeOptions ? sizeOptions.map((data) => data.name) : []}
          cOnChange={handleSizeChange}
          cOptions={sizeOptions.map((data) => data.name)}
          fullWidth
        />
        <CInput
          cHintText="Quantity"
          cValue={quantity}
          cOnChange={(e) => setQuantity(e.target.value)}
          fullWidth
        />
<CButtonGroup>
          <CButton
            cText="Add"
            cTooltipText="Add the label"
            cOnClick={handleSubmit}
          ></CButton>
          <CButton
            cText="Delete All"
            cTooltipText="Delete all the previous labels"
            cOnClick={handleDelete}
          ></CButton>
          <CButton
            cText="Print"
            cTooltipText="Print the label"
            cOnClick={handlePreviewBePdf}
          ></CButton>
          <CButton
            cText="view"
            cTooltipText="view the label"
            cOnClick={handlePreviewClick}
          ></CButton>
          <CButton
            cText="Print all"
            cTooltipText="print all the labels"
            cDisabled={notExistIds.length > 0 || existIds.length === 0}
            cOnClick={handleDownloadAllLabels}
          ></CButton>
        </CButtonGroup>

        {labelData.length > 0 && (
          <>
            <CTable
              id="label_info_table"
              cHeaderData={infoHeadData}
              cRowData={infoRowData}
              cActualData={labelData}
              cIsPagination={true}
              cSmallSize={true}
              cColor="#2196f3"
            />
            {openCarousel && (
              <CCarousel
                cOpen={openCarousel}
                images={materialImages}
                cClose={() => setOpenCarousel(false)}
              />
            )}
          </>
        )}

        {renderEditComp ? (
          <Grid>
            <CInput
              cHintText="Material Number"
              cValue={updatedMatNo}
              cOnChange={(e) => {
                setUpdatedMatNo(e.target.value);
              }}
            />
            <CInput
              cHintText="Quantity"
              cValue={updatedQuantityNo}
              cOnChange={(e) => setUpdatedQuantityNo(e.target.value)}
            />
            <CButton
              cSrc={<SaveIcon />}
              cTooltipText="Save"
              cOnClick={handleUpdateSave}
            />
            <CButton
              cSrc={<DeleteIcon />}
              cTooltipText="Delete"
              cOnClick={EditCompDeleteApi}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default AddPrintingDetails;
