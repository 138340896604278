import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Input,
} from "@mui/material";
import HistoricalJobVM from "../viewmodel/HistoricalJobVM";
import CText from "../../../../basecomp/components/CText";
import CButton from "../../../../basecomp/components/CButton";
import CButtonGroup from "../../../../basecomp/components/CButtonGroup";
import CDropdown from "../../../../basecomp/components/CDropDown";
import CDateTimePicker from "../../../../basecomp/components/CDateTimePicker";
import CInput from "../../../../basecomp/components/CInput";

const HistoricalJobs = () => {
  const {
    dropdownData,
    tableData,
    showTable,
    page,
    rowsPerPage,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    handleSearchClick,
    handleResetClick,
    setSearchQuery,
    searchQuery,
    handleFromChange,
    handleToChange,
    handleJobIdChange,
    handleJobTypeChange,
    handleCreatedByChange,
    handleEquipmentTypeChange,
    handleEquipmentIdChange,
    handleJobDirectiveChange,
    handleChangePage,
    handleChangeRowsPerPage,
    resetAll,
  } = HistoricalJobVM();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Card sx={{ padding: "2em" }}>
            <Grid container paddingTop={3}>
              <Grid item xs={12} sm={12} md={6}>
                <CText cVariant="heading_section" cText="Period Selection" />
                <Grid container spacing={2} paddingTop={0.9}>
                  <Grid item xs={12} sm={12} md={5.3}>
                    <CDateTimePicker
                      resetKey={resetAll}
                      id="hist_from_date_id"
                      cOnChange={handleFromChange}
                      cHelperText="From"
                      hideTime={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={5.3}>
                    <CDateTimePicker
                      resetKey={resetAll}
                      id="hist_to_date_id"
                      cOnChange={handleToChange}
                      cHelperText="To"
                      hideTime={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} paddingTop={3} paddingLeft={3}>
                  <CButtonGroup>
                    <CButton
                      id="job_search"
                      cSrc={<SearchIcon />}
                      cTooltipText="Search"
                      cOnClick={handleSearchClick}
                    />

                    <CButton
                      id="job_reset"
                      cSrc={<AutorenewIcon />}
                      cTooltipText="Reset"
                      cOnClick={handleResetClick}
                    />
                  </CButtonGroup>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <CDropdown
                      key={resetAll}
                      id="job_card_no"
                      cHelperText="Job ID"
                      cData={dropdownData ? dropdownData.id : {}}
                      cOnChange={handleJobIdChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <CDropdown
                      key={resetAll}
                      id="job_type"
                      cHelperText="Job Type"
                      cData={dropdownData ? dropdownData.job_type : {}}
                      cOnChange={handleJobTypeChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} paddingTop={1}>
                    <CDropdown
                      key={resetAll}
                      id="created_by"
                      cHelperText="Created By"
                      cData={dropdownData ? dropdownData.created_by : {}}
                      cOnChange={handleCreatedByChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <CDropdown
                      key={resetAll}
                      id="equipment_type"
                      cHelperText="Equipment Type"
                      cData={[...new Set(filteredEquipmentTypes)].sort() || []}
                      cOnChange={handleEquipmentTypeChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} paddingTop={1}>
                    <CDropdown
                      key={resetAll}
                      id="equipment_id"
                      cHelperText="Equipment ID"
                      cData={[...new Set(filteredEquipmentIDs)].sort() || []}
                      cOnChange={handleEquipmentIdChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} paddingTop={1}>
                    <CInput
                      key={resetAll}
                      id="job_desc"
                      cHintText="Enter Job Directive"
                      cOnChange={handleJobDirectiveChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showTable && (
              <>
                <Grid container marginTop={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    style={{ textAlign: "right", paddingRight: "0.5em" }}>
                    <Input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search..."
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div style={{ overflowX: "auto" }}>
                      <Table id="hist_jobs">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>Job ID</b>
                            </TableCell>
                            <TableCell>
                              <b>Reference No</b>
                            </TableCell>
                            <TableCell>
                              <b>Job Type</b>
                            </TableCell>
                            <TableCell>
                              <b>Job Directive</b>
                            </TableCell>
                            <TableCell>
                              <b>Equipment Type</b>
                            </TableCell>
                            <TableCell>
                              <b>Equipment ID</b>
                            </TableCell>
                            <TableCell>
                              <b>Created By</b>
                            </TableCell>
                            <TableCell>
                              <b>Date/Time Created</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {showTable ? (
                          <TableBody>
                            {tableData.length > 0 ? (
                              tableData
                                .filter((data) => {
                                  // Convert all column values to lowercase for case-insensitive search
                                  const values = Object.values(data).map(
                                    (value) => String(value).toLowerCase()
                                  );
                                  // Check if any column value contains the search query
                                  return values.some((value) =>
                                    value.includes(searchQuery.toLowerCase())
                                  );
                                })
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((data) => (
                                  <TableRow key={data.id}>
                                    <TableCell>
                                      <Link
                                        to={`/d-catalogue/historical-job-detail?job_id=${data.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {data.id}
                                      </Link>
                                    </TableCell>

                                    <TableCell>{data.reference_no}</TableCell>
                                    <TableCell>{data.job_type}</TableCell>
                                    <TableCell>{data.job_directive}</TableCell>

                                    <TableCell>{data.equipment_type}</TableCell>
                                    <TableCell>{data.equipment_id}</TableCell>
                                    <TableCell>
                                      {data.created_by_name}
                                    </TableCell>
                                    <TableCell>{data.created_at}</TableCell>
                                  </TableRow>
                                ))
                            ) : (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell colSpan={8}>No data found</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        ) : null}
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HistoricalJobs;
