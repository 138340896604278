import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

export const themeMain = createTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 4000,
    },
  },
  palette: {
    primary: {
      main: "#042560",
    },
    secondary: {
      main: "#3CB043",
    },
    blurry: {
      main: "#00000080"
    },
    mainbg: {
      main: "white"
    }
  },
});

class CThemeProvider extends React.Component {
  render() {
    return (
      <ThemeProvider theme={themeMain}>
        {this.props.children}
      </ThemeProvider>
    );
  }
}

export default CThemeProvider;
