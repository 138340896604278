/* eslint-disable no-unused-vars */
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import InfoIcon from "@mui/icons-material/Info";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  Toolbar,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  IconButton,
  FormControlLabel,
  FormGroup,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import MaterialSearchVM from "../viewmodel/MaterialSearchVM";
import CCarousel from "../../../../basecomp/components/CCarousel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

// Define custom theme
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "black",
        },
      },
    },
  },
});

const MaterialSearch = (props) => {
  const {
    setSpDetails,
    setSpData,
    setLightboxOpen,
    jobCardNumber,
    handleToastOpen,
    lclMaterialNo,
    setlclMaterialNo,
  } = props;
  const {
    dataCheckBox,
    data1CheckBox,
    data2CheckBox,
    data3CheckBox,
    data4CheckBox,
    showTable,
    tableData,
    modalOpen,
    error,
    handlePurchasingGroup,
    handleLevel1,
    handleLevel2,
    handleLevel3,
    handleLevel4,
    resetPage,
    handleResetPgr,
    handleResetLevel1,
    handleResetLevel2,
    handleResetLevel3,
    handleImageClick,
    handleChange,
    addToCart,
    handleButtonClick,
    handleOpenModal,
    material,
    setMaterial,
    globalMaterial,
    setGlobalMaterial,
    materialDesc,
    setMaterialDesc,
    manufName,
    setManufName,
    manufNo,
    setManufNo,
    equipFunc,
    setEquipFunc,
    binloc,
    setBinLoc,
    setModalOpen,
    quantity,
    index,
    setOpenCarousel,
    openCarousel,
    clickedImageIndex,
    sliderImages,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    handleAddImage,
    handleResetLevel4,
    isLoading,
    setSearchQuery,
    searchQuery,
    selectedRowId,
    clearSelection,
  } = MaterialSearchVM({
    setSpDetails,
    setLightboxOpen,
    jobCardNumber,
    setSpData,
    handleToastOpen,
    lclMaterialNo,
    setlclMaterialNo,
  });

  console.log("tableData:", tableData);
  return (
    <>
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={12} md={12} margin={1.5}>
          <AppBar
            position="static"
            sx={{
              padding: "0em",
              alignItems: "center",
              backgroundColor: "#13A538",
            }}>
            <Typography variant="h4">Material Search</Typography>
          </AppBar>
        </Grid>

        <Grid item xs={12} sm={12} md={12} margin={2}>
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={12} md={2.4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Typography>Purchasing Group</Typography>
                <IconButton onClick={handleResetPgr}>
                  <DriveFileRenameOutlineRoundedIcon
                    style={{ fontSize: "0.7em" }}
                  />
                </IconButton>
              </div>
              <Card style={{ height: "15em", padding: "1em" }}>
                <div style={{ height: "100%", overflow: "auto" }}>
                  <FormGroup>
                    {dataCheckBox.map((item, index) =>
                      item.hidden ? null : (
                        <FormControlLabel
                          key={item.pgr_id}
                          control={
                            <input
                              type="checkbox"
                              checked={item.checked || false}
                              size="small"
                              style={{ marginLeft: "1em" }}
                              onChange={() => handlePurchasingGroup(index)}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ fontSize: "12px" }}>
                              {item.pgr_desc}
                            </Typography>
                          }
                        />
                      )
                    )}
                  </FormGroup>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={2.4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Typography>Taxonomy Level 1</Typography>
                <IconButton onClick={handleResetLevel1}>
                  <DriveFileRenameOutlineRoundedIcon
                    style={{ fontSize: "0.7em" }}
                  />
                </IconButton>
              </div>
              <Card style={{ height: "15em", padding: "1em" }}>
                <div style={{ height: "100%", overflow: "auto" }}>
                  <FormGroup>
                    {data1CheckBox.map((item, index) =>
                      item.hidden ? null : (
                        <FormControlLabel
                          key={item.level1_id}
                          control={
                            <input
                              type="checkbox"
                              checked={item.checked || false}
                              size="small"
                              style={{ marginLeft: "1em" }}
                              onChange={() => handleLevel1(index)}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ fontSize: "12px" }}>
                              {item.level1}
                            </Typography>
                          }
                        />
                      )
                    )}
                  </FormGroup>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={2.4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Typography>Taxonomy Level 2</Typography>
                <IconButton onClick={handleResetLevel2}>
                  <DriveFileRenameOutlineRoundedIcon
                    style={{ fontSize: "0.7em" }}
                  />
                </IconButton>
              </div>
              <Card style={{ height: "15em", padding: "1em" }}>
                <div style={{ height: "100%", overflow: "auto" }}>
                  <FormGroup>
                    {data2CheckBox.map((item, index) =>
                      item.hidden ? null : (
                        <FormControlLabel
                          key={item.level2_id}
                          control={
                            <input
                              type="checkbox"
                              checked={item.checked || false}
                              size="small"
                              style={{ marginLeft: "1em" }}
                              onChange={() => handleLevel2(index)}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ fontSize: "12px" }}>
                              {item.level2}
                            </Typography>
                          }
                        />
                      )
                    )}
                  </FormGroup>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={2.4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Typography>Taxonomy Level 3</Typography>
                <IconButton onClick={handleResetLevel3}>
                  <DriveFileRenameOutlineRoundedIcon
                    style={{ fontSize: "0.7em" }}
                  />
                </IconButton>
              </div>
              <Card style={{ height: "15em", padding: "1em" }}>
                <div style={{ height: "100%", overflow: "auto" }}>
                  <FormGroup>
                    {data3CheckBox.map((item, index) =>
                      item.hidden ? null : (
                        <FormControlLabel
                          key={item.level3_id}
                          control={
                            <input
                              type="checkbox"
                              checked={item.checked || false}
                              size="small"
                              style={{ marginLeft: "1em" }}
                              onChange={() => handleLevel3(index)}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ fontSize: "12px" }}>
                              {item.level3}
                            </Typography>
                          }
                        />
                      )
                    )}
                  </FormGroup>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={2.4}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Typography>Taxonomy Level 4</Typography>
                <IconButton onClick={handleResetLevel4}>
                  <DriveFileRenameOutlineRoundedIcon
                    style={{ fontSize: "0.7em" }}
                  />
                </IconButton>
              </div>
              <Card style={{ height: "15em", padding: "1em" }}>
                <div style={{ height: "100%", overflow: "auto" }}>
                  <FormGroup>
                    {data4CheckBox.map((item, index) =>
                      item.hidden ? null : (
                        <FormControlLabel
                          key={item.level4_id}
                          control={
                            <input
                              type="checkbox"
                              checked={item.checked || false}
                              size="small"
                              style={{ marginLeft: "1em" }} // Adjust the left margin as needed
                              onChange={() => handleLevel4(index)}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ fontSize: "12px" }}>
                              {item.level4}
                            </Typography>
                          }
                        />
                      )
                    )}
                  </FormGroup>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} margin={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 4 }}>
                <Typography noWrap>Local Material Number</Typography>
                <TextField
                  size="small"
                  id="job_directive"
                  style={{ marginTop: "0.55em", width: "100%" }}
                  label="Enter value"
                  value={lclMaterialNo !== "" ? lclMaterialNo : material}
                  onChange={(e) => {
                    if (lclMaterialNo !== "") {
                      // If lclMaterialNo is not null, update it
                      setlclMaterialNo(e.target.value);
                    } else {
                      // If lclMaterialNo is null, update material
                      setMaterial(e.target.value);
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 4 }}>
                <Typography noWrap>Global Material Number</Typography>
                <TextField
                  size="small"
                  id="job_directive"
                  style={{ marginTop: "0.55em", width: "100%" }}
                  label="Enter value"
                  value={globalMaterial}
                  onChange={(e) => setGlobalMaterial(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Material Description
                </Typography>
                <ThemeProvider theme={theme}>
                  <Tooltip
                    title={
                      <span>
                        Enter any part of the description. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For example: gear%hoist or bolt%M%12
                      </span>
                    }
                    placement="right">
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon style={{ color: "#6CB4EE", marginTop: 2 }} />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                label="Enter value"
                size="small"
                style={{ marginTop: "0.5em", width: "100%" }}
                value={materialDesc}
                onChange={(e) => setMaterialDesc(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Manufacture Name
                </Typography>
                <ThemeProvider theme={theme}>
                  <Tooltip
                    title={
                      <span>
                        Enter any part of the Manufacturer. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For Example kal, kalmar%bromma
                      </span>
                    }
                    placement="right">
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon style={{ color: "#6CB4EE", marginTop: 2 }} />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                size="small"
                id="job_directive"
                style={{ marginTop: "0.5em", width: "100%" }}
                label="Enter value"
                value={manufName}
                onChange={(e) => setManufName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 4 }}>
                <Typography noWrap>Manufacture Part Number</Typography>
                <TextField
                  size="small"
                  id="job_directive"
                  style={{ marginTop: "0.55em", width: "100%" }}
                  label="Enter value"
                  value={manufNo}
                  onChange={(e) => setManufNo(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Equipment Function
                </Typography>
                <ThemeProvider theme={theme}>
                  <Tooltip
                    title={
                      <span>
                        Enter any part of the Equipment Function. In case of
                        multiple parts (search strings), use a % sign as
                        separator between the parts. For E.g. qc, qc%RTG
                      </span>
                    }
                    placement="right">
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon style={{ color: "#6CB4EE", marginTop: 2 }} />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                size="small"
                id="job_directive"
                style={{ marginTop: "0.5em", width: "100%" }}
                label="Enter value"
                value={equipFunc}
                onChange={(e) => setEquipFunc(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Bin Location
                </Typography>
                <ThemeProvider theme={theme}>
                  <Tooltip
                    title={
                      <span>
                        Enter any part of the Bin Location. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For E.g. WF%01
                      </span>
                    }
                    placement="right">
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon style={{ color: "#6CB4EE", marginTop: 2 }} />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                size="small"
                id="job_directive"
                style={{ marginTop: "0.5em", width: "100%" }}
                label="Enter value"
                value={binloc}
                onChange={(e) => setBinLoc(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={11.9}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "1em",
              marginTop: "1.5em",
            }}>
            <div style={{ display: "flex" }}>
              <Tooltip title="Search">
                <IconButton
                  style={{
                    backgroundColor: "#13A538",
                    color: "white",
                    borderRadius: 4,
                    height: "1.1em",
                    width: "1.4em",
                  }}
                  onClick={handleButtonClick}>
                  <SearchIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Reset">
                <IconButton
                  style={{
                    backgroundColor: "#13A538",
                    color: "white",
                    borderRadius: 4,
                    height: "1.1em",
                    width: "1.4em",
                    marginLeft: "1em",
                  }}
                  onClick={resetPage}>
                  <AutorenewIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>

        {showTable && (
          <>
            <Grid container marginTop={3}>
              <Grid item xs={12} sm={12} md={12} margin={1}>
                <AppBar
                  position="static"
                  sx={{
                    padding: "0em",
                    alignItems: "center",
                    backgroundColor: "#13A538",
                  }}>
                  <Typography variant="h5">Search Details</Typography>
                </AppBar>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={12}
                style={{
                  textAlign: "right",
                  paddingRight: "0.5em",
                  marginTop: "1em",
                }}>
                <TextField
                  id="search"
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ marginBottom: "1em" }}
                />
              </Grid>

              <Grid item xs={8} sm={8} md={12}>
                <div style={{ overflowX: "auto" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <IconButton color="black">
                            <ShoppingCartOutlinedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton color="black">
                            <CameraAltIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <b>Material</b>
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          <b>Description</b>
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          <b>Local Material Description</b>
                        </TableCell>
                        <TableCell>
                          <b>GMN</b>
                        </TableCell>
                        <TableCell style={{ width: "150px" }}>
                          <b>Gbl Material Desc.</b>
                        </TableCell>
                        <TableCell>
                          <b>Equipment Function</b>
                        </TableCell>
                        <TableCell>
                          <b>Bin Location</b>
                        </TableCell>
                        <TableCell>
                          <b>OHQ</b>
                        </TableCell>
                        <TableCell>
                          <b>UOM</b>
                        </TableCell>
                        <TableCell>
                          <b>Manufacturer Info</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {showTable ? (
                      <TableBody>
                        {tableData.length > 0 ? (
                          tableData
                            .filter((data) =>
                              Object.values(data).some(
                                (value) =>
                                  value &&
                                  (typeof value === "string"
                                    ? value
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                    : value.toString().includes(searchQuery))
                              )
                            )
                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                            .map((data, index) => (
                              <TableRow
                                key={data.id}
                                style={{
                                  backgroundColor:
                                    selectedRowId === data.id
                                      ? "#ffe0b2"
                                      : "inherit",
                                }}>
                                <TableCell>
                                  <IconButton
                                    color="black"
                                    onClick={() => handleOpenModal(data.id)}>
                                    <ShoppingCartOutlinedIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  {data.image_link.length === 0 ||
                                  data.image_link === undefined ? (
                                    <Tooltip title="Add Image">
                                      <IconButton
                                        id="add_image"
                                        onClick={() =>
                                          handleAddImage(data.material_no)
                                        }>
                                        <CameraAltIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="View Image">
                                      <IconButton
                                        id="display_image"
                                        onClick={() =>
                                          handleImageClick(
                                            data.image_link,
                                            index
                                          )
                                        }>
                                        <RemoveRedEyeIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </TableCell>
                                <TableCell>{data.material_no}</TableCell>
                                <TableCell style={{ width: "150px" }}>
                                  {data.material_desc}
                                </TableCell>
                                <TableCell style={{ width: "150px" }}>
                                  {data.local_material_desc}
                                </TableCell>
                                <TableCell>{data.industry_std_desc}</TableCell>
                                <TableCell style={{ width: "150px" }}>
                                  {data.long_desc}
                                </TableCell>
                                <TableCell>{data.equipment_func}</TableCell>
                                <TableCell>{data.bin_location}</TableCell>
                                <TableCell>{data.ohqty}</TableCell>
                                <TableCell>{data.b_un}</TableCell>
                                <TableCell>
                                  {(data.mpn_name1 ||
                                    data.manufac_item_code) && (
                                    <div>
                                      {data.mpn_name1 &&
                                      data.manufac_item_code ? (
                                        <div>
                                          {data.mpn_name1} -{" "}
                                          {data.manufac_item_code}
                                        </div>
                                      ) : (
                                        data.mpn_name1 || data.manufac_item_code
                                      )}
                                    </div>
                                  )}

                                  {(data.mpn_name2 || data.mpn_number1) && (
                                    <div>
                                      {data.mpn_name2 && data.mpn_number1 ? (
                                        <div>
                                          {data.mpn_name2} - {data.mpn_number1}
                                        </div>
                                      ) : (
                                        data.mpn_name2 || data.mpn_number1
                                      )}
                                    </div>
                                  )}

                                  {(data.mpn_name3 || data.mpn_number2) && (
                                    <div>
                                      {data.mpn_name3 && data.mpn_number2 ? (
                                        <div>
                                          {data.mpn_name3} - {data.mpn_number2}
                                        </div>
                                      ) : (
                                        data.mpn_name3 || data.mpn_number2
                                      )}
                                    </div>
                                  )}

                                  {(data.mpn_name4 || data.mpn_number3) && (
                                    <div>
                                      {data.mpn_name4 && data.mpn_number3 ? (
                                        <div>
                                          {data.mpn_name4} - {data.mpn_number3}
                                        </div>
                                      ) : (
                                        data.mpn_name4 || data.mpn_number3
                                      )}
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={10} align="center">
                              No data found
                            </TableCell>
                          </TableRow>
                        )}

                        {openCarousel && (
                          <CCarousel
                            cOpen={openCarousel}
                            images={sliderImages}
                            currentIndex={clickedImageIndex || 0} // Provide a default value if clickedImageIndex is undefined
                            cClose={() => setOpenCarousel(false)}
                          />
                        )}
                      </TableBody>
                    ) : null}
                  </Table>
                  <Dialog
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    PaperProps={{
                      style: {
                        marginBottom: "25em",
                        maxWidth: "23em", // Adjust the desired maximum width
                        width: "100%", // Ensure the modal takes up the full available width
                      },
                    }}>
                    <AppBar
                      position="static"
                      style={{
                        backgroundColor: "#F7A941",
                        alignItems: "center",
                      }}>
                      <Toolbar>
                        <ShoppingCartOutlinedIcon />
                      </Toolbar>
                    </AppBar>

                    <DialogContent>
                      <div className="modal-body">
                        <Typography
                          style={{
                            color: "#1976d2",
                            marginBottom: "1em",
                          }}>
                          QTY
                        </Typography>
                        <TextField
                          id="standard-basic"
                          fullWidth
                          value={quantity}
                          onChange={handleChange}
                          variant="standard"
                        />
                        {error && <span>{error}</span>}
                      </div>
                    </DialogContent>

                    <DialogActions>
                      <Button style={{ color: "#1976d2" }} onClick={addToCart}>
                        OK
                      </Button>
                      <Button
                        onClick={clearSelection}
                        style={{ color: "#1976d2" }}>
                        CANCEL
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </>
  );
};

export default MaterialSearch;
