import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, verificationUser, verifyLogin } from "../model/LoginModel";
import Logger from "../../base/Logger";
import UserUtils from "../UserUtils";
import { useToast } from "../../components/provider/ToastProvider";

const { handleLoggedIn, getUserName } = UserUtils;
const storedEmail = sessionStorage.getItem("email");
const currentUrl = window.location.href;
const loginParam = new URL(currentUrl).searchParams.get("login");

if (loginParam === "true") {
  const userName = getUserName();
  if (userName === null || userName === "") {
    const resultPromise = loginUser(storedEmail);
    resultPromise
      .then((result) => {
        // if (result.data === "success") {
        handleLoggedIn(result);

        const event = new CustomEvent("cStorage", {});
        window.dispatchEvent(event);
        const sessionItem = sessionStorage.getItem("logingsoneurl");
        if (sessionItem) {
          const url = sessionItem;
          const currentUrl = window.location.origin;
          window.location.href = currentUrl + url;
        }
      })
      .catch((error) => {
        //handleToastOpen("error", error.message);
      });
  } else {
    try {
      await verifyLogin();
    } catch (error) {
      Logger.e("Something went wrong in verification");
    }
  }
}

const LoginVM = (
  {
    /*handleToastOpen*/
  }
) => {
  const { handleToastOpen } = useToast();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  // useEffect(async () => {
  const verifyAndRedirect = async () => {
    const userName = getUserName();
    if (userName !== null && userName !== "") {
      try {
        await verifyLogin();
        navigate("/services");
      } catch (error) {
        Logger.e("Something went wrong in verification");
      }
    }
  };
  verifyAndRedirect();
  // }, []);

  const handleLogin = async () => {
    try {
      const REACT_APP_BE_URL = process.env.REACT_APP_BE_URL;

      const msAuthClick = async (email) => {
        try {
          const redirectUrl = `${REACT_APP_BE_URL}/users/auth/microsoft?feURL=${encodeURIComponent(
            currentUrl
          )}&email=${encodeURIComponent(email)}`;
          window.location.href = redirectUrl;
        } catch (error) {
          Logger.e("Something went wrong in authentication");
        }
      };

      await verificationUser(email);
      // if (result.data === "success") {
      sessionStorage.setItem("email", email);
      await msAuthClick(email);
    } catch (error) {
      handleToastOpen("error", error.message);
    }
  };

  return {
    email,
    setEmail,
    handleLogin,
  };
};

export default LoginVM;
