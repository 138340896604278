import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const {
  axiosGetCallWithToken,
  axiosPostCallWithToken
} = AxiosWrapper;
export const checkAssetType = async (id) => {
  let response;
  try {
    response = await axiosGetCallWithToken(`${Base_URL}/gsone/getAssetDetails?id=${id}`);
  } catch (error) {
    let sendError = error;
    if (sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error("Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance.");
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
}

//registration page to fetch plant list
export const getPlantOptions = async () => {
  let response;
  try {
    response = await axiosGetCallWithToken(`${Base_URL}/users/getAllTerminals`);
  } catch (error) {
    let sendError = error;
    if (sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error("Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance.");
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
}

//registration page to add records for specific asset id

export const submitFormData = async (formData) => {
  let response;
  try {
    response = await axiosPostCallWithToken(`${Base_URL}/gsone/addAssetData`, formData);
  } catch (error) {
    let sendError = error;
    if (sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error("Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance.");
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  if (response.status >= 200 && response.status <= 204) {
    if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
}

//sparepart info page to fetch spare details for specific material

export const getSparePartDetails = async (material) => {
  let response;
  try {
    sessionStorage.setItem("logingsoneurl", `/warehouse/spare-part-info?material_no=${material}`);
    response = await axiosGetCallWithToken(
      `${Base_URL}/gsone/getSparePartDetails?material=${material}`
    );
  } catch (error) {
    let sendError = error;
    if (sendError.response !== undefined &&
      sendError.response.data !== undefined &&
      sendError.response.data.message !== undefined) {
      sendError = new Error(sendError.response.data.message);
    } else {
      sendError = new Error("Something unexpected happened." +
        "If the issue persists, please contact our support team for assistance.");
    }
    Logger.printStackTrace("Error fetching data:", error);

    throw sendError;
  }
  if (response === undefined) {
    throw new Error("No response");
  }
  console.log("hwuwusjjoskn  ", response);
  if (response.status >= 200 && response.status <= 204) {
    if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
      throw new Error(response.data.message);
    } else {
      return response;
    }
  }
}

// Function to fetch row data based on ID from sq01
export const getSQ01ByID = async (id) => {
  try {
    // Make the API request to fetch the row data
    console.log("id", id);
    const response = await axiosGetCallWithToken(
      `${Base_URL}/common/getSq01CatById?sq_id=${id}`
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching sq01catById:", error);
  }
};

//fetch spbasketID to insert records in spbasket table
export const getSpBasketID = async () => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/dcatalogue/getSpBasketID`
    );

    return response;
  } catch (error) {
    Logger.messageStackTrace("Error getting SpBasket ID: ", error);
  }
};

// add data to spbasket table
export const postSpBasket = async (data) => {
  // Send the data to the API endpoint
  try {
    console.log("sp_basket", data);
    const response = await axiosPostCallWithToken(
      `${Base_URL}/dcatalogue/addSpBasket`,
      data
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error posting SpBasket details:", error);
  }
};


// fetch fuel asset records
export const fetchMaterialData = async (asset_id, terminal) => {
  const response = await axiosGetCallWithToken(`${Base_URL}/get_gsone_data?equipment_number=${asset_id}&terminal=${terminal}`);
  return response;
}