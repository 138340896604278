import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
import { materialNotPresent } from "./Strings";

const Base_URL = process.env.REACT_APP_BE_URL;
const currentUrl = window.location.origin;
const {
  axiosGetCallWithToken,
  axiosPostCallWithToken,
  axiosPutCallWithToken,
  axiosDeleteCallWithToken,
  axiosGetCallWithTokenHeader,
} = AxiosWrapper;

//Size drop axios call
export const fetchSizeOptions = () => {
  return axiosGetCallWithToken(
    `${Base_URL}/labels/getAllMeasurement`
  );
};
//Already presented labels axios call
export const fetchDefaultPresentedLabels = () => {
  return axiosGetCallWithToken(
    `${Base_URL}/labels/allLabelsForUser`
  );
};

//add label axios call
export const addLabelData = async(requestData, handleToastOpen) => {
  try {
    const response = await axiosPostCallWithToken(`${Base_URL}/labels/addLabelData`, requestData)
    if (response === undefined) {
      return handleToastOpen("error", materialNotPresent);
    } 
    return response;
    
  } catch (error) {
    Logger.printStackTrace(error)
  }
};

//add BulkLocation axios call
export const addBulkLocation = (requestData) => {
  return axiosPostCallWithToken(`${Base_URL}/labels/addBulkLocation`, requestData);
};

//preview time axios call
export const downloadPDF = (existIds, selectedSize) => {
  const sizeParam = selectedSize ? `&size=${selectedSize.id}` : "";
  return axiosGetCallWithToken(
    `/download-pdf?id=${existIds.join(",")}${sizeParam}`
  );
};

//delete all labels by user axios call
export const deleteAllLabels = (terminal, user_id) => {
  return axiosDeleteCallWithToken(
    `${Base_URL}/labels/deleteLabelsByUser`
  );
};

//delete particular label axios call
export const deleteLabelData = (id) => {
  return axiosDeleteCallWithToken(
    `${Base_URL}/labels/deleteLabelData?id=${id}`
  );
};

//update particular label axios call
export const updateLabel = (
  updatedLabelId,
  updatedMatNo,
  updatedQuantityNo
) => {
  return axiosPutCallWithToken(
    `${Base_URL}/labels/updateParticularLabel?id=${updatedLabelId}&material_no=${updatedMatNo}&print_qty=${updatedQuantityNo}`
  );
};

//update bin location
export const updateBinLocation = (
  updatedLabelId,
  material_no,
  bin_location,
) => {
  return axiosPutCallWithToken(
    `${Base_URL}/labels/updateBinLocation?id=${updatedLabelId}&material_no=${material_no}&bin_location=${bin_location}`
  );
};

//excel label file axios call
export const addBulkLabelData = (formData) => {
  return axiosPostCallWithToken(`${Base_URL}/labels/addExcelData`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const fetchMaterialsByPo = (newValue) => {
  return axiosGetCallWithToken(
    `${Base_URL}/labels/allMaterialByPo?po_no=${newValue}`
  );
};

export const fetchPoByMaterials = (newValue) => {
  return axiosGetCallWithToken(
    `${Base_URL}/labels/allPoByMaterial?mat_no=${newValue}`
  );
};

//fetching selected labels and size axios call
export const fetchMaterialData = async (
  lbl_id,
  sizeName
) => {
  try {
    const result1 = await axiosGetCallWithToken(
      `${Base_URL}/labels/getAllSelectedLabels?label_id=${lbl_id}`
    );

    const result2 = await axiosGetCallWithToken(
      `${Base_URL}/labels/getParticularMeasurementDetails?measurement_id=${sizeName}`
    );

    return [result1, result2];
  } catch (error) {}
};

export const fetchPrintPdfData = async (
  purchaseOrder,
  materialNumber,
  selectedSize,
  quantity,
  tableName
) => {
  try {
    return await axiosGetCallWithTokenHeader(
      `${Base_URL}/labels/pdf?po_no=${purchaseOrder}&material_no=${materialNumber}&measurement_id=${selectedSize}&print_qty=${quantity}&url=${currentUrl}&table=${tableName}`
    );
  } catch (error) {}
};

export const fetchViewPdfDataByMatNum = async (
  purchaseOrder,
  materialNumber,
  selectedSize,
  quantity,
  tableName
) => {
  try {
    return await axiosGetCallWithTokenHeader(
      `${Base_URL}/labels/view?po_no=${purchaseOrder}&material_no=${materialNumber}&measurement_id=${selectedSize}&print_qty=${quantity}&url=${currentUrl}&table=${tableName}`
    );
  } catch (error) {}
};

export const fetchPrintAllPdfData = async (labelIds) => {
  const labelIdsString = labelIds.join(",");
  try {
    return await axiosGetCallWithTokenHeader(
      `${Base_URL}/labels/all-pdf?labelIds=${labelIdsString}&url=${currentUrl}`
    );
  } catch (error) {}
};