import React from "react";
import AddPrintingVM from "../viewmodel/AddPrintingVM";
import CText from "../../../basecomp/components/CText";
import { Grid } from "@mui/material";
import CDropDown from "../../../basecomp/components/CDropDown";
import CInput from "../../../basecomp/components/CInput";
import CButton from "../../../basecomp/components/CButton";
import { Label_Printing_Container } from "../../assets/Constant";
import CButtonGroup from "../../../basecomp/components/CButtonGroup";

const UploadLabel = () => {
  const {
    handleSampleFileDownload,
    handleFileUpload,
    sizeOptions,
    selectedSize,
    setSelectedFiles,
    handleSizeChange,
  } = AddPrintingVM();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={Label_Printing_Container.Main_Container}
      
    >
      <CDropDown
        id="size"
        cHelperText="Size"
        cValue={selectedSize}
        cData={sizeOptions ? sizeOptions.map((data) => data.name) : []}
        cOnChange={handleSizeChange}
        cOptions={sizeOptions.map((data) => data.name)}
        fullWidth
      />
      <CInput
        id="file-input"
        cType="file"
        cInputProps={{ shrink: true }}
        cinputProps={{ multiple: true }}
        cOnChange={(e) => setSelectedFiles(e.target.files)}
        fullWidth
      />
      <Grid container item xs={12} justify="center">
      <CButtonGroup>

        <CButton
          cText={"Upload"}
          cTooltipText="Upload the label file"
          cOnClick={handleFileUpload}
        />
        <CButton
          cText={"Sample File"}
          cTooltipText="Download the sample label file"
          cOnClick={handleSampleFileDownload}
        />
        </CButtonGroup>
      </Grid>
    </Grid>
  );
};

export default UploadLabel;
