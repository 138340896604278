import { useState, useEffect, useRef } from "react";

import {
  addJobbIdCatalogue,
  updateType,
  getEquipmentForDropdown,
  getJobCardNo,
} from "../model/SBHeaderModel";

import {
  getSQ01AndSpBasket,
  getGlobalAndSpBasket,
} from "../model/MaterialSearchModel";

import ShoppingBasketVM from "../viewmodel/ShoppingBasketVM";

import { useToast } from "../../../../basecomp/components/provider/ToastProvider";

const SBHeaderVM = (props) => {
  const {
    startDate,
    finishDate,
    jobDirective,
    equipmentID,
    equipmentType,
    externalRef,
    jobType,
    section,
    resources,
    workDesc,
    setIsMaterialSearchUI,
    setIsShoppingBasketUI,
    setIsobDetailsUI,
    setIsReplenishmentUI,
    spDetails,
    spData,
    setSpData,
    setSpDetails,
  } = props;

  const [dropdownData, setDropdownData] = useState([]);
  const [jobCardNumber, setJobCardNumber] = useState("");
  const [catalogueId, setcatalogueId] = useState("");

  const { handleToastOpen } = useToast();

  const externalScriptRef = useRef();

  //Default Section : Create new record in job_id_catalogue_table && Update type to WO
  const handleSaveJob = async () => {
    if (!jobCardNumber || !jobDirective || !equipmentID || !equipmentType) {
      handleToastOpen(
        "error",
        "Please fill in all required fields - Job Directive , Equipment type and Equipment Id."
      );
      return;
    }

    if (finishDate && startDate && finishDate <= startDate) {
      handleToastOpen("error", "Invalid date range!");
      return;
    }

    const data = {
      id: jobCardNumber,
      reference_no: externalRef,
      job_directive: jobDirective,
      equipment_id: equipmentID,
      job_type: jobType,
      section: section,
      resources: resources,
      start_date_time: startDate,
      finish_date_time: finishDate,
      detail: workDesc,
    };

    try {
      const updateData = await updateType();
      if (updateData.status === 201) {
        const response = await addJobbIdCatalogue(data);

        if (response.status === 201) {
          handleToastOpen("success", "Job created successfully");

          const keysToRemove = [
            "sb_job_directive",
            "sb_external_ref",
            "sb_equipment_type",
            "sb_job_type",
            "sb_section",
            "sb_work_desc",
            "sb_resources",
            "sb_start_date_time",
            "sb_finish_date_time",
            "sb_equipment_id",
          ];

          keysToRemove.forEach((key) => {
            sessionStorage.removeItem(key);
          });
        }

        window.location.reload();
      } else {
        handleToastOpen("error", "Quantity must be greater than 0");
      }
    } catch (error) {
      handleToastOpen("error", "Something went wrong!");
      console.error("Error occurred during API request:", error);
    }
  };

  //Default Section : Get unique equipment_id and equipment_number for shopping basket dropdown
  const fetchDropdownData = async () => {
    try {
      const response = await getEquipmentForDropdown();
      setDropdownData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDropdownData();
  }, []);

  //Default Section :  Filter options for Equipment ID based on selected Equipment Type
  const filteredEquipmentIDs = dropdownData
    .filter((item) => !equipmentType || item.equipment_type === equipmentType)
    .map((item) => ({
      equipment_number: item.equipment_number,
      catalogue_id: item.catalogue_id,
    }));

  //Default Section : Filter options for Equipment Type based on selected Equipment ID
  const filteredEquipmentTypes = dropdownData
    .filter((item) => {
      if (!equipmentID) {
        return true; // Include all equipment types if no equipmentID is selected
      }
      // Include equipment types that match the selected equipmentID
      return item.equipment_number === equipmentID.equipment_number;
    })
    .map((item) => item.equipment_type);

  //Default Section : Generate new Job card number logic (Job Id Catalogue)
  const fetchJobCardNumber = async () => {
    try {
      const response = await getJobCardNo();
      const data = response.data;
      if (response.status === 200) {
        setJobCardNumber(parseInt(data.job_card_no, 10));
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };

  //Shopping Basket : Get all data from sp_basket_table by terminal_id  and type="SB" (Sp Basket)
  const fetchSpBasketAll = async () => {
    try {
      const data = await getSQ01AndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpDetails([]);
      } else {
        setSpDetails(data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketAll();
  }, []);

  //D-Catalogue (most used): Fetch merge result of spbasket and ictsi data (Sp Basket, Global Data, Material Images)
  const fetchSpDataAll = async () => {
    try {
      const data = await getGlobalAndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpData([]);
      } else {
        setSpData(data);
        console.log("latest", data);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };
  useEffect(() => {
    fetchSpDataAll();
  }, []);

  //Third party Tool
  const openEquipmentCatalogue = async () => {
    const usrtoken = localStorage.getItem("token");
    // Initialize externalScriptRef here
    externalScriptRef.current = window;
    // Wait for the 'layerLoaded' event
    window.addEventListener("layerLoaded", () => {
      setTimeout(function () {
        // Call setLayerCookie with the desired parameters
        externalScriptRef.current.setLayerCookie("user_token", usrtoken);
      }, 1000);
      console.log("Catalogue is present Akkkkkk");
    });
    externalScriptRef.current.openLayerByReleaseId(catalogueId, true);
  };

  useEffect(() => {
    if (catalogueId) {
      openEquipmentCatalogue();

      // Find the button element
      const closeButton = document.getElementById("close_tab_close_button");

      // Add click event listener
      closeButton.addEventListener("click", async () => {
        // Reload the page
        await new Promise((resolve) => setTimeout(resolve, 100)); // Optional delay for tasks to complete
        fetchSpDataAll();
        fetchSpBasketAll();
      });
    } else if (equipmentID) {
      handleToastOpen("error", "Catalogue not present");
    }
  }, [catalogueId, equipmentID]);

  useEffect(() => {
    fetchDropdownData();
    fetchJobCardNumber();
  }, []);

  const ShoppingBasket = () => {
    setIsMaterialSearchUI(true);
    setIsShoppingBasketUI(false);
    setIsobDetailsUI(false);
    setIsReplenishmentUI(false);
  };

  const MaterialSearch = () => {
    setIsMaterialSearchUI(false);
    setIsShoppingBasketUI(true);
    setIsobDetailsUI(false);
    setIsReplenishmentUI(false);
  };

  const JobDetails = () => {
    setIsMaterialSearchUI(false);
    setIsShoppingBasketUI(false);
    setIsobDetailsUI(true);
    setIsReplenishmentUI(false);
  };

  const Replenishment = () => {
    setIsMaterialSearchUI(false);
    setIsShoppingBasketUI(false);
    setIsobDetailsUI(false);
    setIsReplenishmentUI(true);
  };
  return {
    dropdownData,
    jobCardNumber,
    setJobCardNumber,
    spDetails,
    spData,
    setSpData,
    handleSaveJob,
    ShoppingBasket,
    MaterialSearch,
    JobDetails,
    Replenishment,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    setcatalogueId,
  };
};

export default SBHeaderVM;
